import { Table, Thead, Tr, Th, TableContainer, Flex } from '@chakra-ui/react'
import { getDistributor } from 'services/api/requests'
import { useQuery } from 'react-query'
import { ListDistributorTariff, Loader, EmptyMessage } from 'Components'

export const TableDistributorTariff = ({ query }) => {
  const { data, isLoading } = useQuery('distributor', getDistributor)

  const filteredData = data?.data?.filter((distributor) => {
    return (
      distributor.name.toLowerCase().includes(query.toLowerCase()) ||
      distributor.cnpj_distributor.includes(query)
    )
  })

  if (isLoading) {
    return <Loader />
  }

  if (!data || data?.length === 0) {
    return <EmptyMessage>Nenhuma distribuidora encontrada.</EmptyMessage>
  }
  return (
    <Flex
      bg="#ffffff"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="75%"
      mb="16px"
    >
      <TableContainer
        w="100%"
        justifyContent="space-between"
        overflowX="auto"
        overflowY="auto"
        borderRadius="8px"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'null',
            border: 'null'
          }
        }}
      >
        <Table mb="8px" mt="8px" size="sm">
          <Thead position="sticky" top="0" zIndex="docked" bg="#ffffff">
            <Tr height="34px">
              <Th fontSize="12px">Distribuidora</Th>
              <Th fontSize="12px">CNPJ</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <ListDistributorTariff data={filteredData} />
        </Table>
      </TableContainer>
    </Flex>
  )
}
