import {
  Flex,
  FormControl,
  FormLabel,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { InputModal } from 'Components'
import {
  getFreeMarketSavingsByAnalysisId,
  getAnalysisById
} from 'services/api/requests'

export const SavingsSection = ({ id, values, handleChange, errors }) => {
  const { data: analysis } = useQuery(
    ['analysis', id],
    () => getAnalysisById(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const analysisData = analysis?.data

  const { data: freemarketsavings } = useQuery(
    ['freemarketsavings', id],
    () => getFreeMarketSavingsByAnalysisId(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const freemarketsavingsData = freemarketsavings?.data

  return (
    <Flex flexDir="column">
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Migração para o Mercado Livre
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueTotal"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Custo Cativo (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueTotal"
            name="regulatedValueTotal"
            value={freemarketsavingsData?.regulatedValueTotal || ''}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="valueTotal"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Custo Mercado Livre (R$)
          </FormLabel>
          <InputModal.Result
            id="valueTotal"
            name="valueTotal"
            value={analysisData?.valueTotal || undefined}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="savingsValue"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Economia (R$)
          </FormLabel>
          <InputModal.Result
            id="savingsValue"
            name="savingsValue"
            value={freemarketsavingsData?.savingsValue || ''}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '8%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="savingsPercentage"
            display="flex"
            textAlign="flex-start"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Economia (%)
          </FormLabel>
          <InputModal.Result
            id="savingsPercentage"
            name="savingsPercentage"
            value={freemarketsavingsData?.savingsPercentage || ''}
          />
        </FormControl>
      </Flex>
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Simulação Cativo
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueOffPeakDemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Demanda Medida F.P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueOffPeakDemand"
            name="regulatedValueOffPeakDemand"
            value={freemarketsavingsData?.regulatedValueOffPeakDemand || ''}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueUnused_offpeakdemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Demanda Não Usada F.P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueUnused_offpeakdemand"
            name="regulatedValueUnused_offpeakdemand"
            value={
              freemarketsavingsData?.regulatedValueUnused_offpeakdemand || ''
            }
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueOffPeakDemand_overrun"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Ultrapassagem F.P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueOffPeakDemand_overrun"
            name="regulatedValueOffPeakDemand_overrun"
            value={
              freemarketsavingsData?.regulatedValueOffPeakDemand_overrun || ''
            }
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueOffPeakSurplus_reactive_demand"
            display="flex"
            textAlign="flex-start"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Demanda Exc. Reativo F.P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueOffPeakSurplus_reactive_demand"
            name="regulatedValueOffPeakSurplus_reactive_demand"
            value={
              freemarketsavingsData?.regulatedValueOffPeakSurplus_reactive_demand ||
              ''
            }
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={4}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValuePeakDemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Demanda Medida P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValuePeakDemand"
            name="regulatedValuePeakDemand"
            value={freemarketsavingsData?.regulatedValuePeakDemand || ''}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueUnused_peakdemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Demanda Não Usada P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueUnused_peakdemand"
            name="regulatedValueUnused_peakdemand"
            value={freemarketsavingsData?.regulatedValueUnused_peakdemand || ''}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValuePeakDemand_overrun"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Ultrapassagem P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValuePeakDemand_overrun"
            name="regulatedValuePeakDemand_overrun"
            value={
              freemarketsavingsData?.regulatedValuePeakDemand_overrun || ''
            }
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValuePeakSurplus_reactive_demand"
            display="flex"
            textAlign="flex-start"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Demanda Exc. Reativo P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValuePeakSurplus_reactive_demand"
            name="regulatedValuePeakSurplus_reactive_demand"
            value={
              freemarketsavingsData?.regulatedValuePeakSurplus_reactive_demand ||
              ''
            }
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={8}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueOffpeakconsumption_tusd"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Consumo TUSD F.P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueOffpeakconsumption_tusd"
            name="regulatedValueOffpeakconsumption_tusd"
            value={
              freemarketsavingsData?.regulatedValueOffpeakconsumption_tusd || ''
            }
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueOffpeakconsumption_te"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Consumo TE F.P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueOffpeakconsumption_te"
            name="regulatedValueOffpeakconsumption_te"
            value={
              freemarketsavingsData?.regulatedValueOffpeakconsumption_te || ''
            }
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueOffpeak_surplus_reactive_power"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Consumo Exc. Reativo F.P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueOffpeak_surplus_reactive_power"
            name="regulatedValueOffpeak_surplus_reactive_power"
            value={
              freemarketsavingsData?.regulatedValueOffpeak_surplus_reactive_power ||
              ''
            }
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={4}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValuePeakconsumption_tusd"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Consumo TUSD P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValuePeakconsumption_tusd"
            name="regulatedValuePeakconsumption_tusd"
            value={
              freemarketsavingsData?.regulatedValuePeakconsumption_tusd || ''
            }
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValuePeakconsumption_te"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Consumo TE P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValuePeakconsumption_te"
            name="regulatedValuePeakconsumption_te"
            value={
              freemarketsavingsData?.regulatedValuePeakconsumption_te || ''
            }
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValuePeak_surplus_reactive_power"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Consumo Exc. Reativo P. (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValuePeak_surplus_reactive_power"
            name="regulatedValuePeak_surplus_reactive_power"
            value={
              freemarketsavingsData?.regulatedValuePeak_surplus_reactive_power ||
              ''
            }
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={8}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueFlag"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Bandeira Tarifária (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueFlag"
            name="regulatedValueFlag"
            value={freemarketsavingsData?.regulatedValueFlag || ''}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValuePIS"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            PIS (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValuePIS"
            name="regulatedValuePIS"
            value={freemarketsavingsData?.regulatedValuePIS || ''}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueCOFINS"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            COFINS (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueCOFINS"
            name="regulatedValueCOFINS"
            value={freemarketsavingsData?.regulatedValueCOFINS || ''}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="regulatedValueICMS"
            display="flex"
            textAlign="flex-start"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            ICMS (R$)
          </FormLabel>
          <InputModal.Result
            id="regulatedValueICMS"
            name="regulatedValueICMS"
            value={freemarketsavingsData?.regulatedValueICMS || ''}
          />
        </FormControl>
      </Flex>
    </Flex>
  )
}
