import {
  Flex,
  FormControl,
  FormLabel,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import { InputModal } from 'Components'

export const ChargesSection = ({
  values,
  handleChange,
  setFieldValue,
  errors
}) => {
  return (
    <Flex flexDir="column">
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Encargos CCEE
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="associationfee"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Contribuição Associativa (R$)
          </FormLabel>
          <InputModal.Currency
            id="associationfee"
            name="associationfee"
            value={values.associationfee}
            onChange={(val) => setFieldValue('associationfee', val)}
            error={errors.associationfee}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="financialsettlement"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Liquidação Financeira (R$)
          </FormLabel>
          <InputModal.Currency
            id="financialsettlement"
            name="financialsettlement"
            value={values.financialsettlement}
            onChange={(val) => setFieldValue('financialsettlement', val)}
            error={errors.financialsettlement}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="reserveenergycharges"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Energia de Reserva (R$)
          </FormLabel>
          <InputModal.Currency
            id="reserveenergycharges"
            name="reserveenergycharges"
            value={values.reserveenergycharges}
            onChange={(val) => setFieldValue('reserveenergycharges', val)}
            error={errors.reserveenergycharges}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="ercap"
            display="flex"
            textAlign="flex-start"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Encargo de Potência para Reserva de Capacidade (R$)
          </FormLabel>
          <InputModal.Currency
            id="ercap"
            name="ercap"
            value={values.ercap}
            onChange={(val) => setFieldValue('ercap', val)}
            error={errors.ercap}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
      </Flex>
    </Flex>
  )
}
