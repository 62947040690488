import React from 'react'
import { Flex, HStack, IconButton, Image } from '@chakra-ui/react'
import {
  HeaderDistributor,
  HeaderSupplier,
  Menu,
  SearchBar,
  ModalAddDistributor,
  TableDistributor,
  ModalAddSupplier,
  TableSupplier,
  Button
} from 'Components'

export const Suppliers = () => {
  const [collapse, setCollapse] = React.useState(false)
  const [query, setQuery] = React.useState('')

  const [showSuppliers, setShowSuppliers] = React.useState('distributor')

  const toggleSuppliers = () => {
    setShowSuppliers((prev) =>
      prev === 'distributor' ? 'supplier' : 'distributor'
    )
  }

  const toggleCollapse = () => {
    setCollapse(!collapse)
  }

  React.useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth >= 1200) {
        setCollapse(false)
      } else {
        setCollapse(true)
      }
    }

    checkScreenSize()

    window.addEventListener('resize', checkScreenSize)

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  return (
    <HStack w="full" h="100vh" bg="brand.background_home" padding="8px">
      <Flex
        as="aside"
        w="full"
        h="full"
        maxW={collapse ? '60px' : '250px'}
        transition="0.6s"
        bg="brand.background_home"
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between"
        borderRadius="8px"
        overflowX="hidden"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'white',
            border: '1px solid gray'
          }
        }}
      >
        <Menu collapse={collapse} />
      </Flex>
      <Flex
        as="main"
        w="full"
        h="full"
        bg="brand.background"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        position="relative"
        borderRadius="8px"
        overflowX="hidden"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'white',
            border: '1px solid gray'
          }
        }}
      >
        <Flex w="96%" h="100%" flexDir="column">
          <Flex w="100%" h="120px">
            <IconButton
              bg="none"
              aria-label="Menu Collapse"
              onClick={toggleCollapse}
              transform={collapse ? 'rotate(-180deg)' : 'none'}
              transition="0.6s"
              position="absolute"
              top={2}
              left={2}
              icon={
                <Image
                  src="./img/IconArrow.svg"
                  alt="IconArrow"
                  w="34px"
                  h="34px"
                />
              }
              _hover={{
                bg: 'none'
              }}
            />{' '}
          </Flex>
          <Flex
            flexDirection={['column-reverse', 'row']}
            justifyContent="space-between"
            alignItems={['center', 'baseline']}
          >
            <Flex
              flexDirection={['column-reverse', 'column-reverse', 'row', 'row']}
              mt={['16px', 0, 0, 0]}
            >
              <SearchBar alignItems="center" onChange={setQuery} />
              <Flex
                ml={['0px', '0px', '10px', '10px']}
                mb="8px"
                w="280px"
                justifyContent="space-between"
                flexDirection={['column', 'row', 'row', 'row']}
                alignItems={['center', 'baseline']}
              >
                <Button
                  _hover={{}}
                  border="1px"
                  bg="#383838"
                  color="brand.background"
                  w="136px"
                  h="34px"
                  fontSize="13px"
                  onClick={toggleSuppliers}
                >
                  {showSuppliers === 'distributor'
                    ? 'Comercializadoras'
                    : 'Distribuidoras'}
                </Button>
              </Flex>
            </Flex>
            {showSuppliers === 'distributor' && <ModalAddDistributor />}
            {showSuppliers === 'supplier' && <ModalAddSupplier />}
          </Flex>
          {showSuppliers === 'distributor' && (
            <TableDistributor query={query} />
          )}
          {showSuppliers === 'supplier' && <TableSupplier query={query} />}
        </Flex>
      </Flex>
      {showSuppliers === 'distributor' && <HeaderDistributor />}
      {showSuppliers === 'supplier' && <HeaderSupplier />}
    </HStack>
  )
}
