export const statesBR = [
  { id: 1, name: 'AC', fullname: 'Acre' },
  { id: 2, name: 'AL', fullname: 'Alagoas' },
  { id: 3, name: 'AP', fullname: 'Amapá' },
  { id: 4, name: 'AM', fullname: 'Amazonas' },
  { id: 5, name: 'BA', fullname: 'Bahia' },
  { id: 6, name: 'CE', fullname: 'Ceará' },
  { id: 7, name: 'DF', fullname: 'Distrito Federal' },
  { id: 8, name: 'ES', fullname: 'Espírito Santo' },
  { id: 9, name: 'GO', fullname: 'Goiás' },
  { id: 10, name: 'MA', fullname: 'Maranhão' },
  { id: 11, name: 'MT', fullname: 'Mato Grosso' },
  { id: 12, name: 'MS', fullname: 'Mato Grosso do Sul' },
  { id: 13, name: 'MG', fullname: 'Minas Gerais' },
  { id: 14, name: 'PA', fullname: 'Pará' },
  { id: 15, name: 'PB', fullname: 'Paraíba' },
  { id: 16, name: 'PR', fullname: 'Paraná' },
  { id: 17, name: 'PE', fullname: 'Pernambuco' },
  { id: 18, name: 'PI', fullname: 'Piauí' },
  { id: 19, name: 'RJ', fullname: 'Rio de Janeiro' },
  { id: 20, name: 'RN', fullname: 'Rio Grande do Norte' },
  { id: 21, name: 'RS', fullname: 'Rio Grande do Sul' },
  { id: 22, name: 'RO', fullname: 'Rondônia' },
  { id: 23, name: 'RR', fullname: 'Roraima' },
  { id: 24, name: 'SC', fullname: 'Santa Catarina' },
  { id: 25, name: 'SP', fullname: 'São Paulo' },
  { id: 26, name: 'SE', fullname: 'Sergipe' },
  { id: 27, name: 'TO', fullname: 'Tocantins' }
]
