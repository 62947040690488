import React from 'react'
import { Td, Tr } from '@chakra-ui/react'
import {
  MenuMoreDistributor,
  ModalDeleteDistributor,
  ModalEditDistributor
} from 'Components'

export const Distributor = ({ id, name, cnpj_distributor, onDelete }) => {
  const [showModalDelete, setShowModalDelete] = React.useState()
  const [showModalEdit, setShowModalEdit] = React.useState()

  const onCloseModalDelete = () => {
    setShowModalDelete(null)
  }

  const onCloseModalEdit = () => {
    setShowModalEdit(null)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{cnpj_distributor}</Td>
      <Td display="flex" flexDir="row" justifyContent="flex-end">
        <MenuMoreDistributor
          setShowModalDelete={setShowModalDelete}
          setShowModalEdit={setShowModalEdit}
        />

        {showModalDelete === 'distributor' && (
          <ModalDeleteDistributor
            onDelete={handleDelete}
            onClose={onCloseModalDelete}
          />
        )}
        {showModalEdit === 'distributor' && (
          <ModalEditDistributor
            onClose={onCloseModalEdit}
            id={id}
            distributor={{
              name,
              cnpj_distributor
            }}
          />
        )}
      </Td>
    </Tr>
  )
}
