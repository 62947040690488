import { Text as ChakraText, Link as ChakraLink } from '@chakra-ui/react'

export const Text = ({ children, ...props }) => (
  <ChakraText {...props}>{children}</ChakraText>
)

Text.ScreenTitle = ({ children, ...props }) => (
  <ChakraText
    color="brand.background_home"
    fontWeight="bold"
    fontSize="24px"
    mt="16px"
    {...props}
  >
    {children}
  </ChakraText>
)

Text.ScreenTitle.displayName = 'Screen Title'

Text.Label = ({ children, ...props }) => (
  <ChakraText
    color="brand.background_home"
    fontWeight="medium"
    fontSize="13px"
    mt="20px"
    {...props}
  >
    {children}
  </ChakraText>
)

Text.Label.displayName = 'Label'

export const Link = ({ children, ...props }) => (
  <ChakraLink {...props}>{children}</ChakraLink>
)

Link.Login = ({ children, ...props }) => (
  <ChakraLink
    color="#CF7B03"
    fontWeight="medium"
    fontSize="14px"
    mt="20px"
    justifyContent="flex-start"
    {...props}
  >
    {children}
  </ChakraLink>
)

Link.Login.displayName = 'Link Login'

Text.Recover = ({ children, ...props }) => (
  <ChakraText
    color="brand.background_home"
    fontWeight="medium"
    fontSize="14px"
    mt="16px"
    textAlign="center"
    {...props}
  >
    {children}
  </ChakraText>
)

Text.Recover.displayName = 'Recover'

Text.Recover2 = ({ children, ...props }) => (
  <ChakraText
    color="brand.background_home"
    fontWeight="medium"
    fontSize="12px"
    mt="6px"
    textAlign="center"
    {...props}
  >
    {children}
  </ChakraText>
)

Text.Recover2.displayName = 'Recover2'

Text.Header = ({ children, ...props }) => (
  <ChakraText
    color="brand.background"
    fontWeight="bold"
    fontSize={['0px', '0px', '16px', '20px']}
    ml="24px"
    {...props}
  >
    {children}
  </ChakraText>
)

Text.Header.displayName = 'Header'

Text.Perfil = ({ children, ...props }) => (
  <ChakraText
    color="brand.background"
    fontWeight="medium"
    fontSize="18px"
    mg="0px"
    textAlign="center"
    {...props}
  >
    {children}
  </ChakraText>
)

Text.Perfil.displayName = 'Perfil'

Text.Perfil2 = ({ children, ...props }) => (
  <ChakraText
    color="brand.background"
    fontWeight="regular"
    fontSize="12px"
    mg="0px"
    textAlign="center"
    {...props}
  >
    {children}
  </ChakraText>
)

Text.Perfil2.displayName = 'Perfil2'

Text.CardHome = ({ children, ...props }) => (
  <ChakraText
    color="brand.background"
    fontWeight="semibold"
    fontSize="17px"
    mg="0px"
    textAlign="center"
    {...props}
  >
    {children}
  </ChakraText>
)

Text.CardHome.displayName = 'CardHome'
