import {
  Flex,
  FormControl,
  FormLabel,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { InputModal } from 'Components'
import { getAnalysisById, getTariffByAnalysisId } from 'services/api/requests'

export const DemandSection = ({
  id,
  values,
  handleChange,
  setFieldValue,
  errors,
  isGreen,
  isRegulated
}) => {
  const { data: tariff } = useQuery(
    ['tariff', id],
    () => getTariffByAnalysisId(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const tariffusedData = tariff?.data

  const { data: analysis } = useQuery(
    ['analysis', id],
    () => getAnalysisById(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const analysisData = analysis?.data

  return (
    <Flex flexDir="column">
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          {isGreen ? 'Demanda' : 'Demanda Fora de Ponta'}
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flexDir="column" w={['100%', '100%', '100%', '20%']}>
          <FormControl w={['100%', '100%', '100%', '100%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="offpeakdemand"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Demanda Medida F.P. (kW)
            </FormLabel>
            <InputModal.Currency
              id="offpeakdemand"
              name="offpeakdemand"
              value={values.offpeakdemand}
              onChange={(val) => setFieldValue('offpeakdemand', val)}
              error={errors.offpeakdemand}
              placeholder="0 kW"
              textAlign="center"
            />
          </FormControl>
          {isGreen && (
            <FormControl w={['100%', '100%', '100%', '100%']} mt={4}>
              <FormLabel
                htmlFor="peakdemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Medida P. (kW)
              </FormLabel>
              <InputModal.Currency
                id="peakdemand"
                name="peakdemand"
                value={values.peakdemand}
                onChange={(val) => setFieldValue('peakdemand', val)}
                error={errors.peakdemand}
                placeholder="0 kW"
                textAlign="center"
              />
            </FormControl>
          )}
        </Flex>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="tariff_offpeakdemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Tarifa (R$/kW)
          </FormLabel>
          <InputModal.Currency
            id="tariff_offpeakdemand"
            name="tariff_offpeakdemand"
            value={values.tariff_offpeakdemand}
            onChange={(val) => setFieldValue('tariff_offpeakdemand', val)}
            error={errors.tariff_offpeakdemand}
            placeholder="R$/kW 0,00"
            textAlign="center"
          />
        </FormControl>
        {!isRegulated && (
          <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="tariff_offpeakdemandML"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Tarifa com Desconto M.L. (R$/kW)
            </FormLabel>
            <InputModal.Result
              id="tariff_offpeakdemandML"
              name="tariff_offpeakdemandML"
              value={tariffusedData?.tariff_offpeakdemandML || undefined}
            />
          </FormControl>
        )}
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="valueOffPeakDemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor (R$)
          </FormLabel>
          <InputModal.Result
            id="valueOffPeakDemand"
            name="valueOffPeakDemand"
            value={analysisData?.valueOffPeakDemand || undefined}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={4}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="unused_offpeakdemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            {isGreen ? 'Demanda Não Usada (kW)' : 'Demanda Não Usada F.P. (kW)'}
          </FormLabel>
          <InputModal.Result
            id="unused_offpeakdemand"
            name="unused_offpeakdemand"
            value={analysisData?.unused_offpeakdemand || undefined}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="tariff_offpeakunuseddemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Tarifa (R$/kW)
          </FormLabel>
          <InputModal.Result
            id="tariff_offpeakunuseddemand"
            name="tariff_offpeakunuseddemand"
            value={tariffusedData?.tariff_offpeakdemand || undefined}
          />
        </FormControl>
        {!isRegulated && (
          <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="tariff_offpeakunuseddemandML"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Tarifa com Desconto M.L. (R$/kW)
            </FormLabel>
            <InputModal.Result
              id="tariff_offpeakunuseddemandML"
              name="tariff_offpeakunuseddemandML"
              value={tariffusedData?.tariff_offpeakdemandML || undefined}
            />
          </FormControl>
        )}
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="valueUnused_offpeakdemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor (R$)
          </FormLabel>
          <InputModal.Result
            id="valueUnused_offpeakdemand"
            name="valueUnused_offpeakdemand"
            value={analysisData?.valueUnused_offpeakdemand || undefined}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={4}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="offpeakdemand_overrun"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            {isGreen ? 'Demanda Ultrap. (kW)' : 'Demanda Ultrap. F.P. (kW)'}
          </FormLabel>
          <InputModal.Result
            id="offpeakdemand_overrun"
            name="offpeakdemand_overrun"
            value={analysisData?.offpeakdemand_overrun || undefined}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="tariff_offpeakdemand_overrun"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Tarifa (R$/kW)
          </FormLabel>
          <InputModal.Result
            id="tariff_offpeakdemand_overrun"
            name="tariff_offpeakdemand_overrun"
            value={tariffusedData?.tariff_offpeakdemand_overrun || undefined}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="valueOffPeakDemand_overrun"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor (R$)
          </FormLabel>
          <InputModal.Result
            id="valueOffPeakDemand_overrun"
            name="valueOffPeakDemand_overrun"
            value={analysisData?.valueOffPeakDemand_overrun || undefined}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={4}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="offpeaksurplus_reactive_demand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            {isGreen
              ? 'Demanda Exc. Reativo (kW)'
              : 'Demanda Exc. Reativo F.P. (kW)'}
          </FormLabel>
          <InputModal.Currency
            id="offpeaksurplus_reactive_demand"
            name="offpeaksurplus_reactive_demand"
            value={values.offpeaksurplus_reactive_demand}
            onChange={(val) =>
              setFieldValue('offpeaksurplus_reactive_demand', val)
            }
            error={errors.offpeaksurplus_reactive_demand}
            placeholder="0 kW"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="tariff_offpeaksurplusdemand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Tarifa (R$/kW)
          </FormLabel>
          <InputModal.Result
            id="tariff_offpeaksurplusdemand"
            name="tariff_offpeaksurplusdemand"
            value={tariffusedData?.tariff_offpeakdemand || undefined}
          />
        </FormControl>

        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="valueOffPeakSurplus_reactive_demand"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor (R$)
          </FormLabel>
          <InputModal.Result
            id="valueOffPeakSurplus_reactive_demand"
            name="valueOffPeakSurplus_reactive_demand"
            value={
              analysisData?.valueOffPeakSurplus_reactive_demand || undefined
            }
          />
        </FormControl>
      </Flex>
      {!isGreen && (
        <>
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter
              fontSize="14px"
              fontWeight="semibold"
              bg="white"
              px="4"
            >
              Demanda Ponta
            </AbsoluteCenter>
          </Box>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="peakdemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Medida P. (kW)
              </FormLabel>
              <InputModal.Currency
                id="peakdemand"
                name="peakdemand"
                value={values.peakdemand}
                onChange={(val) => setFieldValue('peakdemand', val)}
                error={errors.peakdemand}
                placeholder="0 kW"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="tariff_peakdemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Tarifa (R$/kW)
              </FormLabel>
              <InputModal.Currency
                id="tariff_peakdemand"
                name="tariff_peakdemand"
                value={values.tariff_peakdemand}
                onChange={(val) => setFieldValue('tariff_peakdemand', val)}
                error={errors.tariff_peakdemand}
                placeholder="R$/kW 0,00"
                textAlign="center"
              />
            </FormControl>
            {!isRegulated && !isGreen && (
              <FormControl
                w={['100%', '100%', '100%', '20%']}
                mt={[4, 4, 4, 0]}
              >
                <FormLabel
                  htmlFor="tariff_peakdemandML"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Tarifa com Desconto M.L. (R$/kW)
                </FormLabel>
                <InputModal.Result
                  id="tariff_peakdemandML"
                  name="tariff_peakdemandML"
                  value={tariffusedData?.tariff_peakdemandML || undefined}
                />
              </FormControl>
            )}
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="valuePeakDemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor (R$)
              </FormLabel>
              <InputModal.Result
                id="valuePeakDemand"
                name="valuePeakDemand"
                value={analysisData?.valuePeakDemand || undefined}
              />
            </FormControl>
          </Flex>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-end"
            mt={4}
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="unused_peakdemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Não Usada P. (kW)
              </FormLabel>
              <InputModal.Result
                id="unused_peakdemand"
                name="unused_peakdemand"
                value={analysisData?.unused_peakdemand || undefined}
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="tariff_peakunuseddemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Tarifa (R$/kW)
              </FormLabel>
              <InputModal.Result
                id="tariff_peakunuseddemand"
                name="tariff_peakunuseddemand"
                value={tariffusedData?.tariff_peakdemand || undefined}
              />
            </FormControl>
            {!isRegulated && (
              <FormControl
                w={['100%', '100%', '100%', '20%']}
                mt={[4, 4, 4, 0]}
              >
                <FormLabel
                  htmlFor="tariff_peakunuseddemandML"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Tarifa com Desconto M.L. (R$/kW)
                </FormLabel>
                <InputModal.Result
                  id="tariff_peakunuseddemandML"
                  name="tariff_peakunuseddemandML"
                  value={tariffusedData?.tariff_peakdemandML || undefined}
                />
              </FormControl>
            )}
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="valueUnused_peakdemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor (R$)
              </FormLabel>
              <InputModal.Result
                id="valueUnused_peakdemand"
                name="valueUnused_peakdemand"
                value={analysisData?.valueUnused_peakdemand || undefined}
              />
            </FormControl>
          </Flex>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-end"
            mt={4}
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="peakdemand_overrun"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Ultrap. P. (kW)
              </FormLabel>
              <InputModal.Result
                id="peakdemand_overrun"
                name="peakdemand_overrun"
                value={analysisData?.peakdemand_overrun || undefined}
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="tariff_peakdemand_overrun"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Tarifa (R$/kW)
              </FormLabel>
              <InputModal.Result
                id="tariff_peakdemand_overrun"
                name="tariff_peakdemand_overrun"
                value={tariffusedData?.tariff_peakdemand_overrun || undefined}
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="valuePeakDemand_overrun"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor (R$)
              </FormLabel>
              <InputModal.Result
                id="valuePeakDemand_overrun"
                name="valuePeakDemand_overrun"
                value={analysisData?.valuePeakDemand_overrun || undefined}
              />
            </FormControl>
          </Flex>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-end"
            mt={4}
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="peaksurplus_reactive_demand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Exc. Reativo P. (kW)
              </FormLabel>
              <InputModal.Currency
                id="peaksurplus_reactive_demand"
                name="peaksurplus_reactive_demand"
                value={values.peaksurplus_reactive_demand}
                onChange={(val) =>
                  setFieldValue('peaksurplus_reactive_demand', val)
                }
                error={errors.peaksurplus_reactive_demand}
                placeholder="0 kW"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="tariff_peaksurplusdemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Tarifa (R$/kW)
              </FormLabel>
              <InputModal.Result
                id="tariff_peaksurplusdemand"
                name="tariff_peaksurplusdemand"
                value={tariffusedData?.tariff_peakdemand || undefined}
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="valuePeakSurplus_reactive_demand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor (R$)
              </FormLabel>
              <InputModal.Result
                id="valuePeakSurplus_reactive_demand"
                name="valuePeakSurplus_reactive_demand"
                value={
                  analysisData?.valuePeakSurplus_reactive_demand || undefined
                }
              />
            </FormControl>
          </Flex>
        </>
      )}
    </Flex>
  )
}
