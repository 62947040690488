import { api } from 'services/api'

export const getUser = () =>
  api.get('/users', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getUserOn = () =>
  api.get('/userOn', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const updateUser = (id, data) =>
  api.put(`/user/${id}`, data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const updateUserAvatar = (data) =>
  api.put('/avatar', data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })
