import { api } from 'services/api'

export const registerTariff = (data) =>
  api.post('/tariff', data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getTariff = () =>
  api.get('/tariff', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getTariffByDistributor = (id) =>
  api.get(`/tariff/distributor/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const deleteTariff = (id) =>
  api.delete(`/tariff/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const updateTariff = (id, data) =>
  api.put(`/tariff/${id}`, data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getTariffById = (id) =>
  api.get(`/tariff/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })
