import { Flex, Image } from '@chakra-ui/react'
import { Text, Alert } from 'Components'

export const HeaderChatAITec = () => {
  return (
    <Flex
      display="flex"
      as="header"
      w="72%"
      h="60px"
      bg="brand.primary"
      borderRadius="10px 0px 0px 10px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      position="absolute"
      top="24px"
      right="0"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex ml="14px" alignItems="center">
        <Image src="./img/IconChatAI.svg" alt="IconChatAI" w="34px" h="34px" />
        <Text.Header>Chat AI</Text.Header>
      </Flex>

      <Alert />
    </Flex>
  )
}
