import {
  FormControl,
  FormLabel,
  Flex,
  Checkbox,
  useToast,
  Text,
  Button,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import { InputModal, ListSelect, Loader, EmptyMessage } from 'Components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getDistributor,
  getSupplier,
  getVoltageClass,
  getTariffModality,
  getHiringEnvironment,
  registerAnalysis
} from 'services/api/requests'

export const ScreenAddAnalysis = () => {
  const userStore = useSelector((state) => state.user)
  const toast = useToast()
  const location = useLocation()
  const navigate = useNavigate()

  const unitData = location.state?.unitData
  const isLoading = location.state?.isLoading

  const queryClient = useQueryClient()

  const { data: distributor } = useQuery('distributor', getDistributor)
  const distributorData = distributor?.data || []

  const { data: supplier } = useQuery('supplier', getSupplier)
  const supplierData = supplier?.data || []

  const { data: voltageclass } = useQuery('voltageclass', getVoltageClass)
  const voltageclassData = voltageclass?.data || []

  const { data: tariffmodality } = useQuery('tariffmodality', getTariffModality)
  const tariffmodalityData = tariffmodality?.data || []

  const { data: hiringenvironment } = useQuery(
    'hiringenvironment',
    getHiringEnvironment
  )
  const hiringenvironmentData = hiringenvironment?.data || []

  const mutation = useMutation((data) => registerAnalysis(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao analisar fatura.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Análise feita com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
      navigate('/analysis')
      queryClient.invalidateQueries(['analysis'])
    }
  })

  const { handleSubmit, values, handleChange, errors, setFieldValue } =
    useFormik({
      initialValues: {
        unit_id: unitData?.id || '',
        user_id: userStore?.user?.id || '',
        distributor_id: unitData?.distributor_id || '',
        supplier_id: unitData?.supplier_id || 0,
        hiringenvironment_id: unitData?.hiringenvironment_id || '',
        voltageclass_id: unitData?.voltageclass_id || '',
        tariffmodality_id: unitData?.tariffmodality_id || '',
        start_date: '',
        end_date: '',
        total_cost: '',

        offpeakdemandcontract: unitData?.offpeakdemandcontract || '',
        peakdemandcontract: unitData?.peakdemandcontract || '',
        offpeakdemand: '',
        peakdemand: '',
        offpeaksurplus_reactive_demand: '',
        peaksurplus_reactive_demand: '',

        offpeakconsumption: '',
        peakconsumption: '',

        offpeak_surplus_reactive_power: '',
        peak_surplus_reactive_power: '',

        energyconsumption: '',
        priceenergyconsumption: '',
        total_cost_energyconsumption: '',

        energyassignment: '',
        priceenergyassignment: '',
        total_cost_energyassignment: '',

        surplusenergy: '',
        pricesurplusenergy: '',
        total_cost_surplusenergy: '',

        offpeaktaxsubstitution: '',
        peaktaxsubstitution: '',
        energydeduction: '',

        grosstariffsubsidy1: '',
        nettariffsubsidy1: '',
        grosstariffsubsidy2: '',
        nettariffsubsidy2: '',
        grosstariffsubsidy3: '',
        nettariffsubsidy3: '',

        associationfee: '',
        financialsettlement: '',
        reserveenergycharges: '',
        ercap: '',

        energydiscount: '',

        public_lighting: '',
        fines: '',
        fees: '',
        billingadjustment: '',
        chargesAndRefunds: '',
        supplementary_demand: '',
        others: '',

        hasDevec: true
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        unit_id: Yup.number().required('Id da unidade é obrigatório'),
        user_id: Yup.number().required('Id do usuário é obrigatório'),
        distributor_id: Yup.number().required('Distribuidora é obrigatório'),
        supplier_id: Yup.number(),
        hiringenvironment_id: Yup.number()
          .required('Ambiente de Contratação é obrigatório')
          .test(
            'is-regulated-environment',
            'O ambiente de contratação deve ser Regulado',
            function (value) {
              const { tariffmodality_id } = this.parent
              return tariffmodality_id === 3 ? value === 2 : true
            }
          ),
        voltageclass_id: Yup.number().required(
          'Classe de Tensão é obrigatório'
        ),
        tariffmodality_id: Yup.number().required(
          'Modalidade Tarifária é obrigatório'
        ),
        start_date: Yup.date().required('Data de início é obrigatória'),
        end_date: Yup.date().required('Data de término é obrigatória'),
        total_cost: Yup.number().required('Custo total é obrigatório'),

        offpeakdemandcontract: Yup.number(),
        peakdemandcontract: Yup.number(),
        offpeakdemand: Yup.number(),
        peakdemand: Yup.number(),
        offpeaksurplus_reactive_demand: Yup.number(),
        peaksurplus_reactive_demand: Yup.number(),

        offpeakconsumption: Yup.number(),
        peakconsumption: Yup.number(),

        offpeak_surplus_reactive_power: Yup.number(),
        peak_surplus_reactive_power: Yup.number(),

        energyconsumption: Yup.number(),
        priceenergyconsumption: Yup.number(),
        total_cost_energyconsumption: Yup.number(),

        energyassignment: Yup.number(),
        priceenergyassignment: Yup.number(),
        total_cost_energyassignment: Yup.number(),

        surplusenergy: Yup.number(),
        pricesurplusenergy: Yup.number(),
        total_cost_surplusenergy: Yup.number(),

        offpeaktaxsubstitution: Yup.number(),
        peaktaxsubstitution: Yup.number(),
        energydeduction: Yup.number(),

        grosstariffsubsidy1: Yup.number(),
        nettariffsubsidy1: Yup.number(),
        grosstariffsubsidy2: Yup.number(),
        nettariffsubsidy2: Yup.number(),
        grosstariffsubsidy3: Yup.number(),
        nettariffsubsidy3: Yup.number(),

        associationfee: Yup.number(),
        financialsettlement: Yup.number(),
        reserveenergycharges: Yup.number(),
        ercap: Yup.number(),

        energydiscount: Yup.number(),

        public_lighting: Yup.number(),
        fines: Yup.number(),
        fees: Yup.number(),
        billingadjustment: Yup.number(),
        chargesAndRefunds: Yup.number(),
        supplementary_demand: Yup.number(),
        others: Yup.number(),

        noICMSinTUSD: Yup.boolean(),
        noICMSinDemand: Yup.boolean(),
        noICMSinConsumptionTUSD: Yup.boolean(),
        hasICMSinDemandUnused: Yup.boolean(),
        hasCovidAccount: Yup.boolean(),
        hasWaterScarcityFee: Yup.boolean(),
        isSeasonality: Yup.boolean(),
        hasDevec: Yup.boolean()
      }),

      onSubmit: (data) => {
        const convertData = Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value === '' ? 0 : value
          ])
        )
        mutation.mutate(convertData)
      }
    })

  const isConventional = values.tariffmodality_id === 3
  const isGreen = values.tariffmodality_id === 2
  const isRegulated = values.hiringenvironment_id === 2

  if (isLoading) {
    return <Loader />
  }
  if (!unitData || unitData.length === 0) {
    return <EmptyMessage>Erro ao buscar dados da unidade.</EmptyMessage>
  }
  return (
    <Flex
      backgroundColor="white"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="80%"
      mb="16px"
      padding={6}
      flexDir={['column', 'column', 'column', 'column']}
      overflowX="hidden"
      overflowY="auto"
      css={{
        '::-webkit-scrollbar': {
          width: '4px',
          height: '4px'
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          background: 'gray'
        },
        '::-webkit-scrollbar-track': {
          borderRadius: '4px',
          background: 'white',
          border: '1px solid gray'
        }
      }}
    >
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '28%']} isReadOnly>
          <FormLabel
            htmlFor="name"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Nome da Unidade
          </FormLabel>
          <InputModal id="name" name="name" value={unitData?.name} />
        </FormControl>
        <FormControl
          w={['100%', '100%', '100%', '20%']}
          mt={[4, 4, 4, 0]}
          isReadOnly
        >
          <FormLabel
            htmlFor="consumer_unit"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Unidade Consumidora
          </FormLabel>
          <InputModal
            id="consumer_unit"
            name="consumer_unit"
            value={unitData?.consumer_unit}
          />
        </FormControl>

        <FormControl
          w={['100%', '100%', '100%', '20%']}
          mt={[4, 4, 4, 0]}
          isReadOnly
        >
          <FormLabel
            htmlFor="cnpj_unit"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            CNPJ da Unidade
          </FormLabel>
          <InputModal.CNPJ
            id="cnpj_unit"
            name="cnpj_unit"
            value={unitData?.cnpj_unit}
          />
        </FormControl>
        <FormControl
          w={['100%', '100%', '100%', '20%']}
          mt={[4, 4, 4, 0]}
          isReadOnly
        >
          <FormLabel
            htmlFor="unitUser_id"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Gestor da Unidade
          </FormLabel>
          <InputModal
            id="unitUser_id"
            name="unitUser_id"
            value={unitData?.user?.name}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        mt={6}
      >
        <FormControl w={['100%', '100%', '100%', '18%']}>
          <FormLabel
            htmlFor="distributor_id"
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Distribuidora
          </FormLabel>
          <ListSelect
            id="distributor_id"
            name="distributor_id"
            value={values.distributor_id}
            onChange={handleChange}
            error={errors.distributor_id}
            data={distributorData}
          />
        </FormControl>
        {!isRegulated && (
          <FormControl w={['100%', '100%', '100%', '18%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="supplier_id"
              display="flex"
              justifyContent={[
                'flex-start',
                'flex-start',
                'flex-start',
                'center'
              ]}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Comercializadora
            </FormLabel>
            <ListSelect
              id="supplier_id"
              name="supplier_id"
              value={values.supplier_id}
              onChange={handleChange}
              error={errors.supplier_id}
              data={supplierData}
            />
          </FormControl>
        )}
        <FormControl w={['100%', '100%', '100%', '18%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="voltageclass_id"
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Classe de Tensão
          </FormLabel>
          <ListSelect
            id="voltageclass_id"
            name="voltageclass_id"
            value={values.voltageclass_id}
            onChange={handleChange}
            error={errors.voltageclass_id}
            data={voltageclassData}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '18%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="tariffmodality_id"
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Modalidade Tarifária
          </FormLabel>
          <ListSelect
            id="tariffmodality_id"
            name="tariffmodality_id"
            value={values.tariffmodality_id}
            onChange={handleChange}
            error={errors.tariffmodality_id}
            data={tariffmodalityData}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '18%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="hiringenvironment_id"
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Ambiente de Contratação
          </FormLabel>
          <ListSelect
            id="hiringenvironment_id"
            name="hiringenvironment_id"
            value={values.hiringenvironment_id}
            onChange={handleChange}
            error={errors.hiringenvironment_id}
            data={hiringenvironmentData}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={[4, 4, 4, 8]}
      >
        <FormControl w={['100%', '100%', '100%', '15%']} mt={4}>
          {!isConventional && (
            <>
              <FormLabel
                htmlFor="offpeakdemandcontract"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Contratada F.P. (kW)
              </FormLabel>
              <InputModal
                id="offpeakdemandcontract"
                name="offpeakdemandcontract"
                value={values.offpeakdemandcontract}
                onChange={handleChange}
                error={errors.offpeakdemandcontract}
                placeholder="0 kW"
                type="number"
                textAlign="center"
              />
            </>
          )}
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          {!isConventional && !isGreen && (
            <>
              <FormLabel
                htmlFor="peakdemandcontract"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Contratada P. (kW)
              </FormLabel>
              <InputModal
                id="peakdemandcontract"
                name="peakdemandcontract"
                value={values.peakdemandcontract}
                onChange={handleChange}
                error={errors.peakdemandcontract}
                placeholder="0 kW"
                type="number"
                textAlign="center"
              />
            </>
          )}
        </FormControl>
        <FormControl
          w={['100%', '100%', '100%', '15%']}
          mt={[4, 4, 4, 0]}
        ></FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="start_date"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Início da Leitura
          </FormLabel>
          <InputModal
            id="start_date"
            name="start_date"
            value={values.start_date}
            onChange={handleChange}
            error={errors.start_date}
            type="date"
            justifyContent="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="end_date"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Fim da Leitura
          </FormLabel>
          <InputModal
            id="end_date"
            name="end_date"
            value={values.end_date}
            onChange={handleChange}
            error={errors.end_date}
            type="date"
            justifyContent="center"
          />
        </FormControl>

        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="total_cost"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor Total da Fatura (R$)
          </FormLabel>
          <InputModal.Currency
            id="total_cost"
            name="total_cost"
            value={values.total_cost}
            onChange={(val) => setFieldValue('total_cost', val)}
            error={errors.total_cost}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
      </Flex>
      {!isRegulated && (
        <Flex
          flexDir={['column', 'column', 'column', 'row']}
          justifyContent="flex-end"
          alignItems="flex-end"
          mt={[4, 4, 4, 8]}
        >
          <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="energydiscount"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Desconto Energia (%)
            </FormLabel>
            <InputModal.Currency
              id="energydiscount"
              name="energydiscount"
              value={values.energydiscount}
              onChange={(val) => setFieldValue('energydiscount', val)}
              error={errors.energydiscount}
              placeholder="0%"
              textAlign="center"
            />
          </FormControl>
        </Flex>
      )}
      {!isConventional && (
        <Flex flexDir="column">
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter
              fontSize="14px"
              fontWeight="semibold"
              bg="white"
              px="4"
            >
              Demanda
            </AbsoluteCenter>
          </Box>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="offpeakdemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Medida F.P. (kW)
              </FormLabel>
              <InputModal.Currency
                id="offpeakdemand"
                name="offpeakdemand"
                value={values.offpeakdemand}
                onChange={(val) => setFieldValue('offpeakdemand', val)}
                error={errors.offpeakdemand}
                placeholder="0 kW"
                textAlign="center"
              />
            </FormControl>

            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="peakdemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Medida P. (kW)
              </FormLabel>
              <InputModal.Currency
                id="peakdemand"
                name="peakdemand"
                value={values.peakdemand}
                onChange={(val) => setFieldValue('peakdemand', val)}
                error={errors.peakdemand}
                placeholder="0 kW"
                textAlign="center"
              />
            </FormControl>

            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="offpeaksurplus_reactive_demand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Excedente Reativo F.P. (kvar)
              </FormLabel>
              <InputModal.Currency
                id="offpeaksurplus_reactive_demand"
                name="offpeaksurplus_reactive_demand"
                value={values.offpeaksurplus_reactive_demand}
                onChange={(val) =>
                  setFieldValue('offpeaksurplus_reactive_demand', val)
                }
                error={errors.offpeaksurplus_reactive_demand}
                placeholder="0 kvar"
                textAlign="center"
              />
            </FormControl>

            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              {!isGreen && (
                <>
                  <FormLabel
                    htmlFor="peaksurplus_reactive_demand"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Demanda Excedente Reativo P. (kvar)
                  </FormLabel>
                  <InputModal.Currency
                    id="peaksurplus_reactive_demand"
                    name="peaksurplus_reactive_demand"
                    value={values.peaksurplus_reactive_demand}
                    onChange={(val) =>
                      setFieldValue('peaksurplus_reactive_demand', val)
                    }
                    error={errors.peaksurplus_reactive_demand}
                    placeholder="0 kvar"
                    textAlign="center"
                  />
                </>
              )}
            </FormControl>
          </Flex>
        </Flex>
      )}
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Consumo
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="offpeakconsumption"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            {isConventional ? 'Consumo (kW/h)' : 'Consumo F.P. (kW/h)'}
          </FormLabel>
          <InputModal.Currency
            id="offpeakconsumption"
            name="offpeakconsumption"
            value={values.offpeakconsumption}
            onChange={(val) => setFieldValue('offpeakconsumption', val)}
            error={errors.offpeakconsumption}
            placeholder="0 kWh"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          {!isConventional && (
            <>
              <FormLabel
                htmlFor="peakconsumption"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo P. (kW/h)
              </FormLabel>
              <InputModal.Currency
                id="peakconsumption"
                name="peakconsumption"
                value={values.peakconsumption}
                onChange={(val) => setFieldValue('peakconsumption', val)}
                error={errors.peakconsumption}
                placeholder="0 kWh"
                textAlign="center"
              />
            </>
          )}
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="offpeak_surplus_reactive_power"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            {isConventional
              ? 'Consumo Excedente Reativo (kvar)'
              : 'Consumo Excedente Reativo F.P. (kvar)'}
          </FormLabel>
          <InputModal.Currency
            id="offpeak_surplus_reactive_power"
            name="offpeak_surplus_reactive_power"
            value={values.offpeak_surplus_reactive_power}
            onChange={(val) =>
              setFieldValue('offpeak_surplus_reactive_power', val)
            }
            error={errors.offpeak_surplus_reactive_power}
            placeholder="0 kvar"
            textAlign="center"
          />
        </FormControl>

        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          {!isConventional && (
            <>
              <FormLabel
                htmlFor="peak_surplus_reactive_power"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo Excedente Reativo P. (kvar)
              </FormLabel>
              <InputModal.Currency
                id="peak_surplus_reactive_power"
                name="peak_surplus_reactive_power"
                value={values.peak_surplus_reactive_power}
                onChange={(val) =>
                  setFieldValue('peak_surplus_reactive_power', val)
                }
                error={errors.peak_surplus_reactive_power}
                placeholder="0 kvar"
                textAlign="center"
              />
            </>
          )}
        </FormControl>
      </Flex>
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Lançamentos e Serviços
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="public_lighting"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Iluminação Pública (R$)
          </FormLabel>
          <InputModal.Currency
            id="public_lighting"
            name="public_lighting"
            value={values.public_lighting}
            onChange={(val) => setFieldValue('public_lighting', val)}
            error={errors.public_lighting}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="fines"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Multas (R$)
          </FormLabel>
          <InputModal.Currency
            id="fines"
            name="fines"
            value={values.fines}
            onChange={(val) => setFieldValue('fines', val)}
            error={errors.fines}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="fees"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Juros (R$)
          </FormLabel>
          <InputModal.Currency
            id="fees"
            name="fees"
            value={values.fees}
            onChange={(val) => setFieldValue('fees', val)}
            error={errors.fees}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>

        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="billingadjustment"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Ajuste de Faturamento (R$)
          </FormLabel>
          <InputModal.Currency
            id="billingadjustment"
            name="billingadjustment"
            value={values.billingadjustment}
            onChange={(val) => setFieldValue('billingadjustment', val)}
            error={errors.billingadjustment}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        {!isConventional && (
          <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="supplementary_demand"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Demanda Complementar (R$)
            </FormLabel>
            <InputModal.Currency
              id="supplementary_demand"
              name="supplementary_demand"
              value={values.supplementary_demand}
              onChange={(val) => setFieldValue('supplementary_demand', val)}
              error={errors.supplementary_demand}
              placeholder="R$ 0,00"
              textAlign="center"
            />
          </FormControl>
        )}
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="others"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Outros (R$)
          </FormLabel>
          <InputModal.Currency
            id="others"
            name="others"
            value={values.others}
            onChange={(val) => setFieldValue('others', val)}
            error={errors.others}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
      </Flex>
      {!isRegulated && (
        <Flex
          flexDir={['column', 'column', 'column', 'row']}
          justifyContent="flex-start"
          alignItems="flex-end"
          mt={[4, 4, 4, 8]}
        >
          <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="chargesAndRefunds"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Cobranças e Devoluções (R$)
            </FormLabel>
            <InputModal.Currency
              id="chargesAndRefunds"
              name="chargesAndRefunds"
              value={values.chargesAndRefunds}
              onChange={(val) => setFieldValue('chargesAndRefunds', val)}
              error={errors.chargesAndRefunds}
              placeholder="R$ 0,00"
              textAlign="center"
            />
          </FormControl>
        </Flex>
      )}
      {!isRegulated && (
        <>
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter
              fontSize="14px"
              fontWeight="semibold"
              bg="white"
              px="4"
            >
              Tributação
            </AbsoluteCenter>
          </Box>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Flex flexDir="column" w={['100%', '100%', '100%', '50%']}>
              <Flex
                justifyContent="space-between"
                flexDir={['column', 'column', 'column', 'row']}
              >
                <FormControl
                  w={['100%', '100%', '100%', '30%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="offpeaktaxsubstitution"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Substituição Tributária F.P. (R$)
                  </FormLabel>
                  <InputModal.Currency
                    id="offpeaktaxsubstitution"
                    name="offpeaktaxsubstitution"
                    value={values.offpeaktaxsubstitution}
                    onChange={(val) =>
                      setFieldValue('offpeaktaxsubstitution', val)
                    }
                    error={errors.offpeaktaxsubstitution}
                    placeholder="R$ 0,00"
                    textAlign="center"
                  />
                </FormControl>
                <FormControl
                  w={['100%', '100%', '100%', '30%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="peaktaxsubstitution"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Substituição Tributária P. (R$)
                  </FormLabel>
                  <InputModal.Currency
                    id="peaktaxsubstitution"
                    name="peaktaxsubstitution"
                    value={values.peaktaxsubstitution}
                    onChange={(val) =>
                      setFieldValue('peaktaxsubstitution', val)
                    }
                    error={errors.peaktaxsubstitution}
                    placeholder="R$ 0,00"
                    textAlign="center"
                  />
                </FormControl>
                <FormControl
                  w={['100%', '100%', '100%', '30%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="energydeduction"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Dedução de Energia (R$)
                  </FormLabel>
                  <InputModal.Currency
                    id="energydeduction"
                    name="energydeduction"
                    value={values.energydeduction}
                    onChange={(val) => setFieldValue('energydeduction', val)}
                    error={errors.energydeduction}
                    placeholder="R$ 0,00"
                    textAlign="center"
                  />
                </FormControl>
              </Flex>
              <Flex
                flexDir={['column', 'column', 'column', 'row']}
                justifyContent="flex-start"
                alignItems="flex-end"
                mt={[4, 4, 4, 8]}
              >
                <FormControl w={['100%', '100%', '100%', '100%']}>
                  <FormLabel
                    htmlFor="hasDevec"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Declaração DEVEC
                  </FormLabel>
                  <Checkbox
                    id="hasDevec"
                    name="hasDevec"
                    isChecked={values.hasDevec}
                    onChange={handleChange}
                    error={errors.hasDevec}
                    mb="0px"
                    fontWeight="semibold"
                    color="#303030"
                    alignItems="center"
                    colorScheme="green"
                  >
                    <Flex flexDir="row">
                      <Text fontSize="13px" fontWeight="normal">
                        {values.hasDevec ? 'Sim' : 'Não'}
                      </Text>
                    </Flex>
                  </Checkbox>
                </FormControl>
              </Flex>
            </Flex>

            <Flex
              display="flex"
              flexDir="column"
              w={['100%', '100%', '100%', '35%']}
            >
              <Flex
                flexDir={['column', 'column', 'column', 'row']}
                justifyContent="space-between"
                w="100%"
              >
                <FormControl
                  w={['100%', '100%', '100%', '45%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="grosstariffsubsidy1"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Subsídio Tarifário Bruto (R$)
                  </FormLabel>
                  <InputModal.Currency
                    id="grosstariffsubsidy1"
                    name="grosstariffsubsidy1"
                    value={values.grosstariffsubsidy1}
                    onChange={(val) =>
                      setFieldValue('grosstariffsubsidy1', val)
                    }
                    error={errors.grosstariffsubsidy1}
                    placeholder="R$ 0,00"
                    textAlign="center"
                  />
                </FormControl>
                <FormControl
                  w={['100%', '100%', '100%', '45%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="nettariffsubsidy1"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Subsídio Tarifário Líquido (R$)
                  </FormLabel>
                  <InputModal.Currency
                    id="nettariffsubsidy1"
                    name="nettariffsubsidy1"
                    value={values.nettariffsubsidy1}
                    onChange={(val) => setFieldValue('nettariffsubsidy1', val)}
                    error={errors.nettariffsubsidy1}
                    placeholder="R$ 0,00"
                    textAlign="center"
                  />
                </FormControl>
              </Flex>
              <Flex
                flexDir={['column', 'column', 'column', 'row']}
                justifyContent="space-between"
                w="100%"
                mt={[0, 0, 0, 4]}
              >
                <FormControl
                  w={['100%', '100%', '100%', '45%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="grosstariffsubsidy2"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Subsídio Tarifário Bruto (R$)
                  </FormLabel>
                  <InputModal.Currency
                    id="grosstariffsubsidy2"
                    name="grosstariffsubsidy2"
                    value={values.grosstariffsubsidy2}
                    onChange={(val) =>
                      setFieldValue('grosstariffsubsidy2', val)
                    }
                    error={errors.grosstariffsubsidy2}
                    placeholder="R$ 0,00"
                    textAlign="center"
                  />
                </FormControl>
                <FormControl
                  w={['100%', '100%', '100%', '45%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="nettariffsubsidy2"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Subsídio Tarifário Líquido (R$)
                  </FormLabel>
                  <InputModal.Currency
                    id="nettariffsubsidy2"
                    name="nettariffsubsidy2"
                    value={values.nettariffsubsidy2}
                    onChange={(val) => setFieldValue('nettariffsubsidy2', val)}
                    error={errors.nettariffsubsidy2}
                    placeholder="R$ 0,00"
                    textAlign="center"
                  />
                </FormControl>
              </Flex>
              <Flex
                flexDir={['column', 'column', 'column', 'row']}
                justifyContent="space-between"
                w="100%"
                mt={[0, 0, 0, 4]}
              >
                <FormControl
                  w={['100%', '100%', '100%', '45%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="grosstariffsubsidy3"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Subsídio Tarifário Bruto (R$)
                  </FormLabel>
                  <InputModal.Currency
                    id="grosstariffsubsidy3"
                    name="grosstariffsubsidy3"
                    value={values.grosstariffsubsidy3}
                    onChange={(val) =>
                      setFieldValue('grosstariffsubsidy3', val)
                    }
                    error={errors.grosstariffsubsidy3}
                    placeholder="R$ 0,00"
                    textAlign="center"
                  />
                </FormControl>
                <FormControl
                  w={['100%', '100%', '100%', '45%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="nettariffsubsidy3"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Subsídio Tarifário Líquido (R$)
                  </FormLabel>
                  <InputModal.Currency
                    id="nettariffsubsidy3"
                    name="nettariffsubsidy3"
                    value={values.nettariffsubsidy3}
                    onChange={(val) => setFieldValue('nettariffsubsidy3', val)}
                    error={errors.nettariffsubsidy3}
                    placeholder="R$ 0,00"
                    textAlign="center"
                  />
                </FormControl>
              </Flex>
            </Flex>
          </Flex>

          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter
              fontSize="14px"
              fontWeight="semibold"
              bg="white"
              px="4"
            >
              Energia
            </AbsoluteCenter>
          </Box>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="energyconsumption"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo de Energia (kW/h)
              </FormLabel>
              <InputModal.Currency
                id="energyconsumption"
                name="energyconsumption"
                value={values.energyconsumption}
                onChange={(val) => setFieldValue('energyconsumption', val)}
                error={errors.energyconsumption}
                placeholder="0 kWh"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="priceenergyconsumption"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Preço da Energia (R$/kWh)
              </FormLabel>
              <InputModal.Currency
                id="priceenergyconsumption"
                name="priceenergyconsumption"
                value={values.priceenergyconsumption}
                onChange={(val) => setFieldValue('priceenergyconsumption', val)}
                error={errors.priceenergyconsumption}
                placeholder="R$/kWh 0,00"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="total_cost_energyconsumption"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor Total (R$)
              </FormLabel>
              <InputModal.Currency
                id="total_cost_energyconsumption"
                name="total_cost_energyconsumption"
                value={values.total_cost_energyconsumption}
                onChange={(val) =>
                  setFieldValue('total_cost_energyconsumption', val)
                }
                error={errors.total_cost_energyconsumption}
                placeholder="R$ 0,00"
                type="number"
                textAlign="center"
              />
            </FormControl>
          </Flex>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
              <FormLabel
                htmlFor="surplusenergy"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Energia Curto Prazo (kW/h)
              </FormLabel>
              <InputModal.Currency
                id="surplusenergy"
                name="surplusenergy"
                value={values.surplusenergy}
                onChange={(val) => setFieldValue('surplusenergy', val)}
                error={errors.surplusenergy}
                placeholder="0 kWh"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
              <FormLabel
                htmlFor="pricesurplusenergy"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Preço da Energia (R$/kWh)
              </FormLabel>
              <InputModal.Currency
                id="pricesurplusenergy"
                name="pricesurplusenergy"
                value={values.pricesurplusenergy}
                onChange={(val) => setFieldValue('pricesurplusenergy', val)}
                error={errors.pricesurplusenergy}
                placeholder="R$/kWh 0,00"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
              <FormLabel
                htmlFor="total_cost_surplusenergy"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor Total (R$)
              </FormLabel>
              <InputModal.Currency
                id="total_cost_surplusenergy"
                name="total_cost_surplusenergy"
                value={values.total_cost_surplusenergy}
                onChange={(val) =>
                  setFieldValue('total_cost_surplusenergy', val)
                }
                error={errors.total_cost_surplusenergy}
                placeholder="R$ 0,00"
                type="number"
                textAlign="center"
              />
            </FormControl>
          </Flex>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
              <FormLabel
                htmlFor="energyassignment"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Cessão de Energia (kW/h)
              </FormLabel>
              <InputModal.Currency
                id="energyassignment"
                name="energyassignment"
                value={values.energyassignment}
                onChange={(val) => setFieldValue('energyassignment', val)}
                error={errors.energyassignment}
                placeholder="0 kWh"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
              <FormLabel
                htmlFor="priceenergyassignment"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Preço da Energia (R$/kWh)
              </FormLabel>
              <InputModal.Currency
                id="priceenergyassignment"
                name="priceenergyassignment"
                value={values.priceenergyassignment}
                onChange={(val) => setFieldValue('priceenergyassignment', val)}
                error={errors.priceenergyassignment}
                placeholder="R$/kWh 0,00"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
              <FormLabel
                htmlFor="total_cost_energyassignment"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor Total (R$)
              </FormLabel>
              <InputModal.Currency
                id="total_cost_energyassignment"
                name="total_cost_energyassignment"
                value={values.total_cost_energyassignment}
                onChange={(val) =>
                  setFieldValue('total_cost_energyassignment', val)
                }
                error={errors.total_cost_energyassignment}
                placeholder="R$ 0,00"
                textAlign="center"
              />
            </FormControl>
          </Flex>
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter
              fontSize="14px"
              fontWeight="semibold"
              bg="white"
              px="4"
            >
              Encargos CCEE
            </AbsoluteCenter>
          </Box>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="associationfee"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Contribuição Associativa (R$)
              </FormLabel>
              <InputModal.Currency
                id="associationfee"
                name="associationfee"
                value={values.associationfee}
                onChange={(val) => setFieldValue('associationfee', val)}
                error={errors.associationfee}
                placeholder="R$ 0,00"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="financialsettlement"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Liquidação Financeira (R$)
              </FormLabel>
              <InputModal.Currency
                id="financialsettlement"
                name="financialsettlement"
                value={values.financialsettlement}
                onChange={(val) => setFieldValue('financialsettlement', val)}
                error={errors.financialsettlement}
                placeholder="R$ 0,00"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="reserveenergycharges"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Energia de Reserva (R$)
              </FormLabel>
              <InputModal.Currency
                id="reserveenergycharges"
                name="reserveenergycharges"
                value={values.reserveenergycharges}
                onChange={(val) => setFieldValue('reserveenergycharges', val)}
                error={errors.reserveenergycharges}
                placeholder="R$ 0,00"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="ercap"
                display="flex"
                textAlign="flex-start"
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Encargo de Potência para Reserva de Capacidade (R$)
              </FormLabel>
              <InputModal.Currency
                id="ercap"
                name="ercap"
                value={values.ercap}
                onChange={(val) => setFieldValue('ercap', val)}
                error={errors.ercap}
                placeholder="R$ 0,00"
                textAlign="center"
              />
            </FormControl>
          </Flex>
        </>
      )}
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Variáveis
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={4}
      >
        <FormControl
          display="flex"
          flexDir="column"
          alignItems="center"
          w={['100%', '100%', '100%', '30%']}
          mt={[4, 4, 4, 0]}
        >
          <FormLabel
            htmlFor="noICMSinTUSD"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="14px"
            fontWeight="semibold"
            color="#303030"
          >
            TUSD
          </FormLabel>
          <Checkbox
            id="noICMSinTUSD"
            name="noICMSinTUSD"
            isChecked={values.noICMSinTUSD}
            onChange={handleChange}
            error={errors.noICMSinTUSD}
            mb="0px"
            fontWeight="semibold"
            color="#303030"
            alignItems="center"
            colorScheme="green"
          >
            <Flex flexDir="row">
              <Text fontSize="13px" fontWeight="normal">
                {values.noICMSinTUSD ? 'Sem ICMS' : 'Com ICMS'}
              </Text>
            </Flex>
          </Checkbox>
        </FormControl>

        {!isConventional && (
          <>
            {' '}
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              w={['100%', '100%', '100%', '30%']}
              mt={[4, 4, 4, 0]}
            >
              <FormLabel
                htmlFor="noICMSinDemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="14px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda
              </FormLabel>
              <Checkbox
                id="noICMSinDemand"
                name="noICMSinDemand"
                isChecked={values.noICMSinDemand}
                onChange={handleChange}
                error={errors.noICMSinDemand}
                mb="0px"
                fontWeight="semibold"
                color="#303030"
                alignItems="center"
                colorScheme="green"
              >
                <Flex flexDir="row">
                  <Text fontSize="13px" fontWeight="normal">
                    {values.noICMSinDemand ? 'Sem ICMS' : 'Com ICMS'}
                  </Text>
                </Flex>
              </Checkbox>
            </FormControl>
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              w={['100%', '100%', '100%', '30%']}
              mt={[4, 4, 4, 0]}
            >
              <FormLabel
                htmlFor="noICMSinConsumptionTUSD"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="14px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo TUSD
              </FormLabel>
              <Checkbox
                id="noICMSinConsumptionTUSD"
                name="noICMSinConsumptionTUSD"
                isChecked={values.noICMSinConsumptionTUSD}
                onChange={handleChange}
                error={errors.noICMSinConsumptionTUSD}
                mb="0px"
                fontWeight="semibold"
                color="#303030"
                alignItems="center"
                colorScheme="green"
              >
                <Flex flexDir="row">
                  <Text fontSize="13px" fontWeight="normal">
                    {values.noICMSinConsumptionTUSD ? 'Sem ICMS' : 'Com ICMS'}
                  </Text>
                </Flex>
              </Checkbox>
            </FormControl>
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              w={['100%', '100%', '100%', '30%']}
              mt={[4, 4, 4, 0]}
            >
              <FormLabel
                htmlFor="hasICMSinDemandUnused"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="14px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Não Usada
              </FormLabel>
              <Checkbox
                id="hasICMSinDemandUnused"
                name="hasICMSinDemandUnused"
                isChecked={values.hasICMSinDemandUnused}
                onChange={handleChange}
                error={errors.hasICMSinDemandUnused}
                mb="0px"
                fontWeight="semibold"
                color="#303030"
                alignItems="center"
                colorScheme="green"
              >
                <Flex flexDir="row">
                  <Text fontSize="13px" fontWeight="normal">
                    {values.hasICMSinDemandUnused ? 'Com ICMS' : 'Sem ICMS'}
                  </Text>
                </Flex>
              </Checkbox>
            </FormControl>
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              w={['100%', '100%', '100%', '30%']}
              mt={[4, 4, 4, 0]}
            >
              <FormLabel
                htmlFor="isSeasonality"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="14px"
                fontWeight="semibold"
                color="#303030"
              >
                Sazonalidade
              </FormLabel>
              <Checkbox
                id="isSeasonality"
                name="isSeasonality"
                isChecked={values.isSeasonality}
                onChange={handleChange}
                error={errors.isSeasonality}
                mb="0px"
                fontWeight="semibold"
                color="#303030"
                alignItems="center"
                colorScheme="green"
              >
                <Flex flexDir="row">
                  <Text fontSize="13px" fontWeight="normal">
                    {values.isSeasonality ? 'Sim' : 'Não'}
                  </Text>
                </Flex>
              </Checkbox>
            </FormControl>
          </>
        )}
      </Flex>
      {!isRegulated && !isConventional && (
        <Flex
          flexDir={['column', 'column', 'column', 'row']}
          justifyContent="flex-start"
          alignItems="flex-end"
          mt={[4, 4, 4, 12]}
        >
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            w={['100%', '100%', '100%', '20%']}
            mt={[4, 4, 4, 0]}
          >
            <FormLabel
              htmlFor="hasWaterScarcityFee"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="14px"
              fontWeight="semibold"
              color="#303030"
            >
              Escassez Hídrica
            </FormLabel>
            <Checkbox
              id="hasWaterScarcityFee"
              name="hasWaterScarcityFee"
              isChecked={values.hasWaterScarcityFee}
              onChange={handleChange}
              error={errors.hasWaterScarcityFee}
              mb="0px"
              fontWeight="semibold"
              color="#303030"
              alignItems="center"
              colorScheme="green"
            >
              <Flex flexDir="row">
                <Text fontSize="13px" fontWeight="normal">
                  {values.hasWaterScarcityFee ? 'Sim' : 'Não'}
                </Text>
              </Flex>
            </Checkbox>
          </FormControl>
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            w={['100%', '100%', '100%', '20%']}
            mt={[4, 4, 4, 0]}
          >
            <FormLabel
              htmlFor="hasCovidAccount"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="14px"
              fontWeight="semibold"
              color="#303030"
            >
              Conta COVID
            </FormLabel>
            <Checkbox
              id="hasCovidAccount"
              name="hasCovidAccount"
              isChecked={values.hasCovidAccount}
              onChange={handleChange}
              error={errors.hasCovidAccount}
              mb="0px"
              fontWeight="semibold"
              color="#303030"
              alignItems="center"
              colorScheme="green"
            >
              <Flex flexDir="row">
                <Text fontSize="13px" fontWeight="normal">
                  {values.hasCovidAccount ? 'Sim' : 'Não'}
                </Text>
              </Flex>
            </Checkbox>
          </FormControl>
        </Flex>
      )}
      <Flex
        justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
        mt={[4, 4, 4, 10]}
      >
        <Button
          mt={[6, 6, 6, 0]}
          isLoading={mutation.isLoading}
          onClick={handleSubmit}
          bg="#F2920A"
          color="#ffffff"
          h="34px"
          _hover={{
            color: 'brand.background_home',
            bg: 'brand.background'
          }}
        >
          Calcular
        </Button>
      </Flex>
    </Flex>
  )
}
