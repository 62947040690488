import { Tbody } from '@chakra-ui/react'
import { Unit } from 'Components'

export const ListUnit = ({ data, onDelete }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <Unit key={`unit_${item.id}`} {...item} onDelete={onDelete} />
        ))}
    </Tbody>
  )
}
