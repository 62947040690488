import React from 'react'
import moment from 'moment'
import { Td, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  MenuMoreCompany,
  ModalDeleteCompany,
  ModalEditCompany
} from 'Components'
import { getCountUnit } from 'services/api/requests'
import { useQuery } from 'react-query'

export const Company = ({
  id,
  name,
  cnpj_company,
  commercial_phone,
  commercial_email,
  contract_start,
  contract_end,
  renovation,
  onDelete
}) => {
  const navigate = useNavigate()

  const [showModalDelete, setShowModalDelete] = React.useState()
  const [showModalEdit, setShowModalEdit] = React.useState()

  const formattedContractEnd = moment.utc(contract_end).format('DD/MM/YYYY')

  const onCloseModalDelete = () => {
    setShowModalDelete(null)
  }

  const onCloseModalEdit = () => {
    setShowModalEdit(null)
  }

  const handleDelete = () => {
    onDelete(id)
    onCloseModalDelete()
  }

  const { data } = useQuery(['unitCount', id], () => getCountUnit(id))
  const countUnit = data?.data || []

  const fixText = countUnit > 1 ? ' unidades' : ' unidade'
  const unitText = countUnit < 1 ? 'Sem unidade' : `${countUnit} ${fixText}`

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{cnpj_company}</Td>
      <Td>{unitText}</Td>
      <Td>{formattedContractEnd}</Td>
      <Td display="flex" flexDir="row" justifyContent="space-between">
        <Button.detail onClick={() => navigate(`/clienttec/company/${id}`)} />
        <MenuMoreCompany
          setShowModalDelete={setShowModalDelete}
          setShowModalEdit={setShowModalEdit}
        />

        {showModalDelete === 'company' && (
          <ModalDeleteCompany
            onDelete={handleDelete}
            onClose={onCloseModalDelete}
          />
        )}
        {showModalEdit === 'company' && (
          <ModalEditCompany
            onClose={onCloseModalEdit}
            id={id}
            company={{
              name,
              cnpj_company,
              commercial_phone,
              commercial_email,
              contract_start,
              contract_end,
              renovation
            }}
          />
        )}
      </Td>
    </Tr>
  )
}
