import React from 'react'
import { Flex, Spinner, Image } from '@chakra-ui/react'

export const LoadingScreen = () => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      backgroundColor="#383838" // ou outra cor de fundo desejada
      position="fixed"
      top={0}
      left={0}
      zIndex={9999}
    >
      <Flex
        width="300px"
        height="230px"
        borderRadius="12px"
        backgroundColor="background"
        flexDir="column"
        alignItems="center"
      >
        <Image src="/img/Logo.svg" alt="Logo" />
        <Spinner size="xl" color="blue.500" />
      </Flex>
    </Flex>
  )
}
