import { apiAneel } from 'services/api'

export const getFlags = async () => {
  try {
    const response = await apiAneel.get('datastore_search', {
      params: {
        resource_id: '0591b8f6-fe54-437b-b72b-1aa2efd46e42',
        limit: 100000
      }
    })

    return response.data.result.records
  } catch (error) {
    console.error('Erro ao buscar bandeiras tarifárias:', error)
    throw error
  }
}
