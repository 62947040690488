import { api } from 'services/api'

export const registerVoltageClass = (data) =>
  api.post('/voltageclass', data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getVoltageClass = () =>
  api.get('/voltageclass', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const deleteVoltageClass = (id) =>
  api.delete(`/voltageclass/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })
