import { Tbody } from '@chakra-ui/react'
import { TariffModality } from 'Components'

export const ListTariffModality = ({ data, onDelete }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <TariffModality
            key={`tariffmodality_${item.id}`}
            {...item}
            onDelete={onDelete}
          />
        ))}
    </Tbody>
  )
}
