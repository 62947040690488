import React from 'react'
import {
  Button as ChakraButton,
  IconButton,
  Image,
  Flex
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

export const Button = ({ collapse, children, ...props }) => (
  <ChakraButton {...props}>{children}</ChakraButton>
)

Button.unauth = ({ children, ...props }) => (
  <ChakraButton
    display="flex"
    alignItems="center"
    textAlign="center"
    padding="0px"
    w="322px"
    h="38px"
    fontSize="14px"
    fontWeight="bold"
    color="brand.textintro"
    backgroundColor="brand.primary"
    borderRadius="8px"
    mt="32px"
    shadow="2px 2px
    10px rgba(0, 0, 0, 0.2)"
    _hover={{
      color: 'brand.background_home',
      bg: 'brand.background',
      boxShadow:
        'inset -1px -1px 8px rgba(0, 0, 0, 0.1), inset 1px 1px 8px rgba(0, 0, 0, 0.1)'
    }}
    {...props}
  >
    {children}
  </ChakraButton>
)

Button.perfil = ({ children, ...props }) => (
  <ChakraButton
    display="flex"
    alignItems="center"
    textAlign="center"
    padding="0px"
    w="322px"
    h="38px"
    fontSize="14px"
    fontWeight="regular"
    color="brand.textintro"
    backgroundColor="brand.primary"
    borderRadius="8px"
    mt="32px"
    shadow="2px 2px
    10px rgba(0, 0, 0, 0.2)"
    _hover={{
      color: 'brand.background_home',
      bg: 'brand.background',
      boxShadow:
        'inset -1px -1px 8px rgba(0, 0, 0, 0.1), inset 1px 1px 8px rgba(0, 0, 0, 0.1)'
    }}
    {...props}
  >
    {children}
  </ChakraButton>
)

Button.menu = ({ collapse, children, targetPath, targetSubPath, ...props }) => {
  const location = useLocation()

  const [isActive, setActive] = React.useState()

  React.useEffect(() => {
    if (location.pathname.startsWith(targetPath)) {
      setActive(true)
    } else {
      setActive()
    }
  }, [location.pathname, targetPath])

  return (
    <ChakraButton
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      textAlign="center"
      paddingLeft="12px"
      w={collapse ? '54px' : '220px'}
      style={{ transition: 'width 0.6s' }}
      h="34px"
      fontSize="14px"
      fontWeight={isActive ? 'semibold' : 'medium'}
      color={isActive ? 'brand.background_home' : 'brand.textintro'}
      bg={isActive ? 'brand.background' : 'none'}
      border="1px"
      borderColor="#EAEBF0"
      borderRadius="8px"
      mt="12px"
      shadow="2px 2px
      10px rgba(0, 0, 0, 0.2)"
      boxShadow={
        isActive
          ? 'inset -1px -1px 8px rgba(0, 0, 0, 0.1), inset 1px 1px 8px rgba(0, 0, 0, 0.1)'
          : 'none'
      }
      _hover={
        isActive
          ? {}
          : {
              color: 'brand.background_home',
              fontWeight: 'bold',
              bg: 'brand.background',
              borderColor: 'brand.primary',
              boxShadow:
                'inset -1px -1px 8px rgba(0, 0, 0, 0.1), inset 1px 1px 8px rgba(0, 0, 0, 0.1)',

              transform: 'scale(1.05)'
            }
      }
      {...props}
    >
      {children}
    </ChakraButton>
  )
}

Button.add = ({ children, ...props }) => (
  <ChakraButton
    display="flex"
    alignItems="center"
    justifyContent="flex-start"
    textAlign="center"
    paddingLeft="12px"
    w="128px"
    style={{ transition: 'width 0.6s' }}
    h="34px"
    fontSize="14px"
    fontWeight="bold"
    color="brand.background_home"
    bg="brand.primary"
    borderRadius="8px"
    mt="12px"
    shadow="2px 2px
    10px rgba(0, 0, 0, 0.2)"
    _hover={{
      color: 'brand.background_home',
      fontWeight: 'semibold',
      bg: 'brand.background',
      borderColor: 'brand.primary',
      boxShadow:
        'inset -1px -1px 8px rgba(0, 0, 0, 0.1), inset 1px 1px 8px rgba(0, 0, 0, 0.1)',

      transform: 'scale(1.05)'
    }}
    {...props}
  >
    {children}
  </ChakraButton>
)

Button.logout = ({ children, ...props }) => (
  <Flex
    display="flex"
    alignItems="center"
    justifyContent="center"
    color="none"
    bg="none"
    borderRadius="8px"
    shadow="none"
    _hover={{
      color: 'none',

      bg: 'none',
      borderColor: 'none',
      boxShadow: 'none'
    }}
    {...props}
  >
    <IconButton
      aria-label="Logout"
      bg="none"
      mt="20px"
      icon={
        <Image src="/img/IconLogout.svg" alt="IconLogout" w="34px" h="34px" />
      }
      _hover={{
        bg: 'none',
        transform: 'scale(1.2)'
      }}
    ></IconButton>
    {children}
  </Flex>
)

Button.detail = ({ children, company, ...props }) => (
  <Flex
    display="flex"
    alignItems="center"
    justifyContent="center"
    color="none"
    bg="none"
    borderRadius="8px"
    shadow="none"
    _hover={{
      color: 'none',

      bg: 'none',
      borderColor: 'none',
      boxShadow: 'none'
    }}
    {...props}
  >
    <IconButton
      company={company}
      aria-label="Unidades"
      bg="none"
      mt="0px"
      icon={
        <Image
          src="/img/IconUnidades.svg"
          alt="IconUnidades"
          w="26px"
          h="26px"
        />
      }
      _hover={{
        bg: 'none',
        transform: 'scale(1.2)'
      }}
    ></IconButton>
    {children}
  </Flex>
)

Button.listDetail = ({ children, unit, ...props }) => (
  <Flex
    display="flex"
    alignItems="center"
    justifyContent="center"
    color="none"
    bg="none"
    borderRadius="8px"
    shadow="none"
    _hover={{
      color: 'none',

      bg: 'none',
      borderColor: 'none',
      boxShadow: 'none'
    }}
    {...props}
  >
    <IconButton
      unit={unit}
      aria-label="UnitDetail"
      bg="none"
      mt="0px"
      icon={
        <Image
          src="/img/IconUnitDetail.svg"
          alt="IconUnitDetail"
          w="26px"
          h="26px"
        />
      }
      _hover={{
        bg: 'none',
        transform: 'scale(1.2)'
      }}
    ></IconButton>
    {children}
  </Flex>
)

Button.perfilEdit = ({ children, ...props }) => (
  <ChakraButton
    display="flex"
    alignItems="center"
    textAlign="center"
    w="84px"
    style={{ transition: 'width 0.6s' }}
    h="28px"
    fontSize="12px"
    fontWeight="semibold"
    color="brand.background"
    bg="brand.background_home"
    borderRadius="6px"
    mt="12px"
    shadow="2px 2px
    10px rgba(0, 0, 0, 0.2)"
    _hover={{
      color: 'brand.background_home',
      fontWeight: 'semibold',
      bg: 'brand.background',
      borderColor: 'brand.primary',
      boxShadow:
        'inset -1px -1px 8px rgba(0, 0, 0, 0.1), inset 1px 1px 8px rgba(0, 0, 0, 0.1)',

      transform: 'scale(1.05)'
    }}
    {...props}
  >
    {children}
  </ChakraButton>
)

Button.selectAnalysis = ({ children, ...props }) => (
  <ChakraButton
    display="flex"
    alignItems="center"
    textAlign="center"
    w="100px"
    h="140px"
    bg="brand.background_home"
    borderRadius="6px"
    shadow="2px 2px
    10px rgba(0, 0, 0, 0.2)"
    _hover={{
      transform: 'scale(1.08)'
    }}
    {...props}
  >
    {children}
  </ChakraButton>
)
