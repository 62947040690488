import { api } from 'services/api'

export const registerDistributor = (data) =>
  api.post('/distributor', data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getDistributor = () =>
  api.get('/distributor', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getDistributorById = (id) =>
  api.get(`/distributor/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const deleteDistributor = (id) =>
  api.delete(`/distributor/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const updateDistributor = (id, data) =>
  api.put(`/distributor/${id}`, data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })
