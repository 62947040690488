import {
  Flex,
  FormControl,
  FormLabel,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import { InputModal } from 'Components'

export const ReleasesSection = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  isRegulated,
  isConventional
}) => {
  return (
    <Flex flexDir="column">
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Lançamentos e Serviços
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="public_lighting"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Iluminação Pública (R$)
          </FormLabel>
          <InputModal.Currency
            id="public_lighting"
            name="public_lighting"
            value={values.public_lighting}
            onChange={(val) => setFieldValue('public_lighting', val)}
            error={errors.public_lighting}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="fines"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Multas (R$)
          </FormLabel>
          <InputModal.Currency
            id="fines"
            name="fines"
            value={values.fines}
            onChange={(val) => setFieldValue('fines', val)}
            error={errors.fines}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="fees"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Juros (R$)
          </FormLabel>
          <InputModal.Currency
            id="fees"
            name="fees"
            value={values.fees}
            onChange={(val) => setFieldValue('fees', val)}
            error={errors.fees}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>

        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="billingadjustment"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Ajuste de Faturamento (R$)
          </FormLabel>
          <InputModal.Currency
            id="billingadjustment"
            name="billingadjustment"
            value={values.billingadjustment}
            onChange={(val) => setFieldValue('billingadjustment', val)}
            error={errors.billingadjustment}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        {!isConventional && (
          <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="supplementary_demand"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Demanda Complementar (R$)
            </FormLabel>
            <InputModal.Currency
              id="supplementary_demand"
              name="supplementary_demand"
              value={values.supplementary_demand}
              onChange={(val) => setFieldValue('supplementary_demand', val)}
              error={errors.supplementary_demand}
              placeholder="R$ 0,00"
              textAlign="center"
            />
          </FormControl>
        )}
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="others"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Outros (R$)
          </FormLabel>
          <InputModal.Currency
            id="others"
            name="others"
            value={values.others}
            onChange={(val) => setFieldValue('others', val)}
            error={errors.others}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
      </Flex>
      {!isRegulated && (
        <Flex
          flexDir={['column', 'column', 'column', 'row']}
          justifyContent="flex-start"
          alignItems="flex-end"
          mt={[4, 4, 4, 8]}
        >
          <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="chargesAndRefunds"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Cobranças e Devoluções (R$)
            </FormLabel>
            <InputModal.Currency
              id="chargesAndRefunds"
              name="chargesAndRefunds"
              value={values.chargesAndRefunds}
              onChange={(val) => setFieldValue('chargesAndRefunds', val)}
              error={errors.chargesAndRefunds}
              placeholder="R$ 0,00"
              textAlign="center"
            />
          </FormControl>
        </Flex>
      )}
    </Flex>
  )
}
