import { Tbody } from '@chakra-ui/react'
import { Supplier } from 'Components'

export const ListSupplier = ({ data, onDelete }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <Supplier key={`supplier_${item.id}`} {...item} onDelete={onDelete} />
        ))}
    </Tbody>
  )
}
