import {
  Flex,
  FormControl,
  FormLabel,
  Box,
  Divider,
  AbsoluteCenter,
  Checkbox
} from '@chakra-ui/react'
import { Text } from 'Components'

export const VariablesSection = ({
  values,
  handleChange,
  errors,
  isRegulated,
  isConventional
}) => {
  return (
    <Flex flexDir="column">
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Variáveis
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={4}
      >
        <FormControl
          display="flex"
          flexDir="column"
          alignItems="center"
          w={['100%', '100%', '100%', '30%']}
          mt={[4, 4, 4, 0]}
        >
          <FormLabel
            htmlFor="noICMSinTUSD"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="14px"
            fontWeight="semibold"
            color="#303030"
          >
            TUSD
          </FormLabel>
          <Checkbox
            id="noICMSinTUSD"
            name="noICMSinTUSD"
            isChecked={values.noICMSinTUSD}
            onChange={handleChange}
            error={errors.noICMSinTUSD}
            mb="0px"
            fontWeight="semibold"
            color="#303030"
            alignItems="center"
            colorScheme="green"
          >
            <Flex flexDir="row">
              <Text fontSize="13px" fontWeight="normal">
                {values.noICMSinTUSD ? 'Sem ICMS' : 'Com ICMS'}
              </Text>
            </Flex>
          </Checkbox>
        </FormControl>

        {!isConventional && (
          <>
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              w={['100%', '100%', '100%', '30%']}
              mt={[4, 4, 4, 0]}
            >
              <FormLabel
                htmlFor="noICMSinDemand"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="14px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda
              </FormLabel>
              <Checkbox
                id="noICMSinDemand"
                name="noICMSinDemand"
                isChecked={values.noICMSinDemand}
                onChange={handleChange}
                error={errors.noICMSinDemand}
                mb="0px"
                fontWeight="semibold"
                color="#303030"
                alignItems="center"
                colorScheme="green"
              >
                <Flex flexDir="row">
                  <Text fontSize="13px" fontWeight="normal">
                    {values.noICMSinDemand ? 'Sem ICMS' : 'Com ICMS'}
                  </Text>
                </Flex>
              </Checkbox>
            </FormControl>
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              w={['100%', '100%', '100%', '30%']}
              mt={[4, 4, 4, 0]}
            >
              <FormLabel
                htmlFor="noICMSinConsumptionTUSD"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="14px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo TUSD
              </FormLabel>
              <Checkbox
                id="noICMSinConsumptionTUSD"
                name="noICMSinConsumptionTUSD"
                isChecked={values.noICMSinConsumptionTUSD}
                onChange={handleChange}
                error={errors.noICMSinConsumptionTUSD}
                mb="0px"
                fontWeight="semibold"
                color="#303030"
                alignItems="center"
                colorScheme="green"
              >
                <Flex flexDir="row">
                  <Text fontSize="13px" fontWeight="normal">
                    {values.noICMSinConsumptionTUSD ? 'Sem ICMS' : 'Com ICMS'}
                  </Text>
                </Flex>
              </Checkbox>
            </FormControl>
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              w={['100%', '100%', '100%', '30%']}
              mt={[4, 4, 4, 0]}
            >
              <FormLabel
                htmlFor="hasICMSinDemandUnused"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="14px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Não Usada
              </FormLabel>
              <Checkbox
                id="hasICMSinDemandUnused"
                name="hasICMSinDemandUnused"
                isChecked={values.hasICMSinDemandUnused}
                onChange={handleChange}
                error={errors.hasICMSinDemandUnused}
                mb="0px"
                fontWeight="semibold"
                color="#303030"
                alignItems="center"
                colorScheme="green"
              >
                <Flex flexDir="row">
                  <Text fontSize="13px" fontWeight="normal">
                    {values.hasICMSinDemandUnused ? 'Com ICMS' : 'Sem ICMS'}
                  </Text>
                </Flex>
              </Checkbox>
            </FormControl>
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              w={['100%', '100%', '100%', '30%']}
              mt={[4, 4, 4, 0]}
            >
              <FormLabel
                htmlFor="isSeasonality"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="14px"
                fontWeight="semibold"
                color="#303030"
              >
                Sazonalidade
              </FormLabel>
              <Checkbox
                id="isSeasonality"
                name="isSeasonality"
                isChecked={values.isSeasonality}
                onChange={handleChange}
                error={errors.isSeasonality}
                mb="0px"
                fontWeight="semibold"
                color="#303030"
                alignItems="center"
                colorScheme="green"
              >
                <Flex flexDir="row">
                  <Text fontSize="13px" fontWeight="normal">
                    {values.isSeasonality ? 'Sim' : 'Não'}
                  </Text>
                </Flex>
              </Checkbox>
            </FormControl>
          </>
        )}
      </Flex>
      {!isRegulated && !isConventional && (
        <Flex
          flexDir={['column', 'column', 'column', 'row']}
          justifyContent="flex-start"
          alignItems="flex-end"
          mt={[4, 4, 4, 12]}
        >
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            w={['100%', '100%', '100%', '20%']}
            mt={[4, 4, 4, 0]}
          >
            <FormLabel
              htmlFor="hasWaterScarcityFee"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="14px"
              fontWeight="semibold"
              color="#303030"
            >
              Escassez Hídrica
            </FormLabel>
            <Checkbox
              id="hasWaterScarcityFee"
              name="hasWaterScarcityFee"
              isChecked={values.hasWaterScarcityFee}
              onChange={handleChange}
              error={errors.hasWaterScarcityFee}
              mb="0px"
              fontWeight="semibold"
              color="#303030"
              alignItems="center"
              colorScheme="green"
            >
              <Flex flexDir="row">
                <Text fontSize="13px" fontWeight="normal">
                  {values.hasWaterScarcityFee ? 'Sim' : 'Não'}
                </Text>
              </Flex>
            </Checkbox>
          </FormControl>
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            w={['100%', '100%', '100%', '20%']}
            mt={[4, 4, 4, 0]}
          >
            <FormLabel
              htmlFor="hasCovidAccount"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="14px"
              fontWeight="semibold"
              color="#303030"
            >
              Conta COVID
            </FormLabel>

            <Checkbox
              id="hasCovidAccount"
              name="hasCovidAccount"
              isChecked={values.hasCovidAccount}
              onChange={handleChange}
              error={errors.hasCovidAccount}
              mb="0px"
              fontWeight="semibold"
              color="#303030"
              alignItems="center"
              colorScheme="green"
            >
              <Flex flexDir="row">
                <Text fontSize="13px" fontWeight="normal">
                  {values.hasCovidAccount ? 'Sim' : 'Não'}
                </Text>
              </Flex>
            </Checkbox>
          </FormControl>
        </Flex>
      )}
    </Flex>
  )
}
