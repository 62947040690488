import { Tbody } from '@chakra-ui/react'
import { DistributorTariff } from 'Components'

export const ListDistributorTariff = ({ data }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <DistributorTariff key={`distributor_${item.id}`} {...item} />
        ))}
    </Tbody>
  )
}
