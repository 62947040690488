import { Flex, Avatar, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { UserModal } from 'Components'

export const CardPerfil = () => {
  const userStore = useSelector((state) => state.user)

  return (
    <Flex
      display="flex"
      w="100%"
      h="260px"
      mt="50px"
      alignItems={['flex-start', 'center', 'center', 'center']}
      justifyContent="space-between"
    >
      <Flex
        display="flex"
        w="58%"
        h="120px"
        bg="brand.background_home"
        borderRadius="0px 10px 10px 0px"
        position="relative"
        flexDir="column"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Text
          mr="18px"
          fontSize={['18px', '18px', '24px', '25px']}
          fontFamily="Open Sans"
          fontWeight="600"
          color="brand.background"
        >
          {userStore?.user?.name}
        </Text>
        <Text
          mr="18px"
          fontFamily="Open Sans"
          fontStyle="italic"
          fontWeight="400"
          fontSize="16px"
          color="brand.primary"
        >
          {userStore?.user?.education}
        </Text>

        <Text
          mt="12px"
          mr="18px"
          fontFamily="Open Sans"
          fontWeight="semibold"
          fontSize="16px"
          color="brand.background"
        >
          {userStore?.user?.jobtitle}
        </Text>
      </Flex>
      <Flex
        display="flex"
        right="8%"
        position="absolute"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
      >
        <Avatar
          mt="8px"
          boxSize={['80px', '120px', '160px', '210px']}
          style={{ transition: '0.4s' }}
          name={userStore?.user?.name}
          src={userStore?.user?.avatar_url}
          borderWidth="4px"
          borderColor="brand.primary"
          backgroundColor="brand.background"
          shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
        />
        <UserModal />
      </Flex>
    </Flex>
  )
}
