import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Button, InputModal, ListSelect, statesBR } from 'Components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Flex,
  useDisclosure,
  Image,
  useToast
} from '@chakra-ui/react'
import {
  registerUnit,
  getDistributor,
  getSupplier,
  getVoltageClass,
  getTariffModality,
  getHiringEnvironment,
  getUser
} from 'services/api/requests'

export const ModalAddUnit = ({ id }) => {
  const toast = useToast()

  const queryClient = useQueryClient()

  const { data: distributor } = useQuery('distributor', getDistributor)
  const distributorData = distributor?.data

  const { data: supplier } = useQuery('supplier', getSupplier)
  const supplierData = supplier?.data

  const { data: voltageclass } = useQuery('voltageclass', getVoltageClass)
  const voltageclassData = voltageclass?.data || []

  const { data: tariffmodality } = useQuery('tariffmodality', getTariffModality)
  const tariffmodalityData = tariffmodality?.data || []

  const { data: hiringenvironment } = useQuery(
    'hiringenvironment',
    getHiringEnvironment
  )
  const hiringenvironmentData = hiringenvironment?.data || []

  const { data: user } = useQuery('user', getUser)
  const userData = user?.data || []

  const mutation = useMutation((data) => registerUnit(id, data), {
    onError: (error) => {
      toast({
        title: 'Falha ao cadastrar unidade.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Cadastro feito com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      queryClient.invalidateQueries(['unit'])
      onClose()
      resetForm()
    }
  })

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues: {
      name: '',
      consumer_unit: '',
      cnpj_unit: '',
      peakdemandcontract: '0',
      offpeakdemandcontract: '0',
      company_id: id,
      user_id: '',
      distributor_id: '',
      voltageclass_id: '',
      tariffmodality_id: '',
      hiringenvironment_id: '',
      city: '',
      state: '',
      validity: true
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Nome da unidade é obrigatório.')
        .min(3, 'Nome deve conter mais de 3 caracteres.'),
      consumer_unit: Yup.string().required(
        'Unidade consumidora é obrigatório.'
      ),
      cnpj_unit: Yup.string()
        .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'CNPJ inválido.')
        .required('CNPJ é obrigatório.'),
      peakdemandcontract: Yup.number(),
      offpeakdemandcontract: Yup.number().required(
        'Demanda Fora de Ponta é obrigatória.'
      ),
      city: Yup.string().required('Cidade da unidade é obrigatório.'),
      state: Yup.string().required('Estado da unidade é obrigatório.'),
      user_id: Yup.number().integer().required('Gestor técnico é obrigatório.'),

      distributor_id: Yup.number()
        .integer()
        .required('Distribuidora é obrigatório.'),
      supplier_id: Yup.number().integer(),
      voltageclass_id: Yup.number()
        .integer()
        .required('Classe de tensão é obrigatório.'),
      tariffmodality_id: Yup.number()
        .integer()
        .required('Modalidade tarifária é obrigatório.'),
      hiringenvironment_id: Yup.number()
        .integer()
        .required('Ambiente de contratação é obrigatório.')
        .test(
          'is-regulated-environment',
          'O ambiente de contratação deve ser Regulado',
          function (value) {
            const { tariffmodality_id } = this.parent
            return tariffmodality_id === 3 ? value === 2 : true
          }
        ),
      validity: Yup.boolean()
    }),

    onSubmit: (data) => {
      mutation.mutate(data)
    }
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isConventional = values.tariffmodality_id === 3
  const isGreen = values.tariffmodality_id === 2
  const isRegulated = values.hiringenvironment_id === 2

  const handleFocus = (e) => {
    if (e.target.value === 0) {
      setFieldValue(e.target.name, '')
    }
  }

  const handleBlur = (e) => {
    if (e.target.value === '') {
      setFieldValue(e.target.name, 0)
    }
  }

  return (
    <Flex>
      <Button.add
        justifyContent="space-between"
        rightIcon={
          <Image
            src="/img/IconAddEmpresa.svg"
            alt="IconAddEmpresa"
            w="22px"
            h="22px"
          />
        }
        onClick={onOpen}
      >
        Adicionar
      </Button.add>

      <Modal isOpen={isOpen} onClose={onClose} size={2}>
        <ModalOverlay />
        <ModalContent
          flexDir="column"
          w={['300px', '500px', '700px', '880px']}
          justifyContent="center"
          borderRadius="8px"
          shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
        >
          <ModalHeader
            display="flex"
            flexDir="row"
            alignItems="flex-end"
            color="#F2920A"
          >
            <Image
              mr="12px"
              src="/img/IconAddEmpresaO.svg"
              alt="IconAddEmpresa"
            />
            Cadastro Unidade
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
            >
              <FormControl w={['100%', '100%', '100%', '470px']}>
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Nome da Unidade
                </FormLabel>
                <InputModal
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                />
              </FormControl>
              <FormControl
                w={['100%', '100%', '100%', '326px']}
                mt={[4, 4, 4, 0]}
              >
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Unidade Consumidora
                </FormLabel>
                <InputModal
                  id="consumer_unit"
                  name="consumer_unit"
                  value={values.consumer_unit}
                  onChange={handleChange}
                  error={errors.consumer_unit}
                />
              </FormControl>
            </Flex>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
            >
              <FormControl w={['100%', '100%', '100%', '296px']} mt={4}>
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  CNPJ da Unidade
                </FormLabel>
                <InputModal.CNPJ
                  id="cnpj_unit"
                  name="cnpj_unit"
                  value={values.cnpj_unit}
                  onChange={handleChange}
                  error={errors.cnpj_unit}
                />
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '340px']} mt={4}>
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Cidade
                </FormLabel>
                <InputModal
                  id="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  error={errors.city}
                />
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '124px']} mt={4}>
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Estado
                </FormLabel>
                <ListSelect
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  error={errors.state}
                  data={statesBR}
                />
              </FormControl>
            </Flex>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
            >
              <FormControl w={['100%', '100%', '100%', '182px']} mt={4}>
                <FormLabel
                  display="flex"
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Distribuidora
                </FormLabel>
                <ListSelect
                  id="distributor_id"
                  name="distributor_id"
                  value={values.distributor_id}
                  onChange={handleChange}
                  error={errors.distributor_id}
                  data={distributorData}
                />
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '182px']} mt={4}>
                <FormLabel
                  display="flex"
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Classe de Tensão
                </FormLabel>
                <ListSelect
                  id="voltageclass_id"
                  name="voltageclass_id"
                  value={values.voltageclass_id}
                  onChange={handleChange}
                  error={errors.voltageclass_id}
                  data={voltageclassData}
                />
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '182px']} mt={4}>
                <FormLabel
                  display="flex"
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Modalidade Tarifária
                </FormLabel>
                <ListSelect
                  id="tariffmodality_id"
                  name="tariffmodality_id"
                  value={values.tariffmodality_id}
                  onChange={handleChange}
                  error={errors.tariffmodality_id}
                  data={tariffmodalityData}
                />
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '182px']} mt={4}>
                <FormLabel
                  display="flex"
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Ambiente de Contratação
                </FormLabel>
                <ListSelect
                  id="hiringenvironment_id"
                  name="hiringenvironment_id"
                  value={values.hiringenvironment_id}
                  onChange={handleChange}
                  error={errors.hiringenvironment_id}
                  data={hiringenvironmentData}
                />
              </FormControl>
            </Flex>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
            >
              <Flex
                w={['100%', '100%', '100%', '38%']}
                flexDir={['column', 'column', 'column', 'row']}
                justifyContent="space-between"
              >
                {!isConventional && (
                  <>
                    <FormControl w={['100%', '100%', '100%', '136px']} mt={4}>
                      <FormLabel
                        display="flex"
                        textAlign={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center'
                        ]}
                        mb="0px"
                        fontSize="12px"
                        fontWeight="semibold"
                        color="#303030"
                      >
                        Demanda Contratada F.P.
                      </FormLabel>
                      <InputModal
                        id="offpeakdemandcontract"
                        name="offpeakdemandcontract"
                        value={values.offpeakdemandcontract}
                        onChange={handleChange}
                        error={errors.offpeakdemandcontract}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder="0 kW"
                        type="number"
                        textAlign="center"
                      />
                    </FormControl>
                    <FormControl w={['100%', '100%', '100%', '136px']} mt={4}>
                      {!isGreen && (
                        <>
                          <FormLabel
                            display="flex"
                            textAlign={[
                              'flex-start',
                              'flex-start',
                              'flex-start',
                              'center'
                            ]}
                            mb="0px"
                            fontSize="12px"
                            fontWeight="semibold"
                            color="#303030"
                          >
                            Demanda Contratada P.
                          </FormLabel>
                          <InputModal
                            id="peakdemandcontract"
                            name="peakdemandcontract"
                            value={values.peakdemandcontract}
                            onChange={handleChange}
                            error={errors.peakdemandcontract}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            placeholder="0 kW"
                            type="number"
                            textAlign="center"
                          />
                        </>
                      )}
                    </FormControl>
                  </>
                )}
              </Flex>
              {!isRegulated && (
                <Flex
                  w={['100%', '100%', '100%', '62%']}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  mt={4}
                >
                  <FormControl w={['100%', '100%', '100%', '188px']} mt={4}>
                    <FormLabel
                      display="flex"
                      justifyContent={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'center'
                      ]}
                      mb="0px"
                      fontSize="12px"
                      fontWeight="semibold"
                      color="#303030"
                    >
                      Comercializadora
                    </FormLabel>
                    <ListSelect
                      id="supplier_id"
                      name="supplier_id"
                      value={values.supplier_id}
                      onChange={handleChange}
                      error={errors.supplier_id}
                      data={supplierData}
                    />
                  </FormControl>
                </Flex>
              )}
            </Flex>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="flex-start"
            >
              <Flex
                w={['100%', '100%', '100%', '62%']}
                alignItems="flex-end"
                justifyContent="flex-start"
                mt={4}
              >
                <FormControl w={['100%', '100%', '100%', '188px']} mt={4}>
                  <FormLabel
                    display="flex"
                    justifyContent={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Gestor Técnico
                  </FormLabel>
                  <ListSelect
                    id="user_id"
                    name="user_id"
                    value={values.user_id}
                    onChange={handleChange}
                    error={errors.user_id}
                    data={userData}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={mutation.isLoading}
              onClick={handleSubmit}
              bg="#F2920A"
              color="#ffffff"
              h="34px"
              mr={3}
              _hover={{
                color: 'brand.background_home',
                bg: 'brand.background'
              }}
            >
              Adicionar
            </Button>
            <Button h="34px" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
