import { Flex, Image } from '@chakra-ui/react'
import { Text, Alert } from 'Components'

export const HeaderAnalysis = () => {
  return (
    <Flex
      display="flex"
      as="header"
      w={['0px', '0px', '58%', '72%']}
      h={['0px', '0px', '48px', '58px']}
      bg="brand.primary"
      borderRadius="10px 0px 0px 10px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      position="absolute"
      top="24px"
      right="0"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex ml="14px" alignItems="center">
        <Image
          src="/img/IconAnaliseW.svg"
          alt="IconClientes"
          w={['0px', '0px', '34px', '34px']}
          h={['0px', '0px', '34px', '34px']}
        />
        <Text.Header>Análise</Text.Header>
      </Flex>

      <Alert />
    </Flex>
  )
}
