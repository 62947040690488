import { Flex } from '@chakra-ui/react'
import { FaCircleCheck, FaCircleExclamation } from 'react-icons/fa6'
import { FaPaperPlane } from 'react-icons/fa'

export const StatusBadge = ({ status }) => {
  // Mapeia o status para o texto e a cor do Badge
  const statusMap = {
    Completed: { text: 'Concluída', color: '#99D44D', icon: FaCircleCheck },
    Divergent: {
      text: 'Divergente',
      color: '#D95D5D',
      icon: FaCircleExclamation
    },
    Sent: { text: 'Enviado', color: '#4E89FC', icon: FaPaperPlane }
  }

  // Define o status padrão caso o status não seja encontrado no mapa
  const { color, icon: Icon } = statusMap[status] || {
    text: 'Desconhecido',
    color: 'gray',
    icon: FaCircleExclamation // Ícone padrão para status desconhecido
  }

  return (
    <Flex flexDir="row" alignItems="center">
      <Icon size={28} color={color} />
    </Flex>
  )
}
