import React from 'react'
import { Flex, HStack, IconButton, Image } from '@chakra-ui/react'
import { HeaderHomeTec, Menu, CardPerfil, InfoHomeTec } from 'Components'

export const HomeTec = () => {
  const [collapse, setCollapse] = React.useState(false)

  const toggleCollapse = () => {
    setCollapse(!collapse)
  }

  React.useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth >= 1200) {
        setCollapse(false)
      } else {
        setCollapse(true)
      }
    }

    checkScreenSize()

    window.addEventListener('resize', checkScreenSize)

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  return (
    <HStack w="full" h="100vh" bg="brand.background_home" padding="8px">
      <Flex
        as="aside"
        w="full"
        h="full"
        maxW={collapse ? '60px' : '250px'}
        transition="0.6s"
        bg="brand.background_home"
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between"
        borderRadius="8px"
        overflowX="hidden"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'white',
            border: '1px solid gray'
          }
        }}
      >
        <Menu collapse={collapse} />
      </Flex>
      <Flex
        as="main"
        w="full"
        h="full"
        bg="brand.background"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        position="relative"
        borderRadius="8px"
      >
        <Flex>
          <IconButton
            bg="none"
            aria-label="Menu Collapse"
            onClick={toggleCollapse}
            transform={collapse ? 'rotate(-180deg)' : 'none'}
            transition="0.6s"
            position="absolute"
            top={2}
            left={2}
            icon={
              <Image
                src="./img/IconArrow.svg"
                alt="IconArrow"
                w="34px"
                h="34px"
              />
            }
            _hover={{
              bg: 'none'
            }}
          />
        </Flex>
        <CardPerfil />
        <InfoHomeTec />
      </Flex>
      <HeaderHomeTec />
    </HStack>
  )
}
