import { Select as ChakraSelect, Image, Box } from '@chakra-ui/react'
import { Text } from 'Components/atoms/Text'

export const Select = (props) => (
  <>
    <ChakraSelect
      display="flex"
      alignItems="center"
      textAlign="center"
      padding="0px"
      w="322px"
      h="38px"
      transition="width 0.6s"
      fontSize="14px"
      fontWeight="medium"
      placeholder="Selecione seu perfil"
      focusBorderColor="brand.primary"
      backgroundColor="brand.background"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.3)"
      _hover={{ cursor: 'pointer', BorderColor: 'brand.primary' }}
      {...props}
    />
    {props.error && (
      <Text fontSize="12px" color="red">
        {props.error}
      </Text>
    )}
  </>
)

Select.unauth = ({ ...props }) => {
  return (
    <>
      <ChakraSelect
        display="flex"
        alignItems="center"
        textAlign="center"
        padding="0px"
        w="322px"
        h="38px"
        fontSize="14px"
        fontWeight="medium"
        placeholder="Selecione seu perfil"
        focusBorderColor="brand.primary"
        backgroundColor="brand.background"
        borderRadius="8px"
        shadow="2px 2px 10px rgba(0, 0, 0, 0.3)"
        _hover={{ cursor: 'pointer', BorderColor: 'brand.primary' }}
        {...props}
      />
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

Select.unauth.displayName = 'SelectUnauth'

Select.area = ({ collapse, ...props }) => {
  return (
    <Box w={collapse ? '55px' : '221px'} transition="width 0.6s">
      <ChakraSelect
        w="100%"
        h="34px"
        fontSize="14px"
        fontWeight="600"
        color={collapse ? 'brand.primary' : 'brand.background_home'}
        backgroundColor="brand.primary"
        border="1px solid brand.primary"
        borderRadius="8px"
        shadow="2px 2px 10px rgba(0, 0, 0, 0.3)"
        _hover={{ cursor: 'pointer' }}
        icon={
          <Image src="/img/IconUser.svg" alt="IconArrow" w="34px" h="34px" />
        }
        focusBorderColor="brand.primary"
        {...props}
      />
    </Box>
  )
}

Select.area.displayName = 'SelectArea'

Select.modal = ({ ...props }) => {
  return (
    <>
      <ChakraSelect
        alignItems="center"
        textAlign="center"
        padding="0px"
        h="36px"
        fontSize="14px"
        focusBorderColor="brand.primary"
        backgroundColor="brand.background"
        _hover={{ cursor: 'pointer', BorderColor: 'brand.primary' }}
        {...props}
      />
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

Select.modal.displayName = 'SelectModal'
