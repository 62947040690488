import { Tbody } from '@chakra-ui/react'
import { Tariff } from 'Components'

export const ListTariff = ({
  data,

  onDelete
}) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <Tariff key={`tariff_${item.id}`} {...item} onDelete={onDelete} />
        ))}
    </Tbody>
  )
}
