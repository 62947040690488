import { Tbody } from '@chakra-ui/react'
import { Analysis } from 'Components'

export const ListAnalysis = ({ data, onDelete }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <Analysis key={`analysis_${item.id}`} {...item} onDelete={onDelete} />
        ))}
    </Tbody>
  )
}
