import React from 'react'

import {
  Table,
  Thead,
  Tr,
  Th,
  TableContainer,
  Flex,
  useToast
} from '@chakra-ui/react'
import { getTaxByDistributor, deleteTax } from 'services/api/requests'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { ListTax, Loader, EmptyMessage } from 'Components'

export const TableTax = () => {
  const { distributor_id } = useParams()
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    ['tax', distributor_id],
    () => getTaxByDistributor(distributor_id),
    {
      enabled: !!distributor_id,
      retry: 1,
      retryDelay: 1000
    }
  )

  const deleteTaxMutation = useMutation((data) => deleteTax(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao excluir imposto.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: () => {
      toast({
        title: 'Imposto excluído com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      queryClient.removeQueries(['tax'])
    }
  })
  const handleDelete = (data) => {
    deleteTaxMutation.mutate(data)
  }

  if (isLoading) {
    return <Loader />
  }

  if (!data || data?.length === 0) {
    return <EmptyMessage>Nenhum imposto encontrado.</EmptyMessage>
  }

  return (
    <Flex
      bg="#ffffff"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="75%"
      mb="16px"
    >
      <TableContainer
        w="100%"
        justifyContent="space-between"
        overflowX="auto"
        overflowY="auto"
        borderRadius="8px"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'null',
            border: 'null'
          }
        }}
      >
        <Table mb="8px" mt="8px" size="sm">
          <Thead position="sticky" top="0" zIndex="docked" bg="#ffffff">
            <Tr height="34px">
              <Th fontSize="12px">Mês</Th>
              <Th fontSize="12px">ICMS</Th>
              <Th fontSize="12px">Pis</Th>
              <Th fontSize="12px">Cofins</Th>
              <Th fontSize="12px">Início Vigência</Th>
              <Th fontSize="12px">Fim Vigência</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <ListTax onDelete={handleDelete} data={data?.data} />
        </Table>
      </TableContainer>
    </Flex>
  )
}
