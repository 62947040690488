import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaFileDownload } from 'react-icons/fa'
import { BsSendFill } from 'react-icons/bs'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
  useToast
} from '@chakra-ui/react'
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { generatePdf, updateStatus } from 'services/api/requests'
import { useMutation, useQueryClient } from 'react-query'

export const MenuMoreAnalysis = ({ id, setShowModalDelete, status }) => {
  const navigate = useNavigate()
  const buttonRef = React.useRef()
  const queryClient = useQueryClient()

  const [isSent, setIsSent] = React.useState(status === 'Sent')
  const toast = useToast()
  const mutation = useMutation(updateStatus, {
    onError: (error) => {
      toast({
        title: 'Falha ao atualizar status da análise.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: () => {
      toast({
        title: 'Análise enviada !',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
      setIsSent(true)
      queryClient.invalidateQueries(['analysis'])
    }
  })

  const handleSend = () => {
    if (!isSent) {
      mutation.mutate(id)
    }
  }
  const handleGenerateReport = async () => {
    try {
      await generatePdf(id)
    } catch (error) {
      alert('Erro ao gerar o relatório: ' + error.message)
    }
  }
  return (
    <Menu initialFocusRef={buttonRef}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Image w="22px" h="22px" src="/img/IconMore.svg" />}
        variant="null"
        ref={buttonRef}
      />
      <MenuList>
        <MenuItem icon={<FaFileDownload />} onClick={handleGenerateReport}>
          Baixar Relatório
        </MenuItem>
        <MenuItem
          icon={<EditIcon />}
          onClick={() => navigate(`/analysis/edit/${id}`)}
        >
          Editar
        </MenuItem>
        <MenuItem
          icon={<DeleteIcon />}
          onClick={() => setShowModalDelete('analysis')}
        >
          Excluir
        </MenuItem>
        <MenuItem
          icon={<BsSendFill />}
          onClick={handleSend}
          isDisabled={isSent}
        >
          Enviado
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
