import {
  FormControl,
  FormLabel,
  Flex,
  Checkbox,
  useToast,
  Text,
  Button,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import {
  InputModal,
  ListSelect,
  statesBR,
  Loader,
  EmptyMessage
} from 'Components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getDistributor,
  getSupplier,
  getVoltageClass,
  getTariffModality,
  getHiringEnvironment,
  getUnitById,
  getUser,
  updateUnit
} from 'services/api/requests'

export const UnitDetails = ({ id }) => {
  const toast = useToast()

  const queryClient = useQueryClient()

  const { data: unit, isLoading } = useQuery(
    ['unit', id],
    () => getUnitById(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )

  const unitData = unit?.data

  const { data: distributor } = useQuery('distributor', getDistributor)
  const distributorData = distributor?.data || []

  const { data: supplier } = useQuery('supplier', getSupplier)
  const supplierData = supplier?.data || []

  const { data: voltageclass } = useQuery('voltageclass', getVoltageClass)
  const voltageclassData = voltageclass?.data || []

  const { data: tariffmodality } = useQuery('tariffmodality', getTariffModality)
  const tariffmodalityData = tariffmodality?.data || []

  const { data: hiringenvironment } = useQuery(
    'hiringenvironment',
    getHiringEnvironment
  )
  const hiringenvironmentData = hiringenvironment?.data || []

  const { data: user } = useQuery('user', getUser)
  const userData = user?.data || []

  const mutation = useMutation((data) => updateUnit(id, data), {
    onError: (error) => {
      toast({
        title: 'Falha ao atualizar dados da unidade.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Dados atualizados com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      queryClient.invalidateQueries(['unit'])
    }
  })

  const { handleSubmit, values, handleChange, errors, dirty } = useFormik({
    initialValues: {
      name: unit?.data?.name || '',
      consumer_unit: unit?.data?.consumer_unit || '',
      cnpj_unit: unit?.data?.cnpj_unit || '',
      peakdemandcontract: unit?.data?.peakdemandcontract || '',
      offpeakdemandcontract: unit?.data?.offpeakdemandcontract || '',
      city: unit?.data?.city || '',
      state: unit?.data?.state || '',
      company_id: unit?.data?.company_id,
      user_id: unit?.data?.user_id || '',
      distributor_id: unit?.data?.distributor_id || '',
      supplier_id: unit?.data?.supplier_id || 0,
      voltageclass_id: unit?.data?.voltageclass_id || '',
      tariffmodality_id: unit?.data?.tariffmodality_id || '',
      hiringenvironment_id: unit?.data?.hiringenvironment_id || '',
      validity: unit?.data?.validity || false
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().min(3, 'Nome deve conter mais de 3 caracteres.'),
      consumer_unit: Yup.string(),
      cnpj_unit: Yup.string().matches(
        /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
        'CNPJ inválido.'
      ),
      peakdemandcontract: Yup.number(),
      offpeakdemandcontract: Yup.number(),
      city: Yup.string(),
      state: Yup.string(),
      user_id: Yup.number().integer(),
      distributor_id: Yup.number().integer(),
      supplier_id: Yup.number().integer(),
      voltageclass_id: Yup.number().integer(),
      tariffmodality_id: Yup.number().integer(),
      hiringenvironment_id: Yup.number()
        .integer()
        .test(
          'is-regulated-environment',
          'O ambiente de contratação deve ser Regulado',
          function (value) {
            const { tariffmodality_id } = this.parent
            return tariffmodality_id === 3 ? value === 2 : true
          }
        ),
      validity: Yup.boolean()
    }),

    onSubmit: (data) => {
      mutation.mutate(data)
    }
  })

  const isConventional = values.tariffmodality_id === 3
  const isGreen = values.tariffmodality_id === 2
  const isRegulated = values.hiringenvironment_id === 2

  if (isLoading) {
    return <Loader />
  }
  if (!unitData || unitData.length === 0) {
    return <EmptyMessage>Erro ao buscar detalhes da unidade.</EmptyMessage>
  }
  return (
    <Flex
      backgroundColor="white"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="75%"
      mb="16px"
      padding={6}
      flexDir={['column', 'column', 'column', 'column']}
      overflowX="hidden"
      overflowY="auto"
      css={{
        '::-webkit-scrollbar': {
          width: '4px',
          height: '4px'
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          background: 'gray'
        },
        '::-webkit-scrollbar-track': {
          borderRadius: '4px',
          background: 'white',
          border: '1px solid gray'
        }
      }}
    >
      {' '}
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
      >
        <FormControl w={['100%', '100%', '100%', '35%']} isReadOnly>
          <FormLabel
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Razão Social
          </FormLabel>
          <InputModal
            id="name_company"
            name="name_company"
            value={unitData?.company?.name || ''}
          />
        </FormControl>
        <FormControl
          w={['100%', '100%', '100%', '20%']}
          mt={[4, 4, 4, 0]}
          isReadOnly
        >
          <FormLabel
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            CNPJ Matriz
          </FormLabel>
          <InputModal.CNPJ
            id="cnpj_company"
            name="cnpj_company"
            value={unitData?.company?.cnpj_company || ''}
          />
        </FormControl>
        <FormControl
          w={['100%', '100%', '100%', '20%']}
          mt={[4, 4, 4, 0]}
          isReadOnly
        >
          <FormLabel
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            E-mail Comercial
          </FormLabel>
          <InputModal
            id="commercial_email"
            name="commercial_email"
            value={unitData?.company?.commercial_email || ''}
          />
        </FormControl>
        <FormControl
          w={['100%', '100%', '100%', '20%']}
          mt={[4, 4, 4, 0]}
          isReadOnly
        >
          <FormLabel
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Telefone Comercial
          </FormLabel>
          <InputModal.Number
            id="commercial_phone"
            name="commercial_phone"
            value={unitData?.company?.commercial_phone || ''}
            type="phone"
          />
        </FormControl>
      </Flex>
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Dados da Unidade
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '40%']}>
          <FormLabel
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Nome da Unidade
          </FormLabel>
          <InputModal
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '24%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Unidade Consumidora
          </FormLabel>
          <InputModal
            id="consumer_unit"
            name="consumer_unit"
            value={values.consumer_unit}
            onChange={handleChange}
            error={errors.consumer_unit}
          />
        </FormControl>

        <FormControl w={['100%', '100%', '100%', '28%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            CNPJ da Unidade
          </FormLabel>
          <InputModal.CNPJ
            id="cnpj_unit"
            name="cnpj_unit"
            value={values.cnpj_unit}
            onChange={handleChange}
            error={errors.cnpj_unit}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={[4, 4, 4, 8]}
      >
        <FormControl w={['100%', '100%', '100%', '40%']}>
          <FormLabel
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Cidade
          </FormLabel>
          <InputModal
            id="city"
            name="city"
            value={values.city}
            onChange={handleChange}
            error={errors.city}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '10%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Estado
          </FormLabel>
          <ListSelect
            id="state"
            name="state"
            value={values.state}
            onChange={handleChange}
            error={errors.state}
            data={statesBR}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          {!isConventional && (
            <>
              <FormLabel
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Contratada F.P.
              </FormLabel>
              <InputModal
                id="offpeakdemandcontract"
                name="offpeakdemandcontract"
                value={values.offpeakdemandcontract}
                onChange={handleChange}
                error={errors.offpeakdemandcontract}
                placeholder="0"
                type="number"
                textAlign="center"
              />
            </>
          )}
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          {!isConventional && !isGreen && (
            <>
              <FormLabel
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Contratada P.
              </FormLabel>
              <InputModal
                id="peakdemandcontract"
                name="peakdemandcontract"
                value={values.peakdemandcontract}
                onChange={handleChange}
                error={errors.peakdemandcontract}
                placeholder="0"
                type="number"
                textAlign="center"
              />
            </>
          )}
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        mt={[4, 4, 4, 8]}
      >
        <FormControl w={['100%', '100%', '100%', '18%']}>
          <FormLabel
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Distribuidora
          </FormLabel>
          <ListSelect
            id="distributor_id"
            name="distributor_id"
            value={values.distributor_id}
            onChange={handleChange}
            error={errors.distributor_id}
            data={distributorData}
          />
        </FormControl>
        {!isRegulated && (
          <FormControl w={['100%', '100%', '100%', '18%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              display="flex"
              justifyContent={[
                'flex-start',
                'flex-start',
                'flex-start',
                'center'
              ]}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Comercializadora
            </FormLabel>
            <ListSelect
              id="supplier_id"
              name="supplier_id"
              value={values.supplier_id}
              onChange={handleChange}
              error={errors.supplier_id}
              data={supplierData}
            />
          </FormControl>
        )}
        <FormControl w={['100%', '100%', '100%', '18%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Classe de Tensão
          </FormLabel>
          <ListSelect
            id="voltageclass_id"
            name="voltageclass_id"
            value={values.voltageclass_id}
            onChange={handleChange}
            error={errors.voltageclass_id}
            data={voltageclassData}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '18%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Modalidade Tarifária
          </FormLabel>
          <ListSelect
            id="tariffmodality_id"
            name="tariffmodality_id"
            value={values.tariffmodality_id}
            onChange={handleChange}
            error={errors.tariffmodality_id}
            data={tariffmodalityData}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '18%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Ambiente de Contratação
          </FormLabel>
          <ListSelect
            id="hiringenvironment_id"
            name="hiringenvironment_id"
            value={values.hiringenvironment_id}
            onChange={handleChange}
            error={errors.hiringenvironment_id}
            data={hiringenvironmentData}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row-reverse']}
        justifyContent="space-between"
        alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
        mt={[4, 4, 4, 12]}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Gestor Técnico
          </FormLabel>
          <ListSelect
            id="user_id"
            name="user_id"
            value={values.user_id}
            onChange={handleChange}
            error={errors.user_id}
            data={userData}
          />
        </FormControl>
        <Checkbox
          id="validity"
          name="validity"
          isChecked={values.validity}
          onChange={handleChange}
          error={errors.validity}
          mb="0px"
          fontWeight="semibold"
          color="#303030"
          alignItems="center"
          colorScheme="green"
          mt={6}
        >
          <Flex flexDir="row">
            <Text fontSize="14px">Status da Unidade</Text>
            <Text fontSize="14px" ml="6px" fontWeight="normal">
              {values.validity ? '(Ativada)' : '(Desativada)'}
            </Text>
          </Flex>
        </Checkbox>
      </Flex>
      <Flex
        justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
        mt={[4, 4, 4, 10]}
      >
        <Button
          mt={[6, 6, 6, 0]}
          isLoading={mutation.isLoading}
          onClick={handleSubmit}
          bg="#F2920A"
          color="#ffffff"
          h="34px"
          _hover={{
            color: 'brand.background_home',
            bg: 'brand.background'
          }}
          isDisabled={!dirty}
        >
          Salvar Alterações
        </Button>
      </Flex>
    </Flex>
  )
}
