import { api } from 'services/api'

export const registerUnit = (id, data) =>
  api.post(`/unit/company/${id}`, data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getUnit = () =>
  api.get('/unit', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const deleteUnit = (id) =>
  api.delete(`/unit/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const updateUnit = (id, data) =>
  api.put(`/unit/${id}`, data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getUnitByCompany = (id) =>
  api.get(`/unit/company/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getCountUnit = (id) =>
  api.get(`/unit/count/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getUnitById = (id) =>
  api.get(`/unit/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getCountMonomia = () =>
  api.get(`/count/monomias`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getCountCativo = () =>
  api.get(`/count/cativo`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getCountML = () =>
  api.get(`/count/mercadolivre`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getUnitByConsumer = (consumer_unit) =>
  api.get(`/unit/consumer/${consumer_unit}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })
