import { Flex, Image } from '@chakra-ui/react'
import { Text, Alert } from 'Components'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getUnitById } from 'services/api/requests'

export const HeaderUnit = ({ id }) => {
  const navigate = useNavigate()

  const { data: unit } = useQuery(['unit', id], () => getUnitById(id), {
    enabled: !!id
  })

  const unitData = unit?.data

  return (
    <Flex
      display="flex"
      as="header"
      w={['0px', '0px', '58%', '72%']}
      h={['0px', '0px', '48px', '58px']}
      bg="brand.primary"
      borderRadius="10px 0px 0px 10px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      position="absolute"
      top="24px"
      right="0"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex ml="14px" alignItems="center">
        <Image
          src="/img/IconClientesW.svg"
          alt="IconClientes"
          w={['0px', '0px', '34px', '34px']}
          h={['0px', '0px', '34px', '34px']}
          cursor="pointer"
          onClick={() => {
            navigate('/clienttec')
          }}
        />
        <Text.Header
          cursor="pointer"
          onClick={() => {
            navigate(`/clienttec/company/${unitData?.company?.id}`)
          }}
        >
          {unitData?.company?.name}
        </Text.Header>
      </Flex>

      <Alert />
    </Flex>
  )
}
