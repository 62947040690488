import { api } from 'services/api'

export const registerHiringEnvironment = (data) =>
  api.post('/hiringenvironment', data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getHiringEnvironment = () =>
  api.get('/hiringenvironment', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getHiringEnvironmentByUnit = (id) =>
  api.get(`/hiringenvironment/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const deleteHiringEnvironment = (id) =>
  api.delete(`/hiringenvironment/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })
