import { Flex, Image, FormControl, useToast } from '@chakra-ui/react'
import { Text, Button, Link, InputUnauth } from 'Components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from 'react-query'
import { resetPasswordCall } from 'services/api/requests'

export const ResetPasswordScreen = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [searchParams] = useSearchParams()

  const mutation = useMutation((data) => resetPasswordCall(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao recuperar senha.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Senha salva com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      navigate('/')
    }
  })

  const { handleSubmit, values, handleChange, errors } = useFormik({
    initialValues: {
      token: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      token: Yup.string()
        .length(6, 'Token deve conter 6 caracteres')
        .required('Token é obrigatório.'),
      password: Yup.string()
        .min(6, 'Senha deve conter ao menos 6 caracteres.')
        .required('Senha é obrigatória.'),
      confirmPassword: Yup.string()
        .min(6, 'Senha deve conter ao menos 6 caracteres.')
        .required('Senha é obrigatória.')
        .oneOf([Yup.ref('password'), null], 'Senhas não são iguais.')
    }),
    onSubmit: (data) => {
      mutation.mutate({
        email: searchParams.get('email'),
        token: data.token,
        password: data.password
      })
    }
  })

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Flex
      flexDir="row"
      w="100vw"
      h="100vh"
      backgroundImage="url('/img/Background-login.svg')"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      opacity="0.85"
    >
      <Flex
        flexDir="column"
        w={['0%', '0%', '0%', '50%']}
        h={['0%', '0%', '0%', '100vh']}
      >
        <Image src="/img/Logo.svg" alt="Solfus Logo" w="206px" h="112px" />
        <Flex
          flexDir="row"
          alignItems="center"
          w={['0%', '0%', '0%', '100%']}
          h={['0%', '0%', '0%', '100%']}
          ml="20px"
          fontFamily="Mallanna"
          textShadow="0.2em 0.2em 0.8em black"
        >
          <Flex
            flexDir="column"
            w="10%"
            h="100%"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Image
              src="/img/Icon+.svg"
              alt="Icon Site"
              w="60px"
              h="60px"
              mt="70px"
            />
            <Image
              src="/img/Icon+.svg"
              alt="Icon Site"
              w="60px"
              h="60px"
              mt="110px"
            />
          </Flex>
          <Flex
            flexDir="column"
            w={['0%', '0%', '0%', '20%']}
            h={['0%', '0%', '0%', '100%']}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Text
              ml="30px"
              mt="70px"
              color="#ffffff"
              fontSize={['0px', '0px', '0px', '70px']}
            >
              35
            </Text>
            <Text
              ml="30px"
              mt="60px"
              color="#ffffff"
              fontSize={['0px', '0px', '0px', '70px']}
            >
              700
            </Text>
          </Flex>
          <Flex
            flexDir="column"
            w={['0%', '0%', '0%', '70%']}
            h={['0%', '0%', '0%', '100%']}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Flex flexDir="column">
              <Text
                mt="82px"
                color="#ffffff"
                fontSize={['0px', '0px', '0px', '50px']}
              >
                Anos
              </Text>
              <Flex ml="60px">
                <Text color="#ffffff" fontSize={['0px', '0px', '0px', '26px']}>
                  no mercado
                </Text>
              </Flex>
            </Flex>
            <Flex flexDir="column" ml="40px">
              <Text
                mt="50px"
                color="#ffffff"
                fontSize={['0px', '0px', '0px', '50px']}
              >
                Empresas
              </Text>
              <Flex ml="60px">
                <Text color="#ffffff" fontSize={['0px', '0px', '0px', '26px']}>
                  atendidas pelo Brasil
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDir="row"
          justifyContent="space-between"
          alignItems="flex-end"
          w={['100%', '100%', '100%', '20%']}
          h="100%"
          ml={['0px', '0px', '0px', '20px']}
          mb="20px"
        >
          <Link href="https://solfus.com.br/" target="_blank">
            <Image src="/img/IconSite.svg" alt="Icon Site" w="30px" h="30px" />
          </Link>
          <Link
            href="https://www.instagram.com/solfusengenharia/"
            target="_blank"
          >
            <Image
              src="/img/IconInstagram.svg"
              alt="Icon Instagram"
              w="30px"
              h="30px"
            />
          </Link>
          <Link href="https://br.linkedin.com/company/solfus" target="_blank">
            <Image
              src="/img/IconLinkedin.svg"
              alt="Icon Linkedin"
              w="30px"
              h="30px"
            />
          </Link>
        </Flex>
      </Flex>
      <Flex
        flexDir="column"
        w={['100%', '100%', '100%', '50%']}
        h="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          backgroundColor="rgba(234, 235, 240, 0.7)"
          shadow="0px 0px 50px black"
          w={['360px', '400px', '440px', '360px']}
          h="500px"
          borderRadius="12px"
          flexDir="column"
          display="flex"
          alignItems="center"
        >
          <Image
            src="/img/Logo-small.svg"
            alt="Solfus LogoSmall"
            w="31px"
            h="32px"
            mt="20px"
          />
          <Text.ScreenTitle>Recuperar Senha</Text.ScreenTitle>

          <Flex mt="8px">
            <FormControl>
              <Text.Label>Token</Text.Label>
              <InputUnauth.Token
                id="token"
                name="token"
                value={values.token}
                onChange={handleChange}
                error={errors.token}
                onKeyDown={handleKeyDown}
              />

              <Text.Label>Nova senha</Text.Label>
              <InputUnauth.Password
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                error={errors.password}
                onKeyDown={handleKeyDown}
              />
              <Text.Label>Confirmar senha</Text.Label>
              <InputUnauth.Password
                id="confirmPassword"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                error={errors.confirmPassword}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          </Flex>

          <Button.unauth isLoading={mutation.isLoading} onClick={handleSubmit}>
            Salvar
          </Button.unauth>

          <Link.Login onClick={() => navigate('/recover')}>
            Reenviar Token
          </Link.Login>
        </Flex>
      </Flex>
    </Flex>
  )
}
