import React from 'react'
import { Td, Tr } from '@chakra-ui/react'
import {
  MenuMoreHiringEnvironment,
  ModalDeleteHiringEnvironment
} from 'Components'

export const HiringEnvironment = ({ id, name, onDelete }) => {
  const [showModalDelete, setShowModalDelete] = React.useState()

  const onCloseModalDelete = () => {
    setShowModalDelete(null)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  return (
    <Tr>
      <Td>{name}</Td>
      <Td display="flex" flexDir="row" justifyContent="flex-end">
        <MenuMoreHiringEnvironment setShowModalDelete={setShowModalDelete} />

        {showModalDelete === 'hiringenvironment' && (
          <ModalDeleteHiringEnvironment
            onDelete={handleDelete}
            onClose={onCloseModalDelete}
          />
        )}
      </Td>
    </Tr>
  )
}
