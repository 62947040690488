import { api } from 'services/api'

export const registerAnalysis = (data) =>
  api.post(`/analysis`, data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getAnalysis = () =>
  api.get('/analysis', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const deleteAnalysis = (id) =>
  api.delete(`/analysis/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const updateAnalysis = (id, data) =>
  api.put(`/analysis/${id}`, data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getAnalysisById = (id) =>
  api.get(`/analysis/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

// Used in the Analysis
export const getTariffByAnalysisId = (id) =>
  api.get(`/analysis/tariff/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getFlagByAnalysisId = (id) =>
  api.get(`/analysis/flag/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getTaxByAnalysisId = (id) =>
  api.get(`/analysis/tax/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getFreeMarketSavingsByAnalysisId = (id) =>
  api.get(`/analysis/freemarketsavings/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const updateStatus = (id) =>
  api.put(
    `/analysis/status/${id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
      }
    }
  )

export const generatePdf = async (id) => {
  try {
    const response = await api.get(`/reports/analysis/${id}`, {
      responseType: 'blob', // Configura a resposta para ser um Blob (arquivo)
      headers: {
        Authorization: `bearer ${localStorage.getItem('@solfus_token')}`,
        Accept: 'application/pdf' // Solicita um PDF
      }
    })

    if (response.status !== 200) {
      throw new Error('Erro ao gerar o PDF no servidor')
    }

    const disposition = response.headers['content-disposition']
    let filename = `Relatorio_${id}.pdf` // Nome padrão

    if (disposition) {
      const matches = disposition.match(/filename="(.+)"/)
      if (matches && matches[1]) {
        filename = matches[1] // Extrai o nome do arquivo
      }
    }

    // Criar um link para download do PDF
    const url = window.URL.createObjectURL(response.data)
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', filename) // Nome do arquivo PDF
    link.style.display = 'none' // evita visual no DOM

    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)

    setTimeout(() => {
      link.remove?.()
    }, 100)

    window.URL.revokeObjectURL(url) // Revogar o URL depois de usar
  } catch (error) {
    console.error('Erro ao gerar o PDF:', error)
    alert('Erro ao gerar o PDF')
  }
}
