import React from 'react'
import { Flex, HStack, IconButton, Image, Textarea } from '@chakra-ui/react'
import { HeaderChatAITec, Menu } from 'Components'

// import { useMutation } from 'react-query'
// import { chatAi } from 'services/api/requests'
// import { getResult } from 'services/api'
export const inputQuestion = document.getElementById('inputQuestion')
export const result = document.getElementById('result')

export const ChatAITec = () => {
  const [collapse, setCollapse] = React.useState(false)

  const toggleCollapse = () => {
    setCollapse(!collapse)
  }

  React.useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth >= 1200) {
        setCollapse(false)
      } else {
        setCollapse(true)
      }
    }

    checkScreenSize()

    window.addEventListener('resize', checkScreenSize)

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  /* const [message, setMessage] = useState('')
  const updateMessage = async () => {
    const completation = await getResult()
    const message = completation.data.choices[0].message.content
    setMessage(message)
  }
  React.useEffect(() => {
    updateMessage()
  }, [])

  const handleChange = (event) => {
    setMessage(event.target.value)
  }

  const mutation = useMutation((newQuestion) => chatAi(newQuestion), {
    onError: (error) => {
      console.log({ error })
    },
    onSuccess: (data) => {
      console.log({ data })
    }
  })

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputQuestion = event.target.value
      event.target.value = ''
      mutation(inputQuestion)
    }
  } */
  return (
    <HStack w="full" h="100vh" bg="brand.background_home" padding="8px">
      <Flex
        as="aside"
        w="full"
        h="full"
        maxW={collapse ? '60px' : '250px'}
        transition="0.6s"
        bg="brand.background_home"
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between"
        borderRadius="8px"
        overflowX="hidden"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'white',
            border: '1px solid gray'
          }
        }}
      >
        <Menu collapse={collapse} />
      </Flex>
      <Flex
        as="main"
        w="full"
        h="full"
        bg="brand.background"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        position="relative"
        borderRadius="8px"
        overflowX="hidden"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'white',
            border: '1px solid gray'
          }
        }}
      >
        <Flex>
          <IconButton
            bg="none"
            aria-label="Menu Collapse"
            onClick={toggleCollapse}
            transform={collapse ? 'rotate(-180deg)' : 'none'}
            transition="0.6s"
            position="absolute"
            top={2}
            left={2}
            icon={
              <Image
                src="./img/IconArrow.svg"
                alt="IconArrow"
                w="34px"
                h="34px"
              />
            }
            _hover={{
              bg: 'none'
            }}
          />
        </Flex>
        <Flex display="flex" flexDir="column" w="90%" h="90%" mt="100px">
          <Textarea
            id="result"
            rows="10"
            disabled
            // value={message}
            // onChange={handleChange}
            bg="white"
            shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
            color="brand.background_home"
            h="80%"
            textAlign="justify"
          ></Textarea>
          <Textarea
            id="inputQuestion"
            placeholder="Envia uma mensagem..."
            // onKeyDown={handleKeyDown}
            bg="white"
            shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
            color="brand.background_home"
            mt="18px"
            h="10%"
            textAlign="justify"
            border="none"
            _focus={{ border: '2px solid #F2920A' }}
          ></Textarea>
        </Flex>
      </Flex>
      <HeaderChatAITec />
    </HStack>
  )
}
