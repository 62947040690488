import React from 'react'
import { Td, Tr } from '@chakra-ui/react'
import {
  MenuMoreSupplier,
  ModalDeleteSupplier,
  ModalEditSupplier
} from 'Components'

export const Supplier = ({ id, name, cnpj_supplier, onDelete }) => {
  const [showModalDelete, setShowModalDelete] = React.useState()
  const [showModalEdit, setShowModalEdit] = React.useState()

  const onCloseModalDelete = () => {
    setShowModalDelete(null)
  }

  const onCloseModalEdit = () => {
    setShowModalEdit(null)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{cnpj_supplier}</Td>
      <Td display="flex" flexDir="row" justifyContent="flex-end">
        <MenuMoreSupplier
          setShowModalDelete={setShowModalDelete}
          setShowModalEdit={setShowModalEdit}
        />

        {showModalDelete === 'supplier' && (
          <ModalDeleteSupplier
            onDelete={handleDelete}
            onClose={onCloseModalDelete}
          />
        )}
        {showModalEdit === 'supplier' && (
          <ModalEditSupplier
            onClose={onCloseModalEdit}
            id={id}
            supplier={{
              name,
              cnpj_supplier
            }}
          />
        )}
      </Td>
    </Tr>
  )
}
