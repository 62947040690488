import {
  Flex,
  FormControl,
  FormLabel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast
} from '@chakra-ui/react'
import { Button, Text, InputUserModal } from 'Components'
import { useMutation, useQueryClient } from 'react-query'
import { updateUser } from 'services/api/requests'
import { useFormik } from 'formik'
import * as Yup from 'yup'

export const ChangePassword = () => {
  const queryClient = useQueryClient()

  const toast = useToast()

  const mutation = useMutation((data) => updateUser(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao atualizar senha.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Senha atualizada com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      queryClient.invalidateQueries(['user'])
    }
  })

  const { handleSubmit, values, handleChange, errors, dirty } = useFormik({
    initialValues: {
      password: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().min(6, 'Senha deve conter ao menos 6 caracteres.'),
      confirmNewPassword: Yup.string()
        .min(6, 'Senha deve conter ao menos 6 caracteres.')
        .required('Confirmar senha é obrigatória.')
        .oneOf([Yup.ref('password'), null], 'Senhas não são iguais.')
    }),

    onSubmit: (data) => {
      mutation.mutate(data)
    }
  })
  return (
    <Accordion
      w="100%"
      mt="20px"
      allowMultiple
      border="2px"
      borderColor="brand.gray"
      borderRadius="8px"
    >
      <AccordionItem border="none">
        <Flex>
          <AccordionButton h="32px">
            <Flex as="span" flex="1" textAlign="left">
              <Text.Header
                fontSize="13px"
                fontWeight="semibold"
                color="brand.background_home"
                ml="0px"
              >
                Alterar Senha
              </Text.Header>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </Flex>
        <AccordionPanel pb={4}>
          <FormControl mt="8px">
            <FormLabel
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Nova Senha
            </FormLabel>
            <InputUserModal.Password
              id="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              w="100%"
            />
          </FormControl>
          <FormControl mt="8px">
            <FormLabel
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Confirmar Nova Senha
            </FormLabel>
            <InputUserModal.Password
              id="confirmNewPassword"
              name="confirmNewPassword"
              value={values.confirmNewPassword}
              onChange={handleChange}
              error={errors.confirmNewPassword}
              w="100%"
            />
          </FormControl>
          <Button
            isLoading={mutation.isLoading}
            onClick={handleSubmit}
            isDisabled={!dirty}
            h="32px"
            mt="16px"
            bg="#F2920A"
            color="#ffffff"
            fontSize="13px"
            _hover={{
              color: 'brand.background_home',
              bg: 'brand.background'
            }}
          >
            Salvar Senha
          </Button>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
