import { api } from 'services/api'

export const registerTariffModality = (data) =>
  api.post('/tariffmodality', data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getTariffModality = () =>
  api.get('/tariffmodality', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const deleteTariffModality = (id) =>
  api.delete(`/tariffmodality/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })
