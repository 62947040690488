import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Td, Tr, Badge } from '@chakra-ui/react'
import {
  Button,
  MenuMoreUnit,
  ModalDeleteUnit,
  ModalEditUnit
} from 'Components'

export const Unit = ({
  id,
  name,
  consumer_unit,
  cnpj_unit,
  company_id,
  distributor_id,
  supplier_id,
  voltageclass_id,
  tariffmodality_id,
  hiringenvironment_id,
  user_id,
  peakdemandcontract,
  offpeakdemandcontract,
  city,
  state,
  validity,
  company,
  hiringenvironment,
  tariffmodality,
  onDelete
}) => {
  const navigate = useNavigate()

  const [showModalDelete, setShowModalDelete] = React.useState()
  const [showModalEdit, setShowModalEdit] = React.useState()

  const onCloseModalDelete = () => {
    setShowModalDelete(false)
  }

  const onCloseModalEdit = () => {
    setShowModalEdit(false)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  const current = validity ? (
    <Badge colorScheme="green">Ativa</Badge>
  ) : (
    <Badge colorScheme="red">Desativada</Badge>
  )

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{consumer_unit}</Td>
      <Td>{cnpj_unit}</Td>
      <Td>{tariffmodality?.name}</Td>
      <Td>{hiringenvironment?.name}</Td>
      <Td>{current}</Td>
      <Td display="flex" flexDir="row" justifyContent="space-between">
        <Button.listDetail
          onClick={() =>
            navigate(`/clienttec/company/${company_id}/unit/${id}`)
          }
        />
        <MenuMoreUnit
          setShowModalDelete={setShowModalDelete}
          setShowModalEdit={setShowModalEdit}
        />

        {showModalDelete === 'unit' && (
          <ModalDeleteUnit
            onDelete={handleDelete}
            onClose={onCloseModalDelete}
          />
        )}
        {showModalEdit === 'unit' && (
          <ModalEditUnit
            onClose={onCloseModalEdit}
            id={id}
            unit={{
              name,
              consumer_unit,
              cnpj_unit,
              distributor_id,
              supplier_id,
              voltageclass_id,
              tariffmodality_id,
              hiringenvironment_id,
              user_id,
              peakdemandcontract,
              offpeakdemandcontract,
              city,
              state,
              validity,
              company
            }}
          />
        )}
      </Td>
    </Tr>
  )
}
