import { Select, SelectValue } from 'Components'

export const ListSelect = ({ id, name, data, onChange, error, value }) => {
  const options = Array.isArray(data) ? data : []

  const handleChange = (event) => {
    const value = event.target.value
    onChange({ target: { name, value: Number(value) } })
  }

  return (
    <>
      <Select.modal
        id={id}
        name={name}
        value={value}
        error={error}
        onChange={handleChange}
        placeholder="Selecione"
        overflowX="auto"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'null',
            border: 'null'
          }
        }}
      >
        {options.map((item) => (
          <SelectValue key={`selectvalue_${item.id}`} {...item} />
        ))}
      </Select.modal>
    </>
  )
}
