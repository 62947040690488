import React from 'react'
import { Button, InputModal } from 'Components'
import { useNavigate } from 'react-router-dom'
import { getUnitByConsumer } from 'services/api/requests'
import { useMutation } from 'react-query'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  useDisclosure,
  Image,
  FormControl,
  FormLabel,
  useToast
} from '@chakra-ui/react'

export const ModalAddAnalysis = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [consumerUnit, setConsumerUnit] = React.useState('')
  const toast = useToast()
  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(
    (consumerUnit) => getUnitByConsumer(consumerUnit),
    {
      onSuccess: (response) => {
        const unitData = response.data
        toast({
          title: 'Unidade encontrada!',
          description: 'Dados da unidade foram carregados com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })

        navigate('/analysis/new', {
          state: { unitData, isLoading: false }
        })
        onClose()
      },
      onError: (error) => {
        console.error('Erro ao buscar a unidade:', error)
        toast({
          title: 'Erro ao buscar unidade.',
          description:
            error.response?.data?.error || 'Por favor, tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  )

  const handleStart = () => {
    if (consumerUnit) {
      mutate(consumerUnit)
    } else {
      toast({
        title: 'Campo obrigatório.',
        description: 'Por favor, preencha o número da unidade consumidora.',
        status: 'warning',
        duration: 3000,
        isClosable: true
      })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleStart()
    }
  }

  return (
    <Flex>
      <Button.add
        w="140px"
        justifyContent="space-between"
        rightIcon={
          <Image
            src="/img/IconAnaliseB.svg"
            alt="IconAddAnalysisB"
            w="22px"
            h="22px"
          />
        }
        onClick={onOpen}
      >
        Nova Análise
      </Button.add>
      <Modal isOpen={isOpen} onClose={onClose} size={2}>
        <ModalOverlay />
        <ModalContent
          flexDir="column"
          w={['300px', '500px', '700px', '700px']}
          justifyContent="center"
          borderRadius="8px"
          shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
        >
          <ModalHeader
            display="flex"
            flexDir="row"
            alignItems="flex-end"
            color="#F2920A"
          >
            <Image
              mr="12px"
              src="/img/IconAnalise.svg"
              alt="IconAddEmpresa"
              w="32px"
              h="32px"
            />
            Adicionar Nova Análise
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="center" p={10}>
              <FormControl
                w={['100%', '100%', '100%', '326px']}
                mt={[4, 4, 4, 0]}
              >
                <FormLabel
                  htmlFor="consumer_unit"
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Unidade Consumidora
                </FormLabel>
                <InputModal
                  id="consumer_unit"
                  name="consumer_unit"
                  value={consumerUnit}
                  onChange={(e) => setConsumerUnit(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              onClick={handleStart}
              bg="#F2920A"
              color="#ffffff"
              h="34px"
              mr={3}
              _hover={{
                color: 'brand.background_home',
                bg: 'brand.background'
              }}
            >
              Iniciar
            </Button>
            <Button h="34px" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
