import React from 'react'
import {
  FormControl,
  FormLabel,
  Flex,
  useToast,
  Button,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import { InputModal, ListSelect, Loader, EmptyMessage } from 'Components'
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getVoltageClass,
  getTariffModality,
  getTariffById,
  updateTariff
} from 'services/api/requests'

export const TariffDetails = ({ id }) => {
  const toast = useToast()

  const queryClient = useQueryClient()

  const { data: tariff, isLoading } = useQuery(
    ['tariff', id],
    () => getTariffById(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )

  const tariffData = tariff?.data

  const formattedStartDate = moment
    .utc(tariffData?.start_date)
    .format('YYYY-MM-DD')
  const formattedEndDate = moment.utc(tariffData?.end_date).format('YYYY-MM-DD')

  const { data: voltageclass } = useQuery('voltageclass', getVoltageClass)
  const voltageclassData = voltageclass?.data || []

  const { data: tariffmodality } = useQuery('tariffmodality', getTariffModality)
  const tariffmodalityData = tariffmodality?.data || []

  const mutation = useMutation((data) => updateTariff(id, data), {
    onError: (error) => {
      toast({
        title: 'Falha ao atualizar tarifa.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Tarifa atualizado com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      queryClient.invalidateQueries(['tariff'])
    }
  })

  const { handleSubmit, values, handleChange, errors, dirty, setFieldValue } =
    useFormik({
      initialValues: {
        distributor_id: tariffData?.distributor_id || '',
        tariffmodality_id: tariffData?.tariffmodality_id || '',
        voltageclass_id: tariffData?.voltageclass_id || '',
        peakdemand: tariffData?.peakdemand || '',
        offpeakdemand: tariffData?.offpeakdemand || '',
        peakconsumption_tusd: tariffData?.peakconsumption_tusd || '',
        offpeakconsumption_tusd: tariffData?.offpeakconsumption_tusd || '',
        peakconsumption_te: tariffData?.peakconsumption_te || '',
        offpeakconsumption_te: tariffData?.offpeakconsumption_te || '',
        surplusreactivepower: tariffData?.surplusreactivepower || '',
        waterscarcity: tariffData?.waterscarcity || '',
        covid_tusd: tariffData?.covid_tusd || '',
        start_date: formattedStartDate || '',
        end_date: formattedEndDate || ''
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        distributor_id: Yup.number(),
        tariffmodality_id: Yup.number(),
        voltageclass_id: Yup.number(),
        peakdemand: Yup.number(),
        offpeakdemand: Yup.number(),
        peakconsumption_tusd: Yup.number(),
        offpeakconsumption_tusd: Yup.number(),
        peakconsumption_te: Yup.number(),
        offpeakconsumption_te: Yup.number(),
        surplusreactivepower: Yup.number(),
        waterscarcity: Yup.number(),
        covid_tusd: Yup.number(),
        start_date: Yup.string(),
        end_date: Yup.string()
      }),

      onSubmit: (data) => {
        const convertData = Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value === '' ? 0 : value
          ])
        )
        mutation.mutate(convertData)
      }
    })

  const isConventional = values.tariffmodality_id === 3

  const isGreen = values.tariffmodality_id === 2

  const [filteredVoltageClass, setFilteredVoltageClass] =
    React.useState(voltageclassData)

  const handleTariffChange = (e) => {
    const { value } = e.target
    setFieldValue('tariffmodality_id', value)

    if (value === 1 || value === 2) {
      setFilteredVoltageClass(
        voltageclassData.filter((option) => option.id >= 1 && option.id <= 6)
      )
    } else if (value === 3) {
      setFilteredVoltageClass(
        voltageclassData.filter((option) => option.id > 6)
      )
    } else {
      setFilteredVoltageClass(voltageclassData)
    }
  }

  React.useEffect(() => {
    if (values.tariffmodality_id === 1 || values.tariffmodality_id === 2) {
      setFilteredVoltageClass(
        voltageclassData.filter((option) => option.id >= 1 && option.id <= 6)
      )
    } else if (values.tariffmodality_id === 3) {
      setFilteredVoltageClass(
        voltageclassData.filter((option) => option.id > 6)
      )
    } else {
      setFilteredVoltageClass(voltageclassData)
    }
  }, [values.tariffmodality_id, voltageclassData])

  const handleFocus = (e) => {
    if (e.target.value === 0) {
      setFieldValue(e.target.name, '')
    }
  }

  const handleBlur = (e) => {
    if (e.target.value === '') {
      setFieldValue(e.target.name, 0)
    }
  }
  if (isLoading) {
    return <Loader />
  }
  if (!tariffData || tariffData.length === 0) {
    return <EmptyMessage>Erro ao buscar detalhes da tarifa.</EmptyMessage>
  }
  return (
    <Flex
      backgroundColor="white"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="75%"
      mb="16px"
      padding={8}
      flexDir={['column', 'column', 'column', 'column']}
      overflowX="hidden"
      overflowY="auto"
      css={{
        '::-webkit-scrollbar': {
          width: '4px',
          height: '4px'
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          background: 'gray'
        },
        '::-webkit-scrollbar-track': {
          borderRadius: '4px',
          background: 'white',
          border: '1px solid gray'
        }
      }}
    >
      <Flex
        w="100%"
        paddingLeft={[0, 0, 0, isConventional ? 20 : 0]}
        paddingRight={[0, 0, 0, isConventional ? 20 : 0]}
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent={
          values.tariffmodality_id ? 'space-between' : 'flex-start'
        }
      >
        <FormControl
          w={['100%', '100%', '100%', isConventional ? '240px' : '200px']}
          mt={[4, 4, 4, 4]}
          isDisabled
        >
          <FormLabel
            display="flex"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center'
            ]}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Modalidade Tarifária
          </FormLabel>
          <ListSelect
            id="tariffmodality_id"
            name="tariffmodality_id"
            value={values.tariffmodality_id}
            onChange={handleTariffChange}
            error={errors.tariffmodality_id}
            data={tariffmodalityData}
          />
        </FormControl>
        {values.tariffmodality_id && (
          <FormControl
            w={['100%', '100%', '100%', isConventional ? '240px' : '200px']}
            mt={[4, 4, 4, 4]}
          >
            <FormLabel
              display="flex"
              justifyContent={[
                'flex-start',
                'flex-start',
                'flex-start',
                'center'
              ]}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Classe de Tensão
            </FormLabel>
            <ListSelect
              id="voltageclass_id"
              name="voltageclass_id"
              value={values.voltageclass_id}
              onChange={handleChange}
              error={errors.voltageclass_id}
              data={filteredVoltageClass}
            />
          </FormControl>
        )}
        {isConventional ||
          (values.tariffmodality_id && (
            <>
              <Flex w={['100%', '100%', '100%', '40%']} flexDir="column">
                <Box
                  mt={[6, 6, 6, 0]}
                  position="relative"
                  paddingLeft={4}
                  paddingRight={4}
                >
                  <Divider />
                  <AbsoluteCenter fontSize="13px" bg="white" px="4">
                    Encargos
                  </AbsoluteCenter>
                </Box>
                <Flex
                  flexDir={['column', 'row', 'row', 'row']}
                  justifyContent="space-between"
                >
                  <FormControl
                    w={['100%', '48%', '48%', '136px']}
                    mt={[4, 4, 4, 4]}
                  >
                    <FormLabel
                      display="flex"
                      textAlign={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'center'
                      ]}
                      mb="0px"
                      fontSize="12px"
                      fontWeight="semibold"
                      color="#303030"
                      justifyContent="center"
                    >
                      Escassez Hídrica
                    </FormLabel>
                    <InputModal
                      id="waterscarcity"
                      name="waterscarcity"
                      value={values.waterscarcity}
                      onChange={handleChange}
                      error={errors.waterscarcity}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="0,00 R$/MWh"
                      type="number"
                      textAlign="center"
                    />
                  </FormControl>
                  <FormControl
                    w={['100%', '48%', '48%', '136px']}
                    mt={[4, 4, 4, 4]}
                  >
                    <FormLabel
                      display="flex"
                      textAlign={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'center'
                      ]}
                      mb="0px"
                      fontSize="12px"
                      fontWeight="semibold"
                      color="#303030"
                      justifyContent="center"
                    >
                      Conta COVID
                    </FormLabel>
                    <InputModal
                      id="covid_tusd"
                      name="covid_tusd"
                      value={values.covid_tusd}
                      onChange={handleChange}
                      error={errors.covid_tusd}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="0,00 R$/MWh"
                      type="number"
                      textAlign="center"
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </>
          ))}
      </Flex>
      {!isConventional ||
        (values.tariffmodality_id && (
          <>
            <Flex
              mt={[0, 4, 4, 4]}
              w={['100%', '100%', '100%', '100%']}
              flexDir="column"
            >
              <Box mt={6} position="relative" paddingLeft={4} paddingRight={4}>
                <Divider />
                <AbsoluteCenter fontSize="13px" bg="white" px="4">
                  Consumo
                </AbsoluteCenter>
              </Box>
              <Flex
                flexDir={['column', 'row', 'row', 'row']}
                paddingLeft={[0, 0, 0, 20]}
                paddingRight={[0, 0, 0, 20]}
                justifyContent="space-between"
              >
                <FormControl
                  w={['100%', '45%', '45%', '240px']}
                  mt={[4, 4, 4, 4]}
                >
                  <FormLabel
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                    justifyContent="center"
                  >
                    TUSD (R$/MWh)
                  </FormLabel>
                  <InputModal
                    id="offpeakconsumption_tusd"
                    name="offpeakconsumption_tusd"
                    value={values.offpeakconsumption_tusd}
                    onChange={handleChange}
                    error={errors.offpeakconsumption_tusd}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="0,00 R$/MWh"
                    type="number"
                    textAlign="center"
                  />
                </FormControl>
                <FormControl
                  w={['100%', '45%', '45%', '240px']}
                  mt={[4, 4, 4, 4]}
                >
                  <FormLabel
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                    justifyContent="center"
                  >
                    TE (R$/MWh)
                  </FormLabel>
                  <InputModal
                    id="offpeakconsumption_te"
                    name="offpeakconsumption_te"
                    value={values.offpeakconsumption_te}
                    onChange={handleChange}
                    error={errors.offpeakconsumption_te}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="0,00 R$/MWh"
                    type="number"
                    textAlign="center"
                  />
                </FormControl>
              </Flex>
            </Flex>
          </>
        ))}
      {isConventional ||
        (values.tariffmodality_id && (
          <>
            <Flex
              mt={[0, 4, 4, 4]}
              w={['100%', '100%', '100%', '100%']}
              flexDir="column"
            >
              <Box mt={6} position="relative" paddingLeft={4} paddingRight={4}>
                <Divider />
                <AbsoluteCenter fontSize="13px" bg="white" px="4">
                  Consumo
                </AbsoluteCenter>
              </Box>
              <Flex
                flexDir={['column', 'row', 'row', 'row']}
                justifyContent="space-between"
              >
                <Flex
                  w={['100%', '44%', '44%', '44%']}
                  flexDir={['column', 'column', 'column', 'row']}
                  justifyContent="space-between"
                >
                  <FormControl
                    w={['100%', '100%', '100%', '180px']}
                    mt={[4, 4, 4, 4]}
                  >
                    <FormLabel
                      display="flex"
                      textAlign={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'center'
                      ]}
                      mb="0px"
                      fontSize="12px"
                      fontWeight="semibold"
                      color="#303030"
                      justifyContent="center"
                    >
                      TUSD Fora Ponta (R$/MWh)
                    </FormLabel>
                    <InputModal
                      id="offpeakconsumption_tusd"
                      name="offpeakconsumption_tusd"
                      value={values.offpeakconsumption_tusd}
                      onChange={handleChange}
                      error={errors.offpeakconsumption_tusd}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="0,00 R$/MWh"
                      type="number"
                      textAlign="center"
                    />
                  </FormControl>
                  <FormControl
                    w={['100%', '100%', '100%', '180px']}
                    mt={[4, 4, 4, 4]}
                  >
                    <FormLabel
                      display="flex"
                      textAlign={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'center'
                      ]}
                      mb="0px"
                      fontSize="12px"
                      fontWeight="semibold"
                      color="#303030"
                      justifyContent="center"
                    >
                      TUSD Ponta (R$/MWh)
                    </FormLabel>
                    <InputModal
                      id="peakconsumption_tusd"
                      name="peakconsumption_tusd"
                      value={values.peakconsumption_tusd}
                      onChange={handleChange}
                      error={errors.peakconsumption_tusd}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="0,00 R$/MWh"
                      type="number"
                      textAlign="center"
                    />
                  </FormControl>
                </Flex>
                <Flex
                  w={['100%', '44%', '44%', '44%']}
                  flexDir={['column', 'column', 'column', 'row']}
                  justifyContent="space-between"
                >
                  <FormControl
                    w={['100%', '100%', '100%', '180px']}
                    mt={[4, 4, 4, 4]}
                  >
                    <FormLabel
                      display="flex"
                      textAlign={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'center'
                      ]}
                      mb="0px"
                      fontSize="12px"
                      fontWeight="semibold"
                      color="#303030"
                      justifyContent="center"
                    >
                      TE Fora Ponta (R$/MWh)
                    </FormLabel>
                    <InputModal
                      id="offpeakconsumption_te"
                      name="offpeakconsumption_te"
                      value={values.offpeakconsumption_te}
                      onChange={handleChange}
                      error={errors.offpeakconsumption_te}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="0,00 R$/MWh"
                      type="number"
                      textAlign="center"
                    />
                  </FormControl>
                  <FormControl
                    w={['100%', '100%', '100%', '180px']}
                    mt={[4, 4, 4, 4]}
                  >
                    <FormLabel
                      display="flex"
                      textAlign={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'center'
                      ]}
                      mb="0px"
                      fontSize="12px"
                      fontWeight="semibold"
                      color="#303030"
                      justifyContent="center"
                    >
                      TE Ponta (R$/MWh)
                    </FormLabel>
                    <InputModal
                      id="peakconsumption_te"
                      name="peakconsumption_te"
                      value={values.peakconsumption_te}
                      onChange={handleChange}
                      error={errors.peakconsumption_te}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="0,00 R$/MWh"
                      type="number"
                      textAlign="center"
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </>
        ))}
      <Flex
        w="100%"
        justifyContent="space-between"
        flexDir={['column', 'column', 'row', 'row']}
      >
        {values.tariffmodality_id && (
          <Flex
            mt={[0, 4, 4, 4]}
            w={['100%', '100%', '100%', '44%']}
            flexDir="column"
          >
            <Box mt={6} position="relative" paddingLeft={4} paddingRight={4}>
              <Divider />
              <AbsoluteCenter
                textAlign="center"
                fontSize="13px"
                bg="white"
                px="4"
              >
                Excedente Reativo
              </AbsoluteCenter>
            </Box>
            <Flex
              w={['100%', '100%', '100%', '100%']}
              flexDir={['column', 'row', 'row', 'row']}
              justifyContent="center"
            >
              <FormControl w={['100%', '44%', '44%', '180px']} mt={4}>
                <FormLabel
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                  justifyContent="center"
                >
                  TE (R$/MWh)
                </FormLabel>
                <InputModal
                  id="surplusreactivepower"
                  name="surplusreactivepower"
                  value={values.surplusreactivepower}
                  onChange={handleChange}
                  error={errors.surplusreactivepower}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder="0,00 R$/kWh"
                  type="number"
                  textAlign="center"
                />
              </FormControl>
            </Flex>
          </Flex>
        )}
        {isConventional ||
          (values.tariffmodality_id && (
            <Flex
              mt={[0, 4, 4, 4]}
              w={['100%', '100%', '100%', '44%']}
              flexDir="column"
            >
              <Box mt={6} position="relative" paddingLeft={4} paddingRight={4}>
                <Divider />
                <AbsoluteCenter fontSize="13px" bg="white" px="4">
                  Demanda
                </AbsoluteCenter>
              </Box>
              <Flex
                w={['100%', '100%', '100%', '100%']}
                flexDir={['column', 'row', 'row', 'row']}
                justifyContent={isGreen ? 'center' : 'space-between'}
              >
                <FormControl w={['100%', '44%', '44%', '180px']} mt={4}>
                  <FormLabel
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                    justifyContent="center"
                  >
                    {isGreen ? 'TUSD (R$/kWh)' : 'TUSD Fora Ponta (R$/kWh)'}
                  </FormLabel>
                  <InputModal
                    id="offpeakdemand"
                    name="offpeakdemand"
                    value={values.offpeakdemand}
                    onChange={handleChange}
                    error={errors.offpeakdemand}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="0,00 R$/kWh"
                    type="number"
                    textAlign="center"
                  />
                </FormControl>
                {!isGreen && (
                  <FormControl w={['100%', '44%', '44%', '180px']} mt={4}>
                    <FormLabel
                      display="flex"
                      textAlign={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'center'
                      ]}
                      mb="0px"
                      fontSize="12px"
                      fontWeight="semibold"
                      color="#303030"
                      justifyContent="center"
                    >
                      TUSD Ponta (R$/kWh)
                    </FormLabel>
                    <InputModal
                      id="peakdemand"
                      name="peakdemand"
                      value={values.peakdemand}
                      onChange={handleChange}
                      error={errors.peakdemand}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="0,00 R$/kWh"
                      type="number"
                      textAlign="center"
                    />
                  </FormControl>
                )}
              </Flex>
            </Flex>
          ))}
      </Flex>
      {values.tariffmodality_id && (
        <Flex
          justifyContent="space-between"
          flexDir={['column', 'column', 'column', 'row']}
        >
          <Flex
            mt={[0, 6, 6, 6]}
            w={['100%', '100%', '100%', '44%']}
            flexDir="column"
          >
            <Box mt={6} position="relative" paddingLeft={4} paddingRight={4}>
              <Divider />
              <AbsoluteCenter fontSize="13px" bg="white" px="4">
                Vigência
              </AbsoluteCenter>
            </Box>
            <Flex
              w={['100%', '100%', '100%', '100%']}
              flexDir={['column', 'row', 'row', 'row']}
              justifyContent="space-between"
            >
              <FormControl w={['100%', '44%', '44%', '180px']} mt={4}>
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Início
                </FormLabel>
                <InputModal
                  id="start_date"
                  name="start_date"
                  value={values.start_date}
                  onChange={handleChange}
                  error={errors.start_date}
                  type="date"
                />
              </FormControl>
              <FormControl w={['100%', '44%', '44%', '180px']} mt={4}>
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Fim
                </FormLabel>
                <InputModal
                  id="end_date"
                  name="end_date"
                  value={values.end_date}
                  onChange={handleChange}
                  error={errors.end_date}
                  type="date"
                />
              </FormControl>
            </Flex>
          </Flex>
          <Flex
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'flex-end'
            ]}
            alignItems="flex-end"
            mt={[4, 4, 4, 10]}
          >
            <Button
              mt={[6, 6, 6, 0]}
              isLoading={mutation.isLoading}
              onClick={handleSubmit}
              bg="#F2920A"
              color="#ffffff"
              h="34px"
              _hover={{
                color: 'brand.background_home',
                bg: 'brand.background'
              }}
              isDisabled={!dirty}
            >
              Salvar Alterações
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
