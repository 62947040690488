import React from 'react'
import moment from 'moment'
import { Td, Tr, Image } from '@chakra-ui/react'

const bandeiraImagens = {
  Verde: '/img/FlagGreen.svg',
  Amarela: '/img/FlagYellow.svg',
  'Vermelha P1': '/img/FlagRed.svg',
  'Vermelha P2': '/img/FlagRed2.svg',
  'Escassez Hídrica': '/img/FlagOthers.svg'
}

export const TariffFlag = ({
  id,
  NomBandeiraAcionada,
  VlrAdicionalBandeira,
  DatCompetencia,
  onDelete
}) => {
  const formattedStartDate = moment.utc(DatCompetencia).format('MMM/YYYY')

  const value = NomBandeiraAcionada === 'Verde' ? '0,00' : VlrAdicionalBandeira

  const formattedName =
    NomBandeiraAcionada === 'Escassez Hdrica'
      ? 'Escassez Hídrica'
      : NomBandeiraAcionada

  const bandeiraImage = bandeiraImagens[formattedName] || 'imagem-padrão.svg'
  return (
    <Tr>
      <Td>{formattedStartDate}</Td>
      <Td>{formattedName}</Td>
      <Td>R$ {value}</Td>
      <Td>
        <Image src={bandeiraImage} alt={NomBandeiraAcionada} boxSize="30px" />
      </Td>
    </Tr>
  )
}
