import {
  Table,
  Thead,
  Tr,
  Th,
  TableContainer,
  Flex,
  useToast
} from '@chakra-ui/react'
import { getTariffModality, deleteTariffModality } from 'services/api/requests'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { ListTariffModality, Loader, EmptyMessage } from 'Components'

export const TableTariffModality = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { data: tariffmodality, isLoading } = useQuery(
    'tariffmodality',
    getTariffModality,
    {
      retry: 1,
      retryDelay: 1000
    }
  )
  const data = tariffmodality?.data

  const deleteTariffModalityMutation = useMutation(
    (data) => deleteTariffModality(data),
    {
      onError: (error) => {
        toast({
          title: 'Falha ao excluir modalidade.',
          description:
            error?.response?.data?.error || 'Por favor, tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      },
      onSuccess: () => {
        toast({
          title: 'Modalidade excluída com sucesso!',
          status: 'success',
          duration: 6000,
          isClosable: true
        })
        queryClient.removeQueries('tariffmodality')
      }
    }
  )
  const handleDelete = (data) => {
    deleteTariffModalityMutation.mutate(data)
  }

  if (isLoading) {
    return <Loader />
  }

  if (!data || data?.length === 0) {
    return <EmptyMessage>Nenhuma modalidade encontrada.</EmptyMessage>
  }

  return (
    <Flex>
      <TableContainer
        w="100%"
        justifyContent="space-between"
        overflowX="auto"
        overflowY="auto"
        borderRadius="8px"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'null',
            border: 'null'
          }
        }}
      >
        <Table mb="8px" mt="8px" size="sm">
          <Thead position="sticky" top="0" zIndex="docked" bg="#ffffff">
            <Tr height="34px">
              <Th fontSize="14px">Modalidade Tarifária</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <ListTariffModality
            borderRadius="8px"
            onDelete={handleDelete}
            data={data}
          />
        </Table>
      </TableContainer>
    </Flex>
  )
}
