import {
  Flex,
  FormLabel,
  FormControl,
  Image,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import {
  VariablesSection,
  InputModal,
  ListSelect,
  FlagImageMap,
  Status
} from 'Components'
import { getAnalysisById } from 'services/api/requests'
import { useQuery } from 'react-query'

export const SummarySection = ({
  id,
  values,
  handleChange,
  setFieldValue,
  errors,
  isRegulated,
  isConventional
}) => {
  const { data: analysis } = useQuery(
    ['analysis', id],
    () => getAnalysisById(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const analysisData = analysis?.data

  const getFlagImageById = (flagId) => {
    return (
      FlagImageMap.find((flag) => flag.id === parseInt(flagId, 10))?.image ||
      '/img/defaultFlag.svg'
    )
  }

  return (
    <Flex flexDir="column">
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Status Análise
        </AbsoluteCenter>
      </Box>
      <Flex justifyContent="center">
        <Status status={analysisData?.status} />
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="center"
        alignItems="flex-end"
        mt={10}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="valueDivergence"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Divergência Fatura (R$)
          </FormLabel>
          <InputModal.Result
            id="valueDivergence"
            name="valueDivergence"
            value={analysisData?.valueDivergence || 0}
          />
        </FormControl>
        {!isRegulated && (
          <>
            <Divider marginX={6} orientation="vertical" />
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="valueDivergenceEnergy"
                display="flex"
                textAlign="flex-start"
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Divergência Energia (R$)
              </FormLabel>
              <InputModal.Result
                id="valueDivergenceEnergy"
                name="valueDivergenceEnergy"
                value={analysisData?.valueDivergenceEnergy || 0}
              />
            </FormControl>
          </>
        )}
      </Flex>
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Total
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent={isRegulated ? 'center' : 'space-between'}
        alignItems="flex-end"
      >
        {!isRegulated && (
          <>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="valueTotalInvoice"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor Fatura (R$)
              </FormLabel>
              <InputModal.Result
                id="valueTotalInvoice"
                name="valueTotalInvoice"
                value={analysisData?.valueTotalInvoice || 0}
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="valueTotalEnergy"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor Energia (R$)
              </FormLabel>
              <InputModal.Result
                id="valueTotalEnergy"
                name="valueTotalEnergy"
                value={analysisData?.valueTotalEnergy || 0}
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="valueTotalCharges"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor Encargos (R$)
              </FormLabel>
              <InputModal.Result
                id="valueTotalCharges"
                name="valueTotalCharges"
                value={analysisData?.valueTotalCharges || 0}
              />
            </FormControl>
          </>
        )}
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="valueTotal"
            display="flex"
            textAlign="flex-start"
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor Total (R$)
          </FormLabel>
          <InputModal.Result
            id="valueTotal"
            name="valueTotal"
            value={analysisData?.valueTotal || 0}
          />
        </FormControl>
      </Flex>

      <Box mt={6} position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Impostos e Bandeira
        </AbsoluteCenter>
      </Box>

      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
      >
        <Flex
          w={['100%', '100%', '100%', '50%']}
          border="2px"
          borderRadius="8px"
          borderColor="brand.gray"
          padding={5}
          justifyContent="space-between"
          flexDir={['column', 'column', 'column', 'row']}
        >
          <Flex w={['100%', '100%', '100%', '30%']} flexDir="column">
            <FormControl>
              <FormLabel
                htmlFor="tax_icms"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                ICMS (%)
              </FormLabel>
              <InputModal
                id="tax_icms"
                name="tax_icms"
                value={values.tax_icms}
                onChange={handleChange}
                error={errors.tax_icms}
                placeholder="0,00 %"
                type="number"
                textAlign="center"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                htmlFor="valueICMS"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                ICMS (R$)
              </FormLabel>
              <InputModal.Result
                id="valueICMS"
                name="valueICMS"
                value={analysisData?.valueICMS || 0}
              />
            </FormControl>
          </Flex>
          <Flex w={['100%', '100%', '100%', '30%']} flexDir="column">
            <FormControl mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="tax_pis"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                PIS (%)
              </FormLabel>
              <InputModal.Currency
                id="tax_pis"
                name="tax_pis"
                value={values.tax_pis}
                onChange={(val) => setFieldValue('tax_pis', val)}
                error={errors.tax_pis}
                placeholder="0,00 %"
                textAlign="center"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                htmlFor="valuePIS"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                PIS (R$)
              </FormLabel>
              <InputModal.Result
                id="valuePIS"
                name="valuePIS"
                value={analysisData?.valuePIS || 0}
              />
            </FormControl>
          </Flex>
          <Flex w={['100%', '100%', '100%', '30%']} flexDir="column">
            <FormControl mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="tax_cofins"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                COFINS (%)
              </FormLabel>
              <InputModal.Currency
                id="tax_cofins"
                name="tax_cofins"
                value={values.tax_cofins}
                onChange={(val) => setFieldValue('tax_cofins', val)}
                error={errors.tax_cofins}
                placeholder="0,00 %"
                textAlign="center"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                htmlFor="valueCOFINS"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                COFINS (R$)
              </FormLabel>
              <InputModal.Result
                id="valueCOFINS"
                name="valueCOFINS"
                value={analysisData?.valueCOFINS || 0}
              />
            </FormControl>
          </Flex>
        </Flex>
        <Flex
          w={['100%', '100%', '100%', '40%']}
          border="2px"
          borderRadius="8px"
          borderColor="brand.gray"
          padding={6}
          justifyContent="space-between"
          mt={[4, 4, 4, 0]}
          flexDir={['column', 'column', 'column', 'row']}
        >
          <Flex
            alignItems="center"
            flexDir="column"
            w={['100%', '100%', '100%', '40%']}
          >
            <Image
              src={getFlagImageById(values.flag_name)}
              alt={`Bandeira ${values.flag_name}`}
              boxSize="40px"
              mt={4}
            />
            <FormControl mt={4}>
              <FormLabel
                htmlFor="flag_name"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Bandeira
              </FormLabel>
              <ListSelect
                id="flag_name"
                name="flag_name"
                value={values.flag_name}
                onChange={handleChange}
                error={errors.flag_name}
                data={FlagImageMap}
              />
            </FormControl>
          </Flex>
          <Flex flexDir="column" w={['100%', '100%', '100%', '40%']}>
            <FormControl mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="flag_price"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Tarifa da Bandeira (R$/MWh)
              </FormLabel>
              <InputModal.Currency
                id="flag_price"
                name="flag_price"
                value={values.flag_price}
                onChange={(val) => setFieldValue('flag_price', val)}
                error={errors.flag_price}
                placeholder="0,00 R$/MWh"
                textAlign="center"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                htmlFor="valueFlag"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor Bandeira (R$)
              </FormLabel>
              <InputModal.Result
                id="valueFlag"
                name="valueFlag"
                value={analysisData?.valueFlag || 0}
              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>

      <VariablesSection
        values={values}
        handleChange={handleChange}
        errors={errors}
        isRegulated={isRegulated}
        isConventional={isConventional}
      />
    </Flex>
  )
}
