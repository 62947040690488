import React from 'react'
import { Text } from 'Components/atoms/Text'
import {
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  Button
} from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { PatternFormat } from 'react-number-format'

export const InputUserModal = (props) => (
  <>
    <ChakraInput
      display="flex"
      alignItems="center"
      w="400px"
      h="32px"
      fontSize="14px"
      border="2px"
      borderRadius="8px"
      borderColor="brand.gray"
      focusBorderColor="brand.primary"
      {...props}
    />
    {props.error && (
      <Text fontSize="12px" color="red">
        {props.error}
      </Text>
    )}
  </>
)

InputUserModal.Number = ({ value, onChange, id, name, ...props }) => {
  return (
    <>
      <InputGroup display="flex" alignItems="center" size="md" {...props}>
        <ChakraInput
          as={PatternFormat}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          type="tel"
          format="(##) ##### ####"
          h="32px"
          fontSize="14px"
          border="2px"
          borderRadius="8px"
          borderColor="brand.gray"
          focusBorderColor="brand.primary"
        />
      </InputGroup>
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

InputUserModal.Number.displayName = 'InputUserModalNumber'

InputUserModal.Password = ({ value, onChange, id, name, ...props }) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  return (
    <>
      <InputGroup display="flex" alignItems="center" size="md" {...props}>
        <InputUserModal
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          type={show ? 'text' : 'password'}
          h="32px"
          fontSize="14px"
          border="2px"
          borderRadius="8px"
          borderColor="brand.gray"
          focusBorderColor="brand.primary"
        />
        <InputRightElement h="33px">
          <Button
            display="flex"
            justifyContent="center"
            h="100%"
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            size="sm"
            onClick={handleClick}
          >
            {show ? (
              <ViewOffIcon boxSize="18px" color="brand.primary" />
            ) : (
              <ViewIcon boxSize="18px" color="brand.primary" />
            )}
          </Button>
        </InputRightElement>
      </InputGroup>
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

InputUserModal.Password.displayName = 'InputUserModalPassword'
