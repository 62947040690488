import React from 'react'
import {
  Flex,
  HStack,
  IconButton,
  Image,
  RadioGroup,
  Radio,
  Stack
} from '@chakra-ui/react'
import {
  FaCircleCheck,
  FaBarsStaggered,
  FaCircleExclamation
} from 'react-icons/fa6'
import { FaPaperPlane } from 'react-icons/fa'
import {
  HeaderAnalysis,
  Menu,
  SearchBar,
  ModalAddAnalysis,
  TableAnalysis
} from 'Components'

export const Analysis = () => {
  const [collapse, setCollapse] = React.useState(false)
  const [query, setQuery] = React.useState('')

  // Novo estado para controlar o filtro de status
  const [filterStatus, setFilterStatus] = React.useState('all')

  const toggleCollapse = () => {
    setCollapse(!collapse)
  }

  React.useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth >= 1200) {
        setCollapse(false)
      } else {
        setCollapse(true)
      }
    }

    checkScreenSize()

    window.addEventListener('resize', checkScreenSize)

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  return (
    <HStack w="full" h="100vh" bg="brand.background_home" padding="8px">
      <Flex
        as="aside"
        w="full"
        h="full"
        maxW={collapse ? '60px' : '250px'}
        transition="0.6s"
        bg="brand.background_home"
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between"
        borderRadius="8px"
        overflowX="hidden"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'white',
            border: '1px solid gray'
          }
        }}
      >
        <Menu collapse={collapse} />
      </Flex>
      <Flex
        as="main"
        w="full"
        h="full"
        bg="brand.background"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        position="relative"
        borderRadius="8px"
        overflowX="hidden"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'white',
            border: '1px solid gray'
          }
        }}
      >
        <Flex w="96%" h="100%" flexDir="column">
          <Flex w="100%" h="120px">
            <IconButton
              bg="none"
              aria-label="Menu Collapse"
              onClick={toggleCollapse}
              transform={collapse ? 'rotate(-180deg)' : 'none'}
              transition="0.6s"
              position="absolute"
              top={2}
              left={2}
              icon={
                <Image
                  src="/img/IconArrow.svg"
                  alt="IconArrow"
                  w="34px"
                  h="34px"
                />
              }
              _hover={{
                bg: 'none'
              }}
            />{' '}
          </Flex>
          <Flex
            flexDirection={['column-reverse', 'column-reverse', 'row', 'row']}
            justifyContent="space-between"
            alignItems={['center', 'center', 'baseline', 'baseline']}
          >
            <Flex
              flexDirection={['column-reverse', 'column-reverse', 'row', 'row']}
              justifyContent="center"
              alignItems={['center', 'center', 'center', 'center']}
            >
              <SearchBar onChange={setQuery} />
              <Flex
                mb={['8px', '8px', '0px', '0px']}
                mt={['14px', '14px', '0px', '0px']}
              >
                <RadioGroup
                  id="status-radio-group"
                  value={filterStatus}
                  onChange={(value) => setFilterStatus(value)}
                >
                  <Stack direction="row" spacing={4}>
                    <Radio value="all" hidden={true}>
                      <Flex
                        style={{ transition: 'width 0.6s' }}
                        _hover={
                          filterStatus === 'all'
                            ? {}
                            : {
                                transform: 'scale(1.12)'
                              }
                        }
                      >
                        <FaBarsStaggered
                          size={filterStatus === 'all' ? 24 : 20}
                          color={filterStatus === 'all' ? 'black' : 'grey'}
                        />
                      </Flex>
                    </Radio>
                    <Radio value="Completed" hidden={true}>
                      <Flex
                        _hover={
                          filterStatus === 'Completed'
                            ? {}
                            : {
                                transform: 'scale(1.12)'
                              }
                        }
                      >
                        <FaCircleCheck
                          size={filterStatus === 'Completed' ? 24 : 20}
                          color={
                            filterStatus === 'Completed' ? '#99D44D' : 'grey'
                          }
                        />
                      </Flex>
                    </Radio>
                    <Radio value="Divergent" hidden={true}>
                      <Flex
                        _hover={
                          filterStatus === 'Divergent'
                            ? {}
                            : {
                                transform: 'scale(1.12)'
                              }
                        }
                      >
                        <FaCircleExclamation
                          size={filterStatus === 'Divergent' ? 24 : 20}
                          color={
                            filterStatus === 'Divergent' ? '#D95D5D' : 'grey'
                          }
                        />
                      </Flex>
                    </Radio>
                    <Radio value="Sent" hidden={true}>
                      <Flex
                        _hover={
                          filterStatus === 'Sent'
                            ? {}
                            : {
                                transform: 'scale(1.12)'
                              }
                        }
                      >
                        <FaPaperPlane
                          size={filterStatus === 'Sent' ? 24 : 20}
                          color={filterStatus === 'Sent' ? '#4E89FC' : 'grey'}
                        />
                      </Flex>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            </Flex>

            <ModalAddAnalysis />
          </Flex>
          <TableAnalysis filterStatus={filterStatus} query={query} />
        </Flex>
      </Flex>
      <HeaderAnalysis />
    </HStack>
  )
}
