import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Flex,
  useDisclosure,
  Image,
  useToast
} from '@chakra-ui/react'
import { Button, InputModal } from 'Components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery } from 'react-query'
import { getSupplier, registerSupplier } from 'services/api/requests'

export const ModalAddSupplier = () => {
  const toast = useToast()

  const { refetch } = useQuery(['supplier'], getSupplier)

  const mutation = useMutation((data) => registerSupplier(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao cadastrar comercializadora.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Cadastro feito com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      onClose()
      refetch()
      resetForm()
    }
  })

  const { handleSubmit, values, handleChange, errors, resetForm } = useFormik({
    initialValues: {
      name: '',
      cnpj_supplier: ''
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .required('Nome da comercializadora é obrigatório.')
        .min(3, 'Nome deve conter mais de 3 caracteres.'),
      cnpj_supplier: Yup.string()
        .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'CNPJ inválido.')
        .required('CNPJ é obrigatório.')
    }),

    onSubmit: (data) => {
      mutation.mutate(data)
    }
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex>
      <Button.add
        justifyContent="space-between"
        rightIcon={
          <Image
            src="/img/IconAddSupplier.svg"
            alt="IconAddSupplier"
            w="22px"
            h="22px"
          />
        }
        onClick={onOpen}
      >
        Adicionar
      </Button.add>

      <Modal isOpen={isOpen} onClose={onClose} size={2}>
        <ModalOverlay />
        <ModalContent
          flexDir="column"
          w={['300px', '500px', '700px', '1000px']}
          justifyContent="center"
          borderRadius="8px"
          shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
        >
          <ModalHeader
            display="flex"
            flexDir="row"
            alignItems="flex-end"
            color="#F2920A"
          >
            <Image mr="12px" src="./img/IconSupplier.svg" alt="IconSupplier" />
            Cadastro Comercializadora
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
            >
              <FormControl w={['100%', '100%', '100%', '60%']}>
                <FormLabel
                  mb="0px"
                  fontSize="14px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Razão Social
                </FormLabel>
                <InputModal
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                />
              </FormControl>
              <FormControl
                mt={[4, 4, 4, 0]}
                w={['100%', '100%', '100%', '35%']}
              >
                <FormLabel
                  mb="0px"
                  fontSize="14px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  CNPJ
                </FormLabel>
                <InputModal.CNPJ
                  id="cnpj_supplier"
                  name="cnpj_supplier"
                  value={values.cnpj_supplier}
                  onChange={handleChange}
                  error={errors.cnpj_supplier}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={mutation.isLoading}
              onClick={handleSubmit}
              bg="#F2920A"
              color="#ffffff"
              h="34px"
              mr={3}
              _hover={{
                color: 'brand.background_home',
                bg: 'brand.background'
              }}
            >
              Adicionar
            </Button>
            <Button h="34px" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
