import React from 'react'
import { Flex, Image, FormControl } from '@chakra-ui/react'
import { Select, Button } from 'Components'
// import { ChatAITec } from 'modules/authenticated/screens'
import { useNavigate } from 'react-router-dom'

export const ButtonsMenu = ({ collapse }) => {
  const navigate = useNavigate()

  return (
    <Flex flexDir="column" mt="4">
      <Flex>
        <FormControl>
          <Select.area
            id="area"
            name="area"
            collapse={collapse}
            defaultValue="option2"
            isDisabled
          >
            <option value="option1">Área do Cliente</option>
            <option value="option2">Área Técnica</option>
            <option value="option3">Área Mercado Livre</option>
          </Select.area>
        </FormControl>
      </Flex>
      <Flex flexDir="column" alignItems="center" mt="10px">
        <Button.menu
          leftIcon={
            <Image
              src="/img/IconClientes.svg"
              alt="IconClientes"
              w="22px"
              h="22px"
              mr="36px"
            />
          }
          collapse={collapse}
          targetPath={'/clienttec'}
          onClick={() => {
            navigate('/clienttec')
          }}
        >
          {collapse ? null : 'Clientes'}
        </Button.menu>
        <Button.menu
          leftIcon={
            <Image
              src="/img/IconAnalise.svg"
              alt="IconAnalise"
              w="22px"
              h="22px"
              mr="36px"
            />
          }
          collapse={collapse}
          targetPath={'/analysis'}
          onClick={() => {
            navigate('/analysis')
          }}
        >
          {collapse ? null : 'Análise'}
        </Button.menu>
        <Button.menu
          targetPath={'/tariff'}
          onClick={() => navigate('/tariff')}
          collapse={collapse}
          leftIcon={
            <Image
              src="/img/IconTarifas.svg"
              alt="IconTarifas"
              w="22px"
              h="22px"
              mr="36px"
            />
          }
        >
          {collapse ? null : 'Tarifas'}
        </Button.menu>
        <Button.menu
          targetPath={'/tax'}
          onClick={() => navigate('/tax')}
          collapse={collapse}
          leftIcon={
            <Image
              src="/img/IconImpostos.svg"
              alt="IconImpostos"
              w="22px"
              h="22px"
              mr="36px"
            />
          }
        >
          {collapse ? null : 'Impostos'}
        </Button.menu>
        <Button.menu
          targetPath={'/flag'}
          onClick={() => navigate('/flag')}
          collapse={collapse}
          leftIcon={
            <Image
              src="/img/IconBandeiras.svg"
              alt="IconBandeiras"
              w="22px"
              h="22px"
              mr="36px"
            />
          }
        >
          {collapse ? null : 'Bandeiras'}
        </Button.menu>
        <Button.menu
          isDisabled
          targetPath={'/estudos'}
          onClick={() => {
            navigate('/estudos')
          }}
          collapse={collapse}
          leftIcon={
            <Image
              src="/img/IconEstudos.svg"
              alt="IconEstudos"
              w="22px"
              h="22px"
              mr="36px"
            />
          }
        >
          {collapse ? null : 'Estudos'}
        </Button.menu>
        <Button.menu
          targetPath={'/suppliers'}
          onClick={() => navigate('/suppliers')}
          collapse={collapse}
          leftIcon={
            <Image
              src="/img/IconSupplier.svg"
              alt="IconSupplier"
              w="22px"
              h="22px"
              mr="36px"
            />
          }
        >
          {collapse ? null : 'Fornecedores'}
        </Button.menu>
        <Button.menu
          isDisabled
          targetPath={'/ChatAiTec'}
          onClick={() => navigate('/ChatAiTec')}
          collapse={collapse}
          leftIcon={
            <Image
              src="/img/IconChatAI.svg"
              alt="IconSenhas"
              w="22px"
              h="22px"
              mr="36px"
            />
          }
        >
          {collapse ? null : 'Chat AI'}
        </Button.menu>
      </Flex>
    </Flex>
  )
}
