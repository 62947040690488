import { Tbody } from '@chakra-ui/react'
import { HiringEnvironment } from 'Components'

export const ListHiringEnvironment = ({ data, onDelete }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <HiringEnvironment
            key={`hiringenvironment_${item.id}`}
            {...item}
            onDelete={onDelete}
          />
        ))}
    </Tbody>
  )
}
