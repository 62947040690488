import { Tbody } from '@chakra-ui/react'
import { DistributorTax } from 'Components'

export const ListDistributorTax = ({ data }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <DistributorTax key={`distributor_${item.id}`} {...item} />
        ))}
    </Tbody>
  )
}
