import {
  Flex,
  FormControl,
  FormLabel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { InputModal, Text, ListSelect } from 'Components'
import {
  getAnalysisById,
  getDistributor,
  getSupplier,
  getVoltageClass,
  getTariffModality,
  getHiringEnvironment
} from 'services/api/requests'

export const InitialInformation = ({
  id,
  values,
  handleChange,
  setFieldValue,
  errors,
  isGreen,
  isRegulated,
  isConventional
}) => {
  const { data: distributor } = useQuery('distributor', getDistributor)
  const distributorData = distributor?.data || []

  const { data: supplier } = useQuery('supplier', getSupplier)
  const supplierData = supplier?.data || []

  const { data: voltageclass } = useQuery('voltageclass', getVoltageClass)
  const voltageclassData = voltageclass?.data || []

  const { data: tariffmodality } = useQuery('tariffmodality', getTariffModality)
  const tariffmodalityData = tariffmodality?.data || []

  const { data: hiringenvironment } = useQuery(
    'hiringenvironment',
    getHiringEnvironment
  )
  const hiringenvironmentData = hiringenvironment?.data || []

  const { data: analysis } = useQuery(
    ['analysis', id],
    () => getAnalysisById(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const analysisData = analysis?.data

  return (
    <Flex flexDir="column">
      <Accordion
        w="100%"
        allowMultiple
        border="2px"
        borderColor="brand.gray"
        borderRadius="8px"
      >
        <AccordionItem border="none">
          <Flex>
            <AccordionButton>
              <Flex as="span" flex="1" textAlign="left">
                <Text.Header
                  fontSize="13px"
                  fontWeight="semibold"
                  color="brand.background_home"
                  ml="0px"
                >
                  Dados da Unidade
                </Text.Header>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </Flex>
          <AccordionPanel>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <FormControl w={['100%', '100%', '100%', '28%']} isReadOnly>
                <FormLabel
                  htmlFor="name"
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Nome da Unidade
                </FormLabel>
                <InputModal
                  id="name"
                  name="name"
                  value={analysisData?.unit?.name}
                />
              </FormControl>
              <FormControl
                w={['100%', '100%', '100%', '20%']}
                mt={[4, 4, 4, 0]}
                isReadOnly
              >
                <FormLabel
                  htmlFor="consumer_unit"
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Unidade Consumidora
                </FormLabel>
                <InputModal
                  id="consumer_unit"
                  name="consumer_unit"
                  value={analysisData?.unit?.consumer_unit}
                />
              </FormControl>

              <FormControl
                w={['100%', '100%', '100%', '20%']}
                mt={[4, 4, 4, 0]}
                isReadOnly
              >
                <FormLabel
                  htmlFor="cnpj_unit"
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  CNPJ da Unidade
                </FormLabel>
                <InputModal.CNPJ
                  id="cnpj_unit"
                  name="cnpj_unit"
                  value={analysisData?.unit?.cnpj_unit}
                />
              </FormControl>
              <FormControl
                w={['100%', '100%', '100%', '20%']}
                mt={[4, 4, 4, 0]}
                isReadOnly
              >
                <FormLabel
                  htmlFor="user"
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Gestor da Unidade
                </FormLabel>
                <InputModal
                  id="user"
                  name="user"
                  value={analysisData?.unit?.user?.name}
                />
              </FormControl>
            </Flex>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              mt={6}
            >
              <FormControl w={['100%', '100%', '100%', '18%']}>
                <FormLabel
                  htmlFor="distributor_id"
                  display="flex"
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Distribuidora
                </FormLabel>
                <ListSelect
                  id="distributor_id"
                  name="distributor_id"
                  value={values.distributor_id}
                  onChange={handleChange}
                  error={errors.distributor_id}
                  data={distributorData}
                />
              </FormControl>
              {!isRegulated && (
                <FormControl
                  w={['100%', '100%', '100%', '18%']}
                  mt={[4, 4, 4, 0]}
                >
                  <FormLabel
                    htmlFor="supplier_id"
                    display="flex"
                    justifyContent={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Comercializadora
                  </FormLabel>
                  <ListSelect
                    id="supplier_id"
                    name="supplier_id"
                    value={values.supplier_id}
                    onChange={handleChange}
                    error={errors.supplier_id}
                    data={supplierData}
                  />
                </FormControl>
              )}
              <FormControl
                w={['100%', '100%', '100%', '18%']}
                mt={[4, 4, 4, 0]}
              >
                <FormLabel
                  htmlFor="voltageclass_id"
                  display="flex"
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Classe de Tensão
                </FormLabel>
                <ListSelect
                  id="voltageclass_id"
                  name="voltageclass_id"
                  value={values.voltageclass_id}
                  onChange={handleChange}
                  error={errors.voltageclass_id}
                  data={voltageclassData}
                />
              </FormControl>
              <FormControl
                w={['100%', '100%', '100%', '18%']}
                mt={[4, 4, 4, 0]}
              >
                <FormLabel
                  htmlFor="tariffmodality_id"
                  display="flex"
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Modalidade Tarifária
                </FormLabel>
                <ListSelect
                  id="tariffmodality_id"
                  name="tariffmodality_id"
                  value={values.tariffmodality_id}
                  onChange={handleChange}
                  error={errors.tariffmodality_id}
                  data={tariffmodalityData}
                />
              </FormControl>
              <FormControl
                w={['100%', '100%', '100%', '18%']}
                mt={[4, 4, 4, 0]}
              >
                <FormLabel
                  htmlFor="hiringenvironment_id"
                  display="flex"
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Ambiente de Contratação
                </FormLabel>
                <ListSelect
                  id="hiringenvironment_id"
                  name="hiringenvironment_id"
                  value={values.hiringenvironment_id}
                  onChange={handleChange}
                  error={errors.hiringenvironment_id}
                  data={hiringenvironmentData}
                />
              </FormControl>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
        mt={[4, 4, 4, 4]}
      >
        <FormControl w={['100%', '100%', '100%', '15%']} mt={4}>
          {!isConventional && (
            <>
              <FormLabel
                htmlFor="offpeakdemandcontract"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                {isGreen
                  ? 'Demanda Contratada (kW)'
                  : 'Demanda Contratada F.P. (kW)'}
              </FormLabel>
              <InputModal
                id="offpeakdemandcontract"
                name="offpeakdemandcontract"
                value={values.offpeakdemandcontract}
                onChange={handleChange}
                error={errors.offpeakdemandcontract}
                placeholder="0 kW"
                type="number"
                textAlign="center"
              />
            </>
          )}
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          {!isConventional && !isGreen && (
            <>
              <FormLabel
                htmlFor="peakdemandcontract"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Demanda Contratada P. (kW)
              </FormLabel>
              <InputModal
                id="peakdemandcontract"
                name="peakdemandcontract"
                value={values.peakdemandcontract}
                onChange={handleChange}
                error={errors.peakdemandcontract}
                placeholder="0 kW"
                type="number"
                textAlign="center"
              />
            </>
          )}
        </FormControl>
        <FormControl
          w={['100%', '100%', '100%', '15%']}
          mt={[4, 4, 4, 0]}
        ></FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="start_date"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Início da Leitura
          </FormLabel>
          <InputModal
            id="start_date"
            name="start_date"
            value={values.start_date}
            onChange={handleChange}
            error={errors.start_date}
            type="date"
            justifyContent="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="end_date"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Fim da Leitura
          </FormLabel>
          <InputModal
            id="end_date"
            name="end_date"
            value={values.end_date}
            onChange={handleChange}
            error={errors.end_date}
            type="date"
            justifyContent="center"
          />
        </FormControl>

        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="total_cost"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor Total da Fatura (R$)
          </FormLabel>
          <InputModal.Currency
            id="total_cost"
            name="total_cost"
            value={values.total_cost}
            onChange={(val) => setFieldValue('total_cost', val)}
            error={errors.total_cost}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
      </Flex>

      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="flex-end"
        alignItems="center"
        mt={4}
      >
        {!isRegulated && (
          <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="energydiscount"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Desconto Energia (%)
            </FormLabel>
            <InputModal.Currency
              id="energydiscount"
              name="energydiscount"
              value={values.energydiscount}
              onChange={(val) => setFieldValue('energydiscount', val)}
              error={errors.energydiscount}
              placeholder="0%"
              textAlign="center"
            />
          </FormControl>
        )}
      </Flex>
    </Flex>
  )
}
