import { api } from 'services/api'

export const registerSupplier = (data) =>
  api.post('/supplier', data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getSupplier = () =>
  api.get('/supplier', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const deleteSupplier = (id) =>
  api.delete(`/supplier/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const updateSupplier = (id, data) =>
  api.put(`/supplier/${id}`, data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })
