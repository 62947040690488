import {
  Flex,
  FormControl,
  FormLabel,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import { InputModal } from 'Components'

export const EnergySection = ({
  values,
  handleChange,
  setFieldValue,
  errors
}) => {
  return (
    <Flex flexDir="column">
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Energia
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="energyconsumption"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Consumo de Energia (kW/h)
          </FormLabel>
          <InputModal.Currency
            id="energyconsumption"
            name="energyconsumption"
            value={values.energyconsumption}
            onChange={(val) => setFieldValue('energyconsumption', val)}
            error={errors.energyconsumption}
            placeholder="0 kWh"
            textAlign="center"
            decimalScale={2}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="priceenergyconsumption"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Preço da Energia (R$/kWh)
          </FormLabel>
          <InputModal.Currency
            id="priceenergyconsumption"
            name="priceenergyconsumption"
            value={values.priceenergyconsumption}
            onChange={(val) => setFieldValue('priceenergyconsumption', val)}
            error={errors.priceenergyconsumption}
            placeholder="R$/kWh 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="total_cost_energyconsumption"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor Total (R$)
          </FormLabel>
          <InputModal.Currency
            id="total_cost_energyconsumption"
            name="total_cost_energyconsumption"
            value={values.total_cost_energyconsumption}
            onChange={(val) =>
              setFieldValue('total_cost_energyconsumption', val)
            }
            error={errors.total_cost_energyconsumption}
            placeholder="R$ 0,00"
            textAlign="center"
            decimalScale={2}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
          <FormLabel
            htmlFor="surplusenergy"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Energia Curto Prazo (kW/h)
          </FormLabel>
          <InputModal.Currency
            id="surplusenergy"
            name="surplusenergy"
            value={values.surplusenergy}
            onChange={(val) => setFieldValue('surplusenergy', val)}
            error={errors.surplusenergy}
            placeholder="0 kWh"
            textAlign="center"
            decimalScale={2}
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
          <FormLabel
            htmlFor="pricesurplusenergy"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Preço da Energia (R$/kWh)
          </FormLabel>
          <InputModal.Currency
            id="pricesurplusenergy"
            name="pricesurplusenergy"
            value={values.pricesurplusenergy}
            onChange={(val) => setFieldValue('pricesurplusenergy', val)}
            error={errors.pricesurplusenergy}
            placeholder="R$/kWh 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
          <FormLabel
            htmlFor="total_cost_surplusenergy"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor Total (R$)
          </FormLabel>
          <InputModal.Currency
            id="total_cost_surplusenergy"
            name="total_cost_surplusenergy"
            value={values.total_cost_surplusenergy}
            onChange={(val) => setFieldValue('total_cost_surplusenergy', val)}
            error={errors.total_cost_surplusenergy}
            placeholder="R$ 0,00"
            textAlign="center"
            decimalScale={2}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
          <FormLabel
            htmlFor="energyassignment"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Cessão de Energia (kW/h)
          </FormLabel>
          <InputModal.Currency
            id="energyassignment"
            name="energyassignment"
            value={values.energyassignment}
            onChange={(val) => setFieldValue('energyassignment', val)}
            error={errors.energyassignment}
            placeholder="0 kWh"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
          <FormLabel
            htmlFor="priceenergyassignment"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Preço da Energia (R$/kWh)
          </FormLabel>
          <InputModal.Currency
            id="priceenergyassignment"
            name="priceenergyassignment"
            value={values.priceenergyassignment}
            onChange={(val) => setFieldValue('priceenergyassignment', val)}
            error={errors.priceenergyassignment}
            placeholder="R$/kWh 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '30%']} mt={[4, 4, 4, 5]}>
          <FormLabel
            htmlFor="total_cost_energyassignment"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor Total (R$)
          </FormLabel>
          <InputModal.Currency
            id="total_cost_energyassignment"
            name="total_cost_energyassignment"
            value={values.total_cost_energyassignment}
            onChange={(val) =>
              setFieldValue('total_cost_energyassignment', val)
            }
            error={errors.total_cost_energyassignment}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
      </Flex>
    </Flex>
  )
}
