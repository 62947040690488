import { Flex, Text } from '@chakra-ui/react'
import { FaCircleCheck, FaCircleExclamation } from 'react-icons/fa6'
import { FaPaperPlane } from 'react-icons/fa'

export const Status = ({ status }) => {
  // Mapeia o status para o texto e a cor do Badge
  const statusMap = {
    Completed: { text: 'Concluída', color: '#99D44D', icon: FaCircleCheck },
    Divergent: {
      text: 'Divergente',
      color: '#D95D5D',
      icon: FaCircleExclamation
    },
    Sent: { text: 'Enviado', color: '#4E89FC', icon: FaPaperPlane }
  }

  // Define o status padrão caso o status não seja encontrado no mapa
  const {
    text,
    color,
    icon: Icon
  } = statusMap[status] || {
    text: 'Desconhecido',
    color: 'gray',
    icon: FaCircleExclamation // Ícone padrão para status desconhecido
  }

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      backgroundColor="brand.background"
      borderRadius="8px"
      padding={4}
    >
      <Icon size={30} color={color} />
      <Text fontWeight="semibold" color="brand.background_home" mt={2}>
        {text}
      </Text>
    </Flex>
  )
}
