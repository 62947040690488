import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Flex,
  useDisclosure,
  Image,
  Checkbox,
  useToast
} from '@chakra-ui/react'
import { Button, InputModal } from 'Components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery } from 'react-query'
import { getCompany, registerCompany } from 'services/api/requests'

export const ModalAddCompany = () => {
  const toast = useToast()

  const { refetch } = useQuery(['company'], getCompany)

  const mutation = useMutation((data) => registerCompany(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao cadastrar empresa.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Cadastro feito com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      onClose()
      refetch()
      resetForm()
    }
  })

  const { handleSubmit, values, handleChange, errors, resetForm } = useFormik({
    initialValues: {
      name: '',
      cnpj_company: '',
      commercial_phone: '',
      commercial_email: '',
      contract_start: '',
      contract_end: '',
      logo_url: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Nome da empresa é obrigatório.')
        .min(3, 'Nome deve conter mais de 3 caracteres.'),
      cnpj_company: Yup.string()
        .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'CNPJ inválido.')
        .required('CNPJ é obrigatório.'),
      commercial_phone: Yup.string(),
      commercial_email: Yup.string().email('E-mail Inválido.'),
      contract_start: Yup.string().required('Data do contrato é obrigatória.'),
      contract_end: Yup.string().required('Data do contrato é obrigatória.'),
      logo_url: Yup.string().url('A logo deve ser no formato URL.')
    }),

    onSubmit: (data) => {
      mutation.mutate(data)
    }
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex>
      <Button.add
        mb={['10px', '10px', '0px', '0px']}
        justifyContent="space-between"
        rightIcon={
          <Image
            src="/img/IconAddEmpresa.svg"
            alt="IconAddEmpresa"
            w="22px"
            h="22px"
          />
        }
        onClick={onOpen}
      >
        Adicionar
      </Button.add>

      <Modal isOpen={isOpen} onClose={onClose} size={2}>
        <ModalOverlay />
        <ModalContent
          flexDir="column"
          w={['300px', '500px', '700px', '1000px']}
          justifyContent="center"
          borderRadius="8px"
          shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
        >
          <ModalHeader
            display="flex"
            flexDir="row"
            alignItems="flex-end"
            color="#F2920A"
          >
            <Image
              mr="12px"
              src="./img/IconAddEmpresaO.svg"
              alt="IconAddEmpresa"
            />
            Cadastro Empresa
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <FormControl>
                <FormLabel
                  mb="0px"
                  fontSize="14px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Razão Social
                </FormLabel>
                <InputModal
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                  w="100%"
                />
              </FormControl>
            </Flex>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
            >
              <Flex>
                <FormControl mt={4}>
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    CNPJ
                  </FormLabel>
                  <InputModal.CNPJ
                    id="cnpj_company"
                    name="cnpj_company"
                    value={values.cnpj_company}
                    onChange={handleChange}
                    error={errors.cnpj_company}
                    w={['100%', '100%', '100%', '296px']}
                  />
                </FormControl>
              </Flex>
              <Flex>
                <FormControl mt={4}>
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    E-mail Comercial
                  </FormLabel>
                  <InputModal
                    id="commercial_email"
                    name="commercial_email"
                    value={values.commercial_email}
                    onChange={handleChange}
                    error={errors.commercial_email}
                    w={['100%', '100%', '100%', '348px']}
                  />
                </FormControl>
              </Flex>
              <Flex>
                <FormControl mt={4}>
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Telefone Comercial
                  </FormLabel>
                  <InputModal.Number
                    id="commercial_phone"
                    name="commercial_phone"
                    value={values.commercial_phone}
                    onChange={handleChange}
                    error={errors.commercial_phone}
                    w={['100%', '100%', '100%', '248px']}
                    type="phone"
                  />
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
            >
              <Flex
                w={['100%', '100%', '100%', '60%']}
                flexDir={['column', 'column', 'row', 'row']}
                justifyContent="space-between"
              >
                <FormControl mt={4}>
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Início do Contrato
                  </FormLabel>
                  <InputModal
                    id="contract_start"
                    name="contract_start"
                    value={values.contract_start}
                    onChange={handleChange}
                    error={errors.contract_start}
                    w="236px"
                    type="date"
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Fim do Contrato
                  </FormLabel>
                  <InputModal
                    id="contract_end"
                    name="contract_end"
                    value={values.contract_end}
                    onChange={handleChange}
                    error={errors.contract_end}
                    w="236px"
                    type="date"
                  />
                </FormControl>
              </Flex>
              <Flex
                w={['100%', '100%', '40%', '40%']}
                alignItems="flex-end"
                justifyContent="flex-start"
              >
                <Checkbox
                  id="renovation"
                  name="renovation"
                  isChecked={values.renovation}
                  onChange={handleChange}
                  error={errors.renovation}
                  mb="0px"
                  fontSize="14px"
                  fontWeight="semibold"
                  color="#303030"
                  alignItems="center"
                  colorScheme="green"
                >
                  Renovação Automática
                </Checkbox>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={mutation.isLoading}
              onClick={handleSubmit}
              bg="#F2920A"
              color="#ffffff"
              h="34px"
              mr={3}
              _hover={{
                color: 'brand.background_home',
                bg: 'brand.background'
              }}
            >
              Adicionar
            </Button>
            <Button h="34px" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
