import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { unauthRoutes } from 'modules/unauthenticated/routes'
import { authRoutes } from 'modules/authenticated/routes'
import { useAuth } from './authProvider'
import { LoadingScreen } from 'Components'

const AppRoutes = () => {
  const { isAuthenticated, isAuthLoading } = useAuth()

  if (isAuthLoading) {
    return <LoadingScreen />
  }

  const routes = isAuthenticated
    ? authRoutes
    : authRoutes.map((route) => ({
        ...route,
        element: <Navigate to="/" replace={true} />
      }))

  const router = createBrowserRouter([...unauthRoutes, ...routes])

  return <RouterProvider router={router} />
}

export default AppRoutes
