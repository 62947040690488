import { Tbody } from '@chakra-ui/react'
import { TariffFlag } from 'Components'
import moment from 'moment'

export const ListTariffFlag = ({ data, onDelete }) => {
  const sortedData = data
    ? data.sort((a, b) =>
        moment(b.DatCompetencia).diff(moment(a.DatCompetencia))
      )
    : []

  return (
    <Tbody>
      {sortedData.map((item) => (
        <TariffFlag
          key={`tariffflag_${item._id}`}
          {...item}
          onDelete={onDelete}
        />
      ))}
    </Tbody>
  )
}
