import React from 'react'
import moment from 'moment'
import 'moment/locale/pt'
import { Td, Tr, Flex, Text } from '@chakra-ui/react'
import { FaCircleCheck, FaCircleExclamation } from 'react-icons/fa6'
import { FaPaperPlane } from 'react-icons/fa'

import { MenuMoreAnalysis, ModalDeleteAnalysis } from 'Components'

function convertToNumber(value) {
  if (typeof value === 'string' && value.trim() !== '' && !isNaN(value)) {
    return parseFloat(value)
  }
  if (typeof value === 'number') {
    return value
  }
  return undefined
}
moment.locale('pt')

export const Analysis = ({
  id,
  unit_id,
  unit,
  user_id,
  distributor_id,
  hiringenvironment,
  voltageclass_id,
  tariffmodality_id,
  reference_month,
  start_date,
  end_date,
  total_cost,
  offpeakdemandcontract,
  peakdemandcontract,
  offpeakdemand,
  peakdemand,
  offpeaksurplus_reactive_demand,
  peaksurplus_reactive_demand,
  offpeakconsumption,
  peakconsumption,
  offpeak_surplus_reactive_power,
  peak_surplus_reactive_power,
  energyconsumption,
  priceenergyconsumption,
  total_cost_energyconsumption,
  energyassignment,
  priceenergyassignment,
  total_cost_energyassignment,
  surplusenergy,
  pricesurplusenergy,
  total_cost_surplusenergy,
  offpeaktaxsubstitution,
  peaktaxsubstitution,
  energydeduction,
  grosstariffsubsidy,
  nettariffsubsidy,
  associationfee,
  financialsettlement,
  reserveenergycharges,
  energydiscount,
  public_lighting,
  fines,
  fees,
  billingadjustment,
  chargesAndRefunds,
  supplementary_demand,
  others,
  devec,
  noICMSinTUSD,
  noICMSinDemand,
  noICMSinConsumptionTUSD,
  hasICMSinDemandUnused,
  hasCovidAccount,
  hasWaterScarcityFee,
  isSeasonality,
  isTestPeriod,
  cycleInTestPeriod,
  reasonTestPeriod,
  isTestPeriodPeak,
  cycleInTestPeriodPeak,
  reasonTestPeriodPeak,
  unused_offpeakdemand,
  unused_peakdemand,
  offpeakdemand_overrun,
  peakdemand_overrun,
  valueOffPeakDemand,
  valuePeakDemand,
  valueUnused_offpeakdemand,
  valueUnused_peakdemand,
  valueOffPeakDemand_overrun,
  valuePeakDemand_overrun,
  valueOffPeakSurplus_reactive_demand,
  valuePeakSurplus_reactive_demand,
  valueOffpeakconsumption_te,
  valueOffpeakconsumption_tusd,
  valuePeakconsumption_te,
  valuePeakconsumption_tusd,
  valueTotalConsumption,
  valueOffpeak_surplus_reactive_power,
  valuePeak_surplus_reactive_power,
  valueEnergyconsumption,
  valueEnergyassignment,
  valueSurplusEnergy,
  valueICMSEnergy,
  valueTariffsubsidy,
  valueCovidaccount,
  valueWaterscarcityfee,
  valueFlag,
  valueICMS,
  valuePIS,
  valueCOFINS,
  valueTotalEnergy,
  valueTotalInvoice,
  valueTotalCharges,
  valueTotal,
  valueDivergence,
  valueDivergenceEnergy,
  status,
  onDelete
}) => {
  const [showModalDelete, setShowModalDelete] = React.useState()

  const onCloseModalDelete = () => {
    setShowModalDelete(null)
  }

  const handleDelete = () => {
    onDelete(id)
    onCloseModalDelete()
  }

  const formattedStartDate = moment.utc(start_date).format('DD/MM/YYYY')
  const formattedEndDate = moment.utc(end_date).format('DD/MM/YYYY')

  const findMajorityMonth = (startDate, endDate) => {
    const start = moment.utc(startDate)
    const end = moment.utc(endDate)

    const monthDays = {}

    const current = start.clone()

    while (current.isSameOrBefore(end, 'day')) {
      const monthYear = current.format('MMM/YY')
      if (!monthDays[monthYear]) {
        monthDays[monthYear] = 0
      }
      monthDays[monthYear]++
      current.add(1, 'day')
    }

    let maxDays = 0
    let majorityMonth = ''

    for (const monthYear in monthDays) {
      if (monthDays[monthYear] > maxDays) {
        maxDays = monthDays[monthYear]
        majorityMonth = monthYear
      }
    }

    return majorityMonth
  }

  const majorityMonth = findMajorityMonth(start_date, end_date)

  const formatValue = (value) => {
    if (typeof value === 'number' && !isNaN(value)) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    }
    return 'R$ 0,00' // Valor padrão caso o valor seja `undefined` ou inválido
  }

  const StatusBadge = (status) => {
    // Mapeia o status para o texto e a cor do Badge
    const statusMap = {
      Completed: { text: 'Concluída', color: '#99D44D', icon: FaCircleCheck },
      Divergent: {
        text: 'Divergente',
        color: '#D95D5D',
        icon: FaCircleExclamation
      },
      Sent: { text: 'Enviado', color: '#4E89FC', icon: FaPaperPlane }
    }

    // Define o status padrão caso o status não seja encontrado no mapa
    const {
      text,
      color,
      icon: Icon
    } = statusMap[status] || {
      text: 'Desconhecido',
      color: 'gray',
      icon: FaCircleExclamation // Ícone padrão para status desconhecido
    }

    return (
      <Flex flexDir="row" alignItems="center">
        <Icon size={20} color={color} />
        <Text fontWeight="semibold" ml={2}>
          {text}
        </Text>
      </Flex>
    )
  }

  return (
    <Tr>
      <Td>{majorityMonth}</Td>
      <Td>{unit?.consumer_unit}</Td>
      <Td>{unit?.name}</Td>
      <Td>{formattedStartDate}</Td>
      <Td>{formattedEndDate}</Td>
      <Td>{formatValue(convertToNumber(valueTotal))}</Td>
      <Td>{formatValue(convertToNumber(valueDivergence))}</Td>
      <Td>{StatusBadge(status)}</Td>
      <Td display="flex" flexDir="row" justifyContent="space-between">
        <MenuMoreAnalysis
          setShowModalDelete={setShowModalDelete}
          id={id}
          status={status}
        />

        {showModalDelete === 'analysis' && (
          <ModalDeleteAnalysis
            onDelete={handleDelete}
            onClose={onCloseModalDelete}
          />
        )}
      </Td>
    </Tr>
  )
}
