import React from 'react'
import { Flex } from '@chakra-ui/react'
import { Button } from 'Components'

export const MenuEditAnalysis = ({
  selectedButton,
  onSelectButton,
  isConventional,
  isRegulated
}) => {
  return (
    <Flex
      w="100%"
      mt={8}
      flexDir={['column', 'column', 'column', isRegulated ? 'column' : 'row']}
      justifyContent="center"
    >
      <Flex
        w={['100%', '100%', '100%', isRegulated ? '100% ' : '50%']}
        justifyContent={[
          'center',
          'center',
          'center',
          isRegulated ? 'center' : 'flex-end'
        ]}
      >
        <Button
          _hover={{ transform: 'scale(1.05)' }}
          bg={selectedButton === 'summary' ? '#383838' : 'brand.background'}
          color={selectedButton === 'summary' ? 'brand.background' : '#383838'}
          w="128px"
          h="36px"
          fontSize="13px"
          onClick={() => onSelectButton('summary')}
        >
          Resumo
        </Button>
        {!isConventional && (
          <Button
            _hover={{ transform: 'scale(1.05)' }}
            bg={selectedButton === 'demand' ? '#383838' : 'brand.background'}
            color={selectedButton === 'demand' ? 'brand.background' : '#383838'}
            w="128px"
            h="36px"
            ml={4}
            fontSize="13px"
            onClick={() => onSelectButton('demand')}
          >
            Demanda
          </Button>
        )}
        <Button
          _hover={{ transform: 'scale(1.05)' }}
          bg={selectedButton === 'consumption' ? '#383838' : 'brand.background'}
          color={
            selectedButton === 'consumption' ? 'brand.background' : '#383838'
          }
          w="128px"
          h="36px"
          ml={4}
          fontSize="13px"
          onClick={() => onSelectButton('consumption')}
        >
          Consumo
        </Button>
        <Button
          _hover={{ transform: 'scale(1.05)' }}
          bg={selectedButton === 'releases' ? '#383838' : 'brand.background'}
          color={selectedButton === 'releases' ? 'brand.background' : '#383838'}
          w="128px"
          h="36px"
          ml={4}
          fontSize="13px"
          onClick={() => onSelectButton('releases')}
        >
          Lançamentos
        </Button>
      </Flex>
      <Flex
        mt={[4, 4, 4, isRegulated ? 4 : 0]}
        w={['100%', '100%', '100%', isRegulated ? '100% ' : '50%']}
        justifyContent={[
          'center',
          'center',
          'center',
          isRegulated ? 'center' : 'flex-start'
        ]}
      >
        {!isRegulated && (
          <Button
            _hover={{ transform: 'scale(1.05)' }}
            bg={selectedButton === 'taxation' ? '#383838' : 'brand.background'}
            color={
              selectedButton === 'taxation' ? 'brand.background' : '#383838'
            }
            w="128px"
            h="36px"
            ml={[0, 0, 0, 4]}
            fontSize="13px"
            onClick={() => onSelectButton('taxation')}
          >
            Tributação
          </Button>
        )}
        {!isRegulated && (
          <Button
            _hover={{ transform: 'scale(1.05)' }}
            bg={selectedButton === 'energy' ? '#383838' : 'brand.background'}
            color={selectedButton === 'energy' ? 'brand.background' : '#383838'}
            w="128px"
            h="36px"
            ml={4}
            fontSize="13px"
            onClick={() => onSelectButton('energy')}
          >
            Energia
          </Button>
        )}
        {!isRegulated && (
          <Button
            _hover={{ transform: 'scale(1.05)' }}
            bg={selectedButton === 'charges' ? '#383838' : 'brand.background'}
            color={
              selectedButton === 'charges' ? 'brand.background' : '#383838'
            }
            w="128px"
            h="36px"
            ml={4}
            fontSize="13px"
            onClick={() => onSelectButton('charges')}
          >
            Encargos
          </Button>
        )}
        {!isConventional && (
          <Button
            _hover={{ transform: 'scale(1.05)' }}
            bg={selectedButton === 'savings' ? '#383838' : 'brand.background'}
            color={
              selectedButton === 'savings' ? 'brand.background' : '#383838'
            }
            w="128px"
            h="36px"
            ml={4}
            fontSize="13px"
            onClick={() => onSelectButton('savings')}
          >
            Economia
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
