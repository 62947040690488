import { Tbody } from '@chakra-ui/react'
import { Tax } from 'Components'

export const ListTax = ({ data, onDelete }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <Tax key={`tax_${item.id}`} {...item} onDelete={onDelete} />
        ))}
    </Tbody>
  )
}
