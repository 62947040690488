import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Flex,
  HStack,
  IconButton,
  Image,
  RadioGroup,
  Radio
} from '@chakra-ui/react'
import {
  HeaderSetHiringEnvironment,
  HeaderSetTariffModality,
  HeaderSetVoltageClass,
  Menu,
  ModalAddVoltageClass,
  TableVoltageClass,
  ModalAddTariffModality,
  TableTariffModality,
  TableHiringEnvironment,
  ModalAddHiringEnvironment,
  Button
} from 'Components'

export const TariffSettings = () => {
  const navigate = useNavigate()

  const [collapse, setCollapse] = React.useState(false)

  const [showSettings, setShowSettings] = React.useState('tariffmodality')

  const toggleCollapse = () => {
    setCollapse(!collapse)
  }

  React.useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth >= 1200) {
        setCollapse(false)
      } else {
        setCollapse(true)
      }
    }

    checkScreenSize()

    window.addEventListener('resize', checkScreenSize)

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  return (
    <HStack w="full" h="100vh" bg="brand.background_home" padding="8px">
      <Flex
        as="aside"
        w="full"
        h="full"
        maxW={collapse ? '60px' : '250px'}
        transition="0.6s"
        bg="brand.background_home"
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between"
        borderRadius="8px"
        overflowX="hidden"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'white',
            border: '1px solid gray'
          }
        }}
      >
        <Menu collapse={collapse} />
      </Flex>
      <Flex
        as="main"
        w="full"
        h="full"
        bg="brand.background"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        position="relative"
        borderRadius="8px"
        overflowX="hidden"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'white',
            border: '1px solid gray'
          }
        }}
      >
        <Flex w="96%" h="100%" flexDir="column">
          <Flex w="100%" h="120px">
            <IconButton
              bg="none"
              aria-label="Menu Collapse"
              onClick={toggleCollapse}
              transform={collapse ? 'rotate(-180deg)' : 'none'}
              transition="0.6s"
              position="absolute"
              top={2}
              left={2}
              icon={
                <Image
                  src="/img/IconArrow.svg"
                  alt="IconArrow"
                  w="34px"
                  h="34px"
                />
              }
              _hover={{
                bg: 'none'
              }}
            />{' '}
          </Flex>
          <Flex
            flexDir={['column', 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button.add
              mr={['0px', '10px', '10px', '10px']}
              mb={['10px', '10px', '0px', '0px']}
              w="40px"
              bg="#383838"
              color="#EAEBF0"
              justifyContent="center"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon
                src="/img/IconAddEmpresa.svg"
                alt="IconAddEmpresa"
                w="20px"
                h="20px"
              />
            </Button.add>
            {showSettings === 'hiringenvironment' && (
              <ModalAddHiringEnvironment />
            )}
            {showSettings === 'tariffmodality' && <ModalAddTariffModality />}
            {showSettings === 'voltageclass' && <ModalAddVoltageClass />}
          </Flex>

          <Flex
            display="flex"
            mt="14px"
            h="75%"
            w="100%"
            mb="16px"
            justifyContent="space-around"
            overflowX="auto"
            overflowY="auto"
            css={{
              '::-webkit-scrollbar': {
                width: '4px',
                height: '4px'
              },
              '::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                background: 'gray'
              },
              '::-webkit-scrollbar-track': {
                borderRadius: '4px',
                background: 'white',
                border: '1px solid gray'
              }
            }}
          >
            <Flex mt="14px">
              <RadioGroup
                colorScheme="orange"
                onChange={setShowSettings}
                value={showSettings}
              >
                <Flex direction={['column', 'column', 'column', 'row']}>
                  <Flex
                    w="300px"
                    bg="#ffffff"
                    borderRadius="8px"
                    shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
                    flexDir="column"
                    alignItems="center"
                    mt={
                      showSettings !== 'hiringenvironment'
                        ? ['8', '8', '8', '10']
                        : ['8', '8', '8', '0']
                    }
                    mb={
                      showSettings !== 'hiringenvironment'
                        ? ['8', '8', '8', '0']
                        : ['8', '8', '8', '10']
                    }
                    _hover={{
                      transform:
                        showSettings !== 'hiringenvironment'
                          ? 'scale(1.06)'
                          : 'none',
                      transition: '0.2s'
                    }}
                  >
                    <Radio mt="10px" value="hiringenvironment" />
                    <TableHiringEnvironment radio={showSettings} />
                  </Flex>

                  <Flex
                    w="300px"
                    bg="#ffffff"
                    borderRadius="8px"
                    shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
                    flexDir="column"
                    alignItems="center"
                    ml={[0, 0, 0, '100px']}
                    mr={[0, 0, 0, '100px']}
                    mt={
                      showSettings !== 'tariffmodality'
                        ? ['8', '8', '8', '10']
                        : ['8', '8', '8', '0']
                    }
                    mb={
                      showSettings !== 'tariffmodality'
                        ? ['8', '8', '8', '0']
                        : ['8', '8', '8', '10']
                    }
                    _hover={{
                      transform:
                        showSettings !== 'tariffmodality'
                          ? 'scale(1.06)'
                          : 'none',
                      transition: '0.2s'
                    }}
                  >
                    <Radio mt="10px" value="tariffmodality" />
                    <TableTariffModality radio={showSettings} />
                  </Flex>

                  <Flex
                    w="300px"
                    bg="#ffffff"
                    borderRadius="8px"
                    shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
                    flexDir="column"
                    alignItems="center"
                    mt={
                      showSettings !== 'voltageclass'
                        ? ['8', '8', '8', '10']
                        : ['8', '8', '8', '0']
                    }
                    mb={
                      showSettings !== 'voltageclass'
                        ? ['8', '8', '8', '0']
                        : ['8', '8', '8', '10']
                    }
                    _hover={{
                      transform:
                        showSettings !== 'voltageclass'
                          ? 'scale(1.06)'
                          : 'none',
                      transition: '0.2s'
                    }}
                  >
                    <Radio mt="10px" value="voltageclass" />
                    <TableVoltageClass radio={showSettings} />
                  </Flex>
                </Flex>
              </RadioGroup>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {showSettings === 'hiringenvironment' && <HeaderSetHiringEnvironment />}
      {showSettings === 'tariffmodality' && <HeaderSetTariffModality />}
      {showSettings === 'voltageclass' && <HeaderSetVoltageClass />}
    </HStack>
  )
}
