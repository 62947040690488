import { useRef } from 'react'
import {
  Flex,
  Avatar,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  Icon
} from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { MdModeEditOutline } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Text, InputUserModal, ChangePassword } from 'Components'
import { setUser } from 'services/store/slices/user'
import { useMutation, useQueryClient } from 'react-query'
import { updateUser, updateUserAvatar } from 'services/api/requests'

import { useFormik } from 'formik'
import * as Yup from 'yup'

export const UserModal = () => {
  const inputFileRef = useRef()
  const userStore = useSelector((state) => state.user)

  const queryClient = useQueryClient()

  const toast = useToast()

  const dispatch = useDispatch()

  const mutation = useMutation((data) => updateUser(data.id, data), {
    onError: (error) => {
      toast({
        title: 'Falha ao atualizar dados do usuário.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Dados atualizados com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
      dispatch(setUser({ user: data?.data }))
      queryClient.invalidateQueries(['user'])
    }
  })

  const mutationAvatar = useMutation((data) => updateUserAvatar(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao atualizar foto do usuário.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Foto do usuário atualizada com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
      dispatch(setUser({ user: data?.data }))
      queryClient.invalidateQueries(['user'])
    }
  })

  const { handleSubmit, values, handleChange, errors, dirty } = useFormik({
    initialValues: {
      usertype_id: userStore?.user?.usertype_id || '',
      name: userStore?.user?.name || '',
      email: userStore?.user?.email || '',
      jobtitle: userStore?.user?.jobtitle || '',
      education: userStore?.user?.education || '',
      phone: userStore?.user?.phone || ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      usertype_id: Yup.number(),
      name: Yup.string().min(3, 'Nome deve conter ao menos 3 caracteres.'),
      email: Yup.string().email('Email inválido.'),
      jobtitle: Yup.string().min(3, 'Deve conter ao menos 3 caracteres.'),
      education: Yup.string().min(3, 'Deve conter ao menos 3 caracteres.'),
      phone: Yup.string()
    }),

    onSubmit: (data) => {
      mutation.mutate({ id: userStore?.user?.id, ...data })
    }
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onChangeImage = async (event) => {
    const file = event?.target?.files[0]
    const type = file?.type

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64 = reader.result
      mutationAvatar.mutate({
        mime: type,
        base64
      })
    }
  }
  return (
    <Flex>
      <Button.perfilEdit
        onClick={onOpen}
        justifyContent="space-between"
        rightIcon={<EditIcon boxSize={3} />}
      >
        Editar
      </Button.perfilEdit>
      <Drawer size="sm" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          overflowX="hidden"
          overflowY="auto"
          css={{
            '::-webkit-scrollbar': {
              width: '4px',
              height: '4px'
            },
            '::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              background: 'gray'
            },
            '::-webkit-scrollbar-track': {
              borderRadius: '4px',
              background: 'white',
              border: '1px solid gray'
            }
          }}
        >
          <DrawerCloseButton />
          <DrawerHeader>
            <Text.Header
              fontSize={['16px', '16px', '16px', '19px']}
              color="brand.background_home"
              ml="0px"
            >
              Dados Pessoais
            </Text.Header>
          </DrawerHeader>

          <DrawerBody
            overflowX="hidden"
            overflowY="auto"
            css={{
              '::-webkit-scrollbar': {
                width: '4px',
                height: '4px'
              },
              '::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                background: 'gray'
              },
              '::-webkit-scrollbar-track': {
                borderRadius: '4px',
                background: 'white',
                border: '1px solid gray'
              }
            }}
          >
            <Flex
              w="100%"
              alignItems="center"
              justifyContent="center"
              flexDir="column"
            >
              <Flex>
                <input
                  ref={inputFileRef}
                  style={{ display: 'none' }}
                  onChange={onChangeImage}
                  type="file"
                  accept="image/*"
                />
                <Avatar
                  onClick={() => inputFileRef?.current?.click()}
                  boxSize="134px"
                  style={{ transition: '0.4s' }}
                  name={userStore?.user?.name}
                  src={userStore?.user?.avatar_url}
                  borderWidth="4px"
                  borderColor="brand.primary"
                  backgroundColor="brand.background"
                  shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
                  cursor="pointer"
                />
                <Flex
                  w="34px"
                  h="34px"
                  bg="brand.primary"
                  borderRadius="16px"
                  position="relative"
                  top="102px"
                  right="36px"
                  alignItems="center"
                  justifyContent="center"
                  shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
                >
                  <Icon
                    cursor="pointer"
                    onClick={() => inputFileRef?.current?.click()}
                    color="brand.background_home"
                    boxSize="18px"
                    as={MdModeEditOutline}
                  />
                </Flex>
              </Flex>
              <FormControl mt="8px">
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Nome
                </FormLabel>
                <InputUserModal
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                  w="100%"
                />
              </FormControl>
              <FormControl mt="8px">
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  E-mail Comercial
                </FormLabel>
                <InputUserModal
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email}
                  w="100%"
                />
              </FormControl>
              <FormControl mt="8px">
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Telefone Comercial
                </FormLabel>
                <InputUserModal.Number
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  error={errors.phone}
                  type="phone"
                  w="100%"
                />
              </FormControl>
              <FormControl mt="8px">
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Formação
                </FormLabel>
                <InputUserModal
                  id="education"
                  name="education"
                  value={values.education}
                  onChange={handleChange}
                  error={errors.education}
                  w="100%"
                />
              </FormControl>
              <FormControl mt="8px">
                <FormLabel
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Cargo
                </FormLabel>
                <InputUserModal
                  id="jobtitle"
                  name="jobtitle"
                  value={values.jobtitle}
                  onChange={handleChange}
                  error={errors.jobtitle}
                  w="100%"
                />
              </FormControl>
              <ChangePassword />
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Button fontSize="14px" mr={3} h="32px" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              isLoading={mutation.isLoading || mutationAvatar.isLoading}
              onClick={handleSubmit}
              isDisabled={!dirty}
              bg="#F2920A"
              color="#ffffff"
              fontSize="14px"
              h="32px"
              _hover={{
                color: 'brand.background_home',
                bg: 'brand.background'
              }}
            >
              Salvar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}
