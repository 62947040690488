import React from 'react'
import {
  Table,
  Thead,
  Tr,
  Th,
  TableContainer,
  Flex,
  useToast
} from '@chakra-ui/react'
import { getAnalysis, deleteAnalysis } from 'services/api/requests'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { ListAnalysis, Loader, EmptyMessage } from 'Components'

export const TableAnalysis = ({ query, filterStatus }) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(['analysis'], getAnalysis, {
    retry: 1,
    retryDelay: 1000
  })

  const deleteAnalysisMutation = useMutation((data) => deleteAnalysis(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao excluir análise.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: () => {
      toast({
        title: 'Análise excluída com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
      queryClient.removeQueries('analysis')
    }
  })
  const handleDelete = (data) => {
    deleteAnalysisMutation.mutate(data)
  }

  const filteredData = Array.isArray(data?.data)
    ? data?.data?.filter((analysis) => {
        const matchesQuery =
          analysis.unit.consumer_unit
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          analysis.unit.name.toLowerCase().includes(query.toLowerCase())

        const matchesStatus =
          filterStatus === 'all'
            ? analysis.status !== 'Sent' // Exclui as enviadas quando o filtro for 'all'
            : filterStatus === 'all' || analysis.status === filterStatus

        return matchesQuery && matchesStatus
      })
    : []

  if (isLoading) {
    return <Loader />
  }

  if (!filteredData || filteredData.length === 0) {
    return <EmptyMessage>Nenhuma análise encontrada.</EmptyMessage>
  }

  return (
    <Flex
      bg="#ffffff"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="75%"
      mb="16px"
    >
      <TableContainer
        w="100%"
        justifyContent="space-between"
        overflowX="auto"
        overflowY="auto"
        borderRadius="8px"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'null',
            border: 'null'
          }
        }}
      >
        <Table mb="8px" mt="8px" size="sm">
          <Thead position="sticky" top="0" zIndex="docked" bg="#ffffff">
            <Tr height="34px">
              <Th fontSize="12px">Referência</Th>
              <Th fontSize="12px">UC</Th>
              <Th fontSize="12px">Unidade</Th>
              <Th fontSize="12px">Início da Leitura</Th>
              <Th fontSize="12px">Fim da Leitura</Th>
              <Th fontSize="12px">Valor Total</Th>
              <Th fontSize="12px">Divergência</Th>
              <Th fontSize="12px">Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <ListAnalysis onDelete={handleDelete} data={filteredData} />
        </Table>
      </TableContainer>
    </Flex>
  )
}
