import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image
} from '@chakra-ui/react'
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'

export const MenuMoreTax = ({ setShowModalDelete, setShowModalEdit }) => {
  const buttonRef = React.useRef()

  return (
    <Menu initialFocusRef={buttonRef}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Image w="22px" h="22px" src="/img/IconMore.svg" />}
        variant="null"
        ref={buttonRef}
      />
      <MenuList>
        <MenuItem icon={<EditIcon />} onClick={() => setShowModalEdit('tax')}>
          Editar
        </MenuItem>
        <MenuItem
          icon={<DeleteIcon />}
          onClick={() => setShowModalDelete('tax')}
        >
          Excluir
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
