import React from 'react'
import { Flex, Image, FormControl, useToast } from '@chakra-ui/react'
import { Text, InputUnauth, Select, Button, Link } from 'Components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from 'react-query'
import { loginCall } from 'services/api/requests'
import { saveItem } from 'services/storage'
import { setAll } from 'services/store/slices/user'
import { useAuth } from 'router/authProvider'

export const LoginScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { login, isAuthenticated } = useAuth()
  const toast = useToast()
  const [isLoginPending, setIsLoginPending] = React.useState(false)

  const mutation = useMutation((login) => loginCall(login), {
    onError: (error) => {
      toast({
        title: 'Falha ao realizar login.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Login feito com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
      saveItem('@solfus_token', data?.data?.token)
      dispatch(setAll({ token: data?.data?.token, user: data?.data?.user }))
      login(data?.data?.token)
      setIsLoginPending(true)
    }
  })

  React.useEffect(() => {
    if (isAuthenticated && isLoginPending) {
      navigate('/hometec')
    }
  }, [isAuthenticated, isLoginPending, navigate])

  const { handleSubmit, values, handleChange, errors } = useFormik({
    initialValues: {
      usertype_id: '',
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      usertype_id: Yup.number().integer().required('Escolha seu perfil.'),
      email: Yup.string()
        .email('E-mail inválido.')
        .required('Digite seu e-mail.'),
      password: Yup.string()
        .min(6, 'Senha deve conter ao menos 6 caracteres.')
        .required('Digite sua senha.')
    }),
    onSubmit: (data) => {
      data.usertype_id = parseInt(data.usertype_id, 10)
      mutation.mutate(data)
    }
  })

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Flex
      flexDir="row"
      w="100vw"
      h="100vh"
      backgroundImage="url('/img/Background-login.svg')"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      opacity="0.85"
    >
      <Flex
        flexDir="column"
        w={['0%', '0%', '0%', '50%']}
        h={['0%', '0%', '0%', '100vh']}
      >
        <Image src="/img/Logo.svg" alt="Solfus Logo" w="206px" h="112px" />
        <Flex
          flexDir="row"
          alignItems="center"
          w={['0%', '0%', '0%', '100%']}
          h={['0%', '0%', '0%', '100%']}
          ml="20px"
          fontFamily="Mallanna"
          textShadow="0.2em 0.2em 0.8em black"
        >
          <Flex
            flexDir="column"
            w="10%"
            h="100%"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Image
              src="/img/Icon+.svg"
              alt="Icon Site"
              w="60px"
              h="60px"
              mt="70px"
            />
            <Image
              src="/img/Icon+.svg"
              alt="Icon Site"
              w="60px"
              h="60px"
              mt="110px"
            />
          </Flex>
          <Flex
            flexDir="column"
            w={['0%', '0%', '0%', '20%']}
            h={['0%', '0%', '0%', '100%']}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Text
              ml="30px"
              mt="70px"
              color="#ffffff"
              fontSize={['0px', '0px', '0px', '70px']}
            >
              35
            </Text>
            <Text
              ml="30px"
              mt="60px"
              color="#ffffff"
              fontSize={['0px', '0px', '0px', '70px']}
            >
              700
            </Text>
          </Flex>
          <Flex
            flexDir="column"
            w={['0%', '0%', '0%', '70%']}
            h={['0%', '0%', '0%', '100%']}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Flex flexDir="column">
              <Text
                mt="82px"
                color="#ffffff"
                fontSize={['0px', '0px', '0px', '50px']}
              >
                Anos
              </Text>
              <Flex ml="60px">
                <Text color="#ffffff" fontSize={['0px', '0px', '0px', '26px']}>
                  no mercado
                </Text>
              </Flex>
            </Flex>
            <Flex flexDir="column" ml="40px">
              <Text
                mt="50px"
                color="#ffffff"
                fontSize={['0px', '0px', '0px', '50px']}
              >
                Empresas
              </Text>
              <Flex ml="60px">
                <Text color="#ffffff" fontSize={['0px', '0px', '0px', '26px']}>
                  atendidas pelo Brasil
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDir="row"
          justifyContent="space-between"
          alignItems="flex-end"
          w={['100%', '100%', '100%', '20%']}
          h="100%"
          ml={['0px', '0px', '0px', '20px']}
          mb="20px"
        >
          <Link href="https://solfus.com.br/" target="_blank">
            <Image src="/img/IconSite.svg" alt="Icon Site" w="30px" h="30px" />
          </Link>
          <Link
            href="https://www.instagram.com/solfusengenharia/"
            target="_blank"
          >
            <Image
              src="/img/IconInstagram.svg"
              alt="Icon Instagram"
              w="30px"
              h="30px"
            />
          </Link>
          <Link href="https://br.linkedin.com/company/solfus" target="_blank">
            <Image
              src="/img/IconLinkedin.svg"
              alt="Icon Linkedin"
              w="30px"
              h="30px"
            />
          </Link>
        </Flex>
      </Flex>
      <Flex
        flexDir="column"
        w={['100%', '100%', '100%', '50%']}
        h="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          backgroundColor="rgba(234, 235, 240, 0.7)"
          shadow="0px 0px 50px black"
          w={['360px', '400px', '440px', '360px']}
          h="500px"
          borderRadius="12px"
          flexDir="column"
          display="flex"
          alignItems="center"
        >
          <Image
            src="/img/Logo-small.svg"
            alt="Solfus LogoSmall"
            w="31px"
            h="32px"
            mt="20px"
          />
          <Text.ScreenTitle mt="16px">Login</Text.ScreenTitle>
          <Flex>
            {' '}
            <FormControl>
              <Text.Label>Perfil</Text.Label>
              <Select.unauth
                type="select"
                id="usertype_id"
                name="usertype_id"
                value={values.usertype_id}
                onChange={handleChange}
                error={errors.usertype_id}
              >
                <option value="4">Cliente</option>
                <option value="3">Administrador</option>
                <option value="1">Gestor Técnico</option>
                <option value="2">Gestor Mercado Livre</option>
              </Select.unauth>
            </FormControl>
          </Flex>
          <Flex>
            <FormControl>
              <Text.Label>Email</Text.Label>
              <InputUnauth
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl>
              <Text.Label>Senha</Text.Label>
              <InputUnauth.Password
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                error={errors.password}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          </Flex>
          <Button.unauth isLoading={mutation.isLoading} onClick={handleSubmit}>
            Acessar
          </Button.unauth>
          <Link.Login onClick={() => navigate('/recover')}>
            Esqueceu sua Senha?
          </Link.Login>
        </Flex>
      </Flex>
    </Flex>
  )
}
