import moment from 'moment'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Image,
  Flex,
  useToast
} from '@chakra-ui/react'
import { Button, InputModal } from 'Components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'
import { updateTax } from 'services/api/requests'

export const ModalEditTax = ({ onClose, tax, id }) => {
  const toast = useToast()

  const formattedStartDate = moment.utc(tax?.start_date).format('YYYY-MM-DD')
  const formattedEndDate = moment.utc(tax?.end_date).format('YYYY-MM-DD')

  const queryClient = useQueryClient()

  const mutation = useMutation((data) => updateTax(id, data), {
    onError: (error) => {
      toast({
        title: 'Falha ao atualizar imposto.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Imposto atualizada com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      queryClient.invalidateQueries(['tax'])
      onClose()
      resetForm()
    }
  })

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues: {
      distributor_id: tax?.distributor_id || '',
      icms: tax?.icms || '',
      pis: tax?.pis || '',
      cofins: tax?.cofins || '',
      start_date: formattedStartDate || '',
      end_date: formattedEndDate || ''
    },
    validationSchema: Yup.object({
      distributor_id: Yup.number(),
      icms: Yup.number(),
      pis: Yup.number(),
      cofins: Yup.number(),
      start_date: Yup.string(),
      end_date: Yup.string()
    }),

    onSubmit: (data) => {
      const convertData = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value === '' ? 0 : value
        ])
      )
      mutation.mutate(convertData)
    }
  })

  const handleFocus = (e) => {
    if (e.target.value === 0) {
      setFieldValue(e.target.name, '')
    }
  }

  const handleBlur = (e) => {
    if (e.target.value === '') {
      setFieldValue(e.target.name, 0)
    }
  }

  return (
    <Flex>
      <Modal isOpen={true} onClose={onClose} size={2}>
        <ModalOverlay />
        <ModalContent
          flexDir="column"
          w={['300px', '500px', '680px', '680px']}
          justifyContent="center"
          borderRadius="8px"
          shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
        >
          <ModalHeader
            display="flex"
            flexDir="row"
            alignItems="flex-end"
            color="#F2920A"
          >
            <Image mr="12px" src="/img/IconImpostos.svg" alt="IconAddEmpresa" />
            Cadastro Imposto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
            >
              <Flex>
                <FormControl w={['100%', '100%', '100%', '160px']}>
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    ICMS
                  </FormLabel>
                  <InputModal
                    id="icms"
                    name="icms"
                    value={values.icms}
                    onChange={handleChange}
                    error={errors.icms}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="0,00 %"
                    type="number"
                  />
                </FormControl>
              </Flex>
              <Flex>
                <FormControl
                  mt={[4, 4, 4, 0]}
                  w={['100%', '100%', '100%', '160px']}
                >
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Pis
                  </FormLabel>
                  <InputModal
                    id="pis"
                    name="pis"
                    value={values.pis}
                    onChange={handleChange}
                    error={errors.pis}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="0,00 %"
                    type="number"
                  />
                </FormControl>
              </Flex>
              <Flex>
                <FormControl
                  mt={[4, 4, 4, 0]}
                  w={['100%', '100%', '100%', '160px']}
                >
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Cofins
                  </FormLabel>
                  <InputModal
                    id="cofins"
                    name="cofins"
                    value={values.cofins}
                    onChange={handleChange}
                    error={errors.cofins}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="0,00 %"
                    type="number"
                  />
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
            >
              <Flex
                w={['100%', '100%', '100%', '64%']}
                flexDir={['column', 'column', 'column', 'row']}
                justifyContent="space-between"
              >
                <FormControl mt={4} w={['100%', '100%', '100%', '180px']}>
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Início Vigência
                  </FormLabel>
                  <InputModal
                    id="start_date"
                    name="start_date"
                    value={values.start_date}
                    onChange={handleChange}
                    error={errors.start_date}
                    type="date"
                  />
                </FormControl>
                <FormControl mt={4} w={['100%', '100%', '100%', '180px']}>
                  <FormLabel
                    mb="0px"
                    fontSize="14px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Fim Vigência
                  </FormLabel>
                  <InputModal
                    id="end_date"
                    name="end_date"
                    value={values.end_date}
                    onChange={handleChange}
                    error={errors.end_date}
                    type="date"
                  />
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={mutation.isLoading}
              onClick={handleSubmit}
              bg="#F2920A"
              color="#ffffff"
              h="34px"
              mr={3}
              _hover={{
                color: 'brand.background_home',
                bg: 'brand.background'
              }}
            >
              Salvar
            </Button>
            <Button h="34px" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
