import React from 'react'
import { Td, Tr } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

export const DistributorTax = ({ id, name, cnpj_distributor }) => {
  const navigate = useNavigate()
  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{cnpj_distributor}</Td>
      <Td display="flex" flexDir="row" justifyContent="flex-end">
        <ChevronRightIcon
          onClick={() => navigate(`/tax/distributor/${id}`)}
          boxSize={8}
          cursor="pointer"
          _hover={{ transform: 'scale(1.1)' }}
        />
      </Td>
    </Tr>
  )
}
