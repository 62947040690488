import React from 'react'
import { Flex, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'

export const SearchBar = ({ onChange }) => {
  const [query, setQuery] = React.useState('')
  const handleInputChange = (e) => {
    setQuery(e.target.value)
    onChange(e.target.value)
  }

  return (
    <Flex
      w={['100%', '338px', '338px', '338px']}
      h="34px"
      bg="#ffffff"
      borderRadius="8px"
    >
      <InputGroup>
        <InputLeftElement h="100%">
          <SearchIcon color="brand.background_home" />
        </InputLeftElement>
        <Input
          value={query}
          onChange={handleInputChange}
          borderWidth="0px"
          w="100%"
          h="100%"
          placeholder="Pesquisa"
          _placeholder={{ fontStyle: 'Italic' }}
          focusBorderColor="transparent"
        />
      </InputGroup>
    </Flex>
  )
}
