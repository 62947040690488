import {
  Analysis,
  NewAnalysis,
  EditAnalysis,
  HomeTec,
  ClientTec,
  ChatAITec,
  Company,
  Unit,
  Suppliers,
  Tariff,
  TariffSettings,
  TariffDistributor,
  TariffDetail,
  Tax,
  TaxDistributor,
  Estudos,
  Flag,
  Teste
} from '../screens'

export const authRoutes = [
  {
    path: '/hometec',
    element: <HomeTec />
  },
  {
    path: '/clienttec',
    element: <ClientTec />
  },
  {
    path: '/chataitec',
    element: <ChatAITec />
  },
  {
    path: '/clienttec/company/:company_id',
    element: <Company />
  },
  {
    path: '/clienttec/company/:company_id/unit/:id',
    element: <Unit />
  },
  {
    path: '/suppliers',
    element: <Suppliers />
  },
  {
    path: '/tariff',
    element: <Tariff />
  },
  {
    path: '/tariff/tariffsettings',
    element: <TariffSettings />
  },
  {
    path: '/tariff/distributor/:distributor_id',
    element: <TariffDistributor />
  },
  {
    path: '/tariff/distributor/:distributor_id/detail/:id',
    element: <TariffDetail />
  },
  {
    path: '/tax',
    element: <Tax />
  },
  {
    path: '/tax/distributor/:distributor_id',
    element: <TaxDistributor />
  },
  {
    path: '/flag',
    element: <Flag />
  },
  {
    path: '/estudos',
    element: <Estudos />
  },
  {
    path: '/analysis',
    element: <Analysis />
  },
  {
    path: '/analysis/new',
    element: <NewAnalysis />
  },
  {
    path: '/analysis/edit/:id',
    element: <EditAnalysis />
  },
  {
    path: '/teste',
    element: <Teste />
  }
]
