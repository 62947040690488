import React from 'react'
import moment from 'moment'
import {
  Table,
  Thead,
  Tr,
  Th,
  TableContainer,
  Flex,
  useToast,
  Switch,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { getTariffByDistributor, deleteTariff } from 'services/api/requests'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { ListTariff, Loader, EmptyMessage } from 'Components'

export const TableTariff = ({ query }) => {
  const { distributor_id } = useParams()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data, isLoading } = useQuery(
    ['tariff', distributor_id],
    () => getTariffByDistributor(distributor_id),

    {
      enabled: !!distributor_id,
      retry: 1,
      retryDelay: 1000
    }
  )

  const deleteTariffMutation = useMutation((data) => deleteTariff(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao excluir tarifa.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: () => {
      toast({
        title: 'Tarifa excluída com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })

      queryClient.removeQueries(['tariff'])
    }
  })
  const handleDelete = (data) => {
    deleteTariffMutation.mutate(data)
  }

  const [showOnlyCurrent, setShowOnlyCurrent] = React.useState(false)

  const handleSwitchChange = () => {
    setShowOnlyCurrent(!showOnlyCurrent)
  }

  const filteredData = Array.isArray(data?.data)
    ? data.data.filter((tariff) => {
        if (!tariff?.voltageclass || !tariff?.tariffmodality) {
          return false
        }

        const matchesQuery =
          tariff.voltageclass.name
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          tariff.tariffmodality.name.toLowerCase().includes(query.toLowerCase())

        const isCurrent = moment().isBetween(
          moment.utc(tariff.start_date),
          moment.utc(tariff.end_date),
          'day',
          '[]'
        )

        return matchesQuery && (!showOnlyCurrent || isCurrent)
      })
    : []

  if (isLoading) {
    return <Loader />
  }

  if (!data?.data || data?.data.length === 0) {
    return <EmptyMessage>Nenhuma tarifa encontrada.</EmptyMessage>
  }

  return (
    <Flex
      bg="#ffffff"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="75%"
      mb="16px"
    >
      <TableContainer
        w="100%"
        justifyContent="space-between"
        overflowX="auto"
        overflowY="auto"
        borderRadius="8px"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'null',
            border: 'null'
          }
        }}
      >
        <Table mb="8px" mt="8px" size="sm">
          <Thead position="sticky" top="0" zIndex="docked" bg="#ffffff">
            <Tr height="34px">
              <Th fontSize="12px">Modalidade Tarifária</Th>
              <Th fontSize="12px">Classe de Tensão</Th>
              <Th fontSize="12px">Início Vigência</Th>
              <Th fontSize="12px">Fim Vigência</Th>
              <Th fontSize="12px">
                <FormControl display="flex" alignItems="center">
                  <FormLabel
                    mb="0"
                    fontSize="12px"
                    fontWeight="bold"
                    cursor="pointer"
                    htmlFor="current-tariff-switch"
                  >
                    Status
                  </FormLabel>
                  <Switch
                    id="current-tariff-switch"
                    isChecked={showOnlyCurrent}
                    onChange={handleSwitchChange}
                    colorScheme="green"
                    size="md"
                  />
                </FormControl>
              </Th>
              <Th></Th>
            </Tr>
          </Thead>
          <ListTariff onDelete={handleDelete} data={filteredData} />
        </Table>
      </TableContainer>
    </Flex>
  )
}
