import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react'
import { useToast } from '@chakra-ui/react'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const timeoutIdRef = useRef(null)
  const timeoutDuration = 20 * 60 * 1000 // 15 minutos
  const toast = useToast()

  useEffect(() => {
    const token = localStorage.getItem('@solfus_token')
    if (token) {
      setIsAuthenticated(true)
      startTimeout()
    }
    setIsAuthLoading(false)
  }, [])

  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('@solfus_token')
      if (token) {
        setIsAuthenticated(true)
        startTimeout()
      } else {
        setIsAuthenticated(false)
        clearTimeout(timeoutIdRef.current)
      }
      setIsAuthLoading(false)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const login = (token) => {
    localStorage.setItem('@solfus_token', token)
    setIsAuthenticated(true)
    startTimeout()
  }

  const logout = (reason = 'manual') => {
    localStorage.removeItem('@solfus_token')
    setIsAuthenticated(false)
    clearTimeout(timeoutIdRef.current)
    if (reason === 'inactivity') {
      toast({
        title: 'Sessão encerrada por inatividade.',
        status: 'warning',
        duration: 10000,
        isClosable: true
      })
    }
  }

  const startTimeout = useCallback(() => {
    clearTimeout(timeoutIdRef.current)
    timeoutIdRef.current = setTimeout(() => {
      logout('inactivity')
    }, timeoutDuration)
  }, [])

  const resetTimeout = useCallback(() => {
    clearTimeout(timeoutIdRef.current)
    if (isAuthenticated) {
      timeoutIdRef.current = setTimeout(() => {
        logout('inactivity')
      }, timeoutDuration)
    }
  }, [isAuthenticated, logout])

  useEffect(() => {
    const events = ['click', 'mousemove', 'keypress']
    const resetTimer = () => {
      resetTimeout()
    }

    events.forEach((event) => {
      window.addEventListener(event, resetTimer)
    })

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer)
      })
    }
  }, [resetTimeout])

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isAuthLoading, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}
