import { useState } from 'react'
import { Text } from 'Components/atoms/Text'
import {
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  Button
} from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

export const InputUnauth = (props) => (
  <>
    <ChakraInput
      display="flex"
      alignItems="center"
      textAlign="center"
      w="322px"
      h="38px"
      fontSize="14px"
      type="email"
      placeholder="email@exemplo.com"
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
      focusBorderColor="brand.primary"
      backgroundColor="brand.background"
      borderRadius="8px"
      shadow="0px 2px 10px rgba(0, 0, 0, 0.2)"
      {...props}
    />
    {props.error && (
      <Text fontSize="12px" color="red">
        {props.error}
      </Text>
    )}
  </>
)

InputUnauth.Password = ({ value, onChange, id, name, ...props }) => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  return (
    <>
      <InputGroup display="flex" alignItems="center" size="md" {...props}>
        <InputUnauth
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          fontSize="14px"
          type={show ? 'text' : 'password'}
          placeholder="*************"
        />
        <InputRightElement mr="16px">
          <Button
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            h="1.75rem"
            size="sm"
            onClick={handleClick}
          >
            {show ? (
              <ViewOffIcon boxSize="18px" color="brand.primary" />
            ) : (
              <ViewIcon boxSize="18px" color="brand.primary" />
            )}
          </Button>
        </InputRightElement>
      </InputGroup>
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

InputUnauth.Password.displayName = 'InputPassword'

InputUnauth.Token = ({ value, onChange, id, name, ...props }) => {
  return (
    <>
      <InputGroup display="flex" alignItems="center" size="md" {...props}>
        <InputUnauth
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          fontSize="14px"
          type="string"
          placeholder="0 0 0 0 0 0"
          maxLength="6"
        />
      </InputGroup>
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

InputUnauth.Token.displayName = 'InputToken'
