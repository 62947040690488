import React from 'react'
import { Table, Thead, Tr, Th, TableContainer, Flex } from '@chakra-ui/react'
import { getFlags } from 'services/api/requests' // ajuste o caminho conforme a estrutura de diretórios
import { useQuery } from 'react-query'
import { ListTariffFlag, Loader, EmptyMessage } from 'Components'

export const TableTariffFlag = () => {
  const { data, isLoading } = useQuery(['flags'], getFlags)
  if (isLoading) {
    return <Loader />
  }

  if (!data || data?.length === 0) {
    return <EmptyMessage>Nenhuma bandeira encontrada.</EmptyMessage>
  }

  return (
    <Flex
      bg="#ffffff"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="75%"
      mb="16px"
    >
      <TableContainer
        w="100%"
        justifyContent="space-between"
        overflowX="auto"
        overflowY="auto"
        borderRadius="8px"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'null',
            border: 'null'
          }
        }}
      >
        <Table mb="8px" mt="8px" size="sm">
          <Thead position="sticky" top="0" zIndex="docked" bg="#ffffff">
            <Tr height="34px">
              <Th fontSize="12px">Mês</Th>
              <Th fontSize="12px">Bandeira</Th>
              <Th fontSize="12px">Valor (R$/MWh)</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <ListTariffFlag data={data} />
        </Table>
      </TableContainer>
    </Flex>
  )
}
