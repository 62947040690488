import { Flex, IconButton, Image } from '@chakra-ui/react'

export const Alert = () => {
  return (
    <Flex>
      <IconButton
        w={['0px', '0px', '36px', '36px']}
        h={['0px', '0px', '36px', '36px']}
        bg="none"
        mr="32px"
        aria-label="Alert"
        onClick={() => null}
        icon={
          <Image
            src="/img/IconAlert.svg"
            alt="IconAlert"
            w={['0px', '0px', '36px', '36px']}
            h={['0px', '0px', '36px', '36px']}
          />
        }
        _hover={{
          bg: 'none',
          transform: 'scale(1.1)',
          transition: '0.1s'
        }}
      />
    </Flex>
  )
}
