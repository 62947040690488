import { Flex, Image } from '@chakra-ui/react'
import { Text } from 'Components'
import {
  getCountMonomia,
  getCountCativo,
  getCountML
} from 'services/api/requests'
import { useQuery } from 'react-query'

export const CardHome = () => {
  const { data: monomia } = useQuery(['countMonomia'], () => getCountMonomia())
  const countMonomia = monomia?.data || []

  const fixTextMon = countMonomia > 1 ? 'unidades' : 'unidade'
  const valueMon = countMonomia > 0 ? countMonomia : ''
  const textMon =
    countMonomia < 1 ? (
      <Text.CardHome>Sem unidade</Text.CardHome>
    ) : (
      <Flex
        flexDir={['row', 'row', 'row', 'column']}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Text.CardHome mr={['8px', '8px', '8px', '0px']} fontSize="22px">
          {valueMon}
        </Text.CardHome>
        <Text.CardHome fontSize={['0px', '0px', '0px', '16px']}>
          {fixTextMon}
        </Text.CardHome>
      </Flex>
    )

  const { data: cativo } = useQuery(['countCativo'], () => getCountCativo())
  const countCativo = cativo?.data || []

  const fixTextCat = countCativo > 1 ? 'unidades' : 'unidade'
  const valueCat = countCativo > 0 ? countCativo : ''
  const textCat =
    countCativo < 1 ? (
      <Text.CardHome>Sem unidade</Text.CardHome>
    ) : (
      <Flex
        flexDir={['row', 'row', 'row', 'column']}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Text.CardHome mr={['8px', '8px', '8px', '0px']} fontSize="22px">
          {valueCat}
        </Text.CardHome>
        <Text.CardHome fontSize={['0px', '0px', '0px', '16px']}>
          {fixTextCat}
        </Text.CardHome>
      </Flex>
    )

  const { data: mercadolivre } = useQuery(['countML'], () => getCountML())
  const countML = mercadolivre?.data || []

  const fixTextML = countML > 1 ? 'unidades' : 'unidade'
  const valueML = countML > 0 ? countML : ''
  const textML =
    countML < 1 ? (
      <Text.CardHome>Sem unidade</Text.CardHome>
    ) : (
      <Flex
        flexDir={['row', 'row', 'row', 'column']}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Text.CardHome mr={['8px', '8px', '8px', '0px']} fontSize="22px">
          {valueML}
        </Text.CardHome>
        <Text.CardHome fontSize={['0px', '0px', '0px', '16px']}>
          {fixTextML}
        </Text.CardHome>
      </Flex>
    )

  return (
    <Flex
      display="flex"
      w="100%"
      h="100%"
      paddingX={['4%', '6%', '8%', '8%']}
      flexDir="column"
    >
      <Flex flex="1" alignItems="flex-start">
        <Text.Header
          fontSize={['16px', '16px', '16px', '19px']}
          color="brand.background_home"
          ml="0px"
        >
          Unidades sob sua gestão
        </Text.Header>
      </Flex>

      <Flex
        h="216px"
        alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
        justifyContent="space-between"
        flexDir={['column', 'column', 'column', 'row']}
      >
        <Flex
          minW="126px"
          w={['94%', '94%', '94%', '26%']}
          h={['60px', '60px', '60px', '216px']}
          flexDir={['row', 'row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          bg="brand.background_home"
          borderRadius="10px"
          paddingY="14px"
          paddingX="14px"
        >
          <Flex
            w={['60%', '50%', '40%', '100%']}
            h={['100%', '100%', '100%', '55%']}
            flexDir={['row-reverse', 'row-reverse', 'row-reverse', 'column']}
            alignItems="center"
            justifyContent={[
              'flex-end',
              'flex-end',
              'flex-end',
              'space-between'
            ]}
          >
            <Text.CardHome ml={['16px', '16px', '16px', '0px']}>
              Monômia
            </Text.CardHome>
            <Image
              src="/img/IconMonomia.svg"
              alt="IconMonomia"
              w={['30px', '30px', '30px', '54px']}
              h={['30px', '30px', '30px', '54px']}
            />
          </Flex>
          <Flex
            w={['40%', '50%', '60%', '100%']}
            h={['100%', '100%', '100%', '40%']}
            flexDir={['row', 'row', 'row', 'column']}
            alignItems="center"
            justifyContent="flex-end"
          >
            {textMon}
          </Flex>
        </Flex>
        <Flex
          minW="126px"
          w={['94%', '94%', '94%', '26%']}
          h={['60px', '60px', '60px', '216px']}
          flexDir={['row', 'row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          bg="brand.background_home"
          borderRadius="10px"
          paddingY="14px"
          paddingX="14px"
        >
          <Flex
            w={['60%', '50%', '40%', '100%']}
            h={['100%', '100%', '100%', '55%']}
            flexDir={['row-reverse', 'row-reverse', 'row-reverse', 'column']}
            alignItems="center"
            justifyContent={[
              'flex-end',
              'flex-end',
              'flex-end',
              'space-between'
            ]}
          >
            <Text.CardHome ml={['16px', '16px', '16px', '0px']}>
              Cativo
            </Text.CardHome>
            <Image
              src="/img/IconCativo.svg"
              alt="IconCativo"
              w={['30px', '30px', '30px', '54px']}
              h={['30px', '30px', '30px', '54px']}
            />
          </Flex>
          <Flex
            w={['40%', '50%', '60%', '100%']}
            h={['100%', '100%', '100%', '40%']}
            flexDir={['row', 'row', 'row', 'column']}
            alignItems="center"
            justifyContent="flex-end"
          >
            {textCat}
          </Flex>
        </Flex>
        <Flex
          minW="126px"
          w={['94%', '94%', '94%', '26%']}
          h={['60px', '60px', '60px', '216px']}
          flexDir={['row', 'row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          bg="brand.background_home"
          borderRadius="10px"
          paddingY="14px"
          paddingX="14px"
        >
          <Flex
            w={['60%', '50%', '40%', '100%']}
            h={['100%', '100%', '100%', '55%']}
            flexDir={['row-reverse', 'row-reverse', 'row-reverse', 'column']}
            alignItems="center"
            justifyContent={[
              'flex-end',
              'flex-end',
              'flex-end',
              'space-between'
            ]}
          >
            <Text.CardHome
              textAlign={['left', 'left', 'left', 'center']}
              ml={['16px', '16px', '16px', '0px']}
            >
              Mercado Livre
            </Text.CardHome>
            <Image
              src="/img/IconMercadoLivre.svg"
              alt="IconMercadoLivre"
              w={['30px', '30px', '30px', '54px']}
              h={['30px', '30px', '30px', '54px']}
            />
          </Flex>
          <Flex
            w={['40%', '50%', '60%', '100%']}
            h={['100%', '100%', '100%', '40%']}
            flexDir={['row', 'row', 'row', 'column']}
            alignItems="center"
            justifyContent="flex-end"
          >
            {textML}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
