import {
  Flex,
  FormControl,
  FormLabel,
  Box,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { InputModal } from 'Components'
import { getAnalysisById } from 'services/api/requests'

export const ConsumptionSection = ({
  id,
  values,
  handleChange,
  setFieldValue,
  errors,
  isRegulated,
  isGreen,
  isConventional
}) => {
  const { data: analysis } = useQuery(
    ['analysis', id],
    () => getAnalysisById(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const analysisData = analysis?.data

  return (
    <Flex flexDir="column">
      {!isConventional && (
        <Flex flexDir="column">
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter
              fontSize="14px"
              fontWeight="semibold"
              bg="white"
              px="4"
            >
              Consumo Fora de Ponta
            </AbsoluteCenter>
          </Box>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="offpeakconsumption"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo F.P. (kW/h)
              </FormLabel>
              <InputModal.Currency
                id="offpeakconsumption"
                name="offpeakconsumption"
                value={values.offpeakconsumption}
                onChange={(val) => setFieldValue('offpeakconsumption', val)}
                error={errors.offpeakconsumption}
                placeholder="0 kWh"
                textAlign="center"
              />
            </FormControl>
            <Flex flexDir="column" w={['100%', '100%', '100%', '20%']}>
              <FormControl w="100%" mt={4}>
                <FormLabel
                  htmlFor="tariff_offpeakconsumption_tusd"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Tarifa TUSD (R$/kWh)
                </FormLabel>
                <InputModal.Currency
                  id="tariff_offpeakconsumption_tusd"
                  name="tariff_offpeakconsumption_tusd"
                  value={values.tariff_offpeakconsumption_tusd}
                  onChange={(val) =>
                    setFieldValue('tariff_offpeakconsumption_tusd', val)
                  }
                  error={errors.tariff_offpeakconsumption_tusd}
                  placeholder="R$/kW 0,00"
                  textAlign="center"
                />
              </FormControl>
              {isRegulated && (
                <FormControl w="100%" mt={4}>
                  <FormLabel
                    htmlFor="tariff_offpeakconsumption_te"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Tarifa TE (R$/kWh)
                  </FormLabel>
                  <InputModal.Currency
                    id="tariff_offpeakconsumption_te"
                    name="tariff_offpeakconsumption_te"
                    value={values.tariff_offpeakconsumption_te}
                    onChange={(val) =>
                      setFieldValue('tariff_offpeakconsumption_te', val)
                    }
                    error={errors.tariff_offpeakconsumption_te}
                    placeholder="R$/kW 0,00"
                    textAlign="center"
                  />
                </FormControl>
              )}
            </Flex>
            <Flex flexDir="column" w={['100%', '100%', '100%', '20%']}>
              <FormControl w="100%" mt={4}>
                <FormLabel
                  htmlFor="valueOffpeakconsumption_tusd"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Valor TUSD (R$)
                </FormLabel>
                <InputModal.Result
                  id="valueOffpeakconsumption_tusd"
                  name="valueOffpeakconsumption_tusd"
                  value={
                    analysisData?.valueOffpeakconsumption_tusd || undefined
                  }
                />
              </FormControl>
              {isRegulated && (
                <FormControl w="100%" mt={4}>
                  <FormLabel
                    htmlFor="valueOffpeakconsumption_te"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Valor TE (R$)
                  </FormLabel>
                  <InputModal.Result
                    id="valueOffpeakconsumption_te"
                    name="valueOffpeakconsumption_te"
                    value={
                      analysisData?.valueOffpeakconsumption_te || undefined
                    }
                  />
                </FormControl>
              )}
            </Flex>
          </Flex>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={4}>
              <FormLabel
                htmlFor="offpeak_surplus_reactive_power"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo Excedente Reativo F.P. (kvar)
              </FormLabel>
              <InputModal.Currency
                id="offpeak_surplus_reactive_power"
                name="offpeak_surplus_reactive_power"
                value={values.offpeak_surplus_reactive_power}
                onChange={(val) =>
                  setFieldValue('offpeak_surplus_reactive_power', val)
                }
                error={errors.offpeak_surplus_reactive_power}
                placeholder="0 kvar"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={4}>
              <FormLabel
                htmlFor="tariff_offpeaksurplusreactivepower"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Tarifa (R$/kvar)
              </FormLabel>
              <InputModal.Currency
                id="tariff_offpeaksurplusreactivepower"
                name="tariff_offpeaksurplusreactivepower"
                value={values.tariff_surplusreactivepower}
                onChange={(val) =>
                  setFieldValue('tariff_offpeaksurplusreactivepower', val)
                }
                error={errors.tariff_surplusreactivepower}
                placeholder="R$/kW 0,00"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={4}>
              <FormLabel
                htmlFor="valueOffpeak_surplus_reactive_power"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor (R$)
              </FormLabel>
              <InputModal.Result
                id="valueOffpeak_surplus_reactive_power"
                name="valueOffpeak_surplus_reactive_power"
                value={
                  analysisData?.valueOffpeak_surplus_reactive_power || undefined
                }
              />
            </FormControl>
          </Flex>
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter
              fontSize="14px"
              fontWeight="semibold"
              bg="white"
              px="4"
            >
              Consumo Ponta
            </AbsoluteCenter>
          </Box>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="peakconsumption"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo P. (kW/h)
              </FormLabel>
              <InputModal.Currency
                id="peakconsumption"
                name="peakconsumption"
                value={values.peakconsumption}
                onChange={(val) => setFieldValue('peakconsumption', val)}
                error={errors.peakconsumption}
                placeholder="0 kWh"
                textAlign="center"
              />
            </FormControl>
            <Flex flexDir="column" w={['100%', '100%', '100%', '20%']}>
              {!isRegulated && isGreen && (
                <FormControl w="100%" mt={4}>
                  <FormLabel
                    htmlFor="tariff_peakconsumption_tusd"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Tarifa com Desconto ML TUSD (R$/kWh)
                  </FormLabel>
                  <InputModal.Currency
                    id="tariff_peakconsumption_tusdML"
                    name="tariff_peakconsumption_tusdML"
                    value={values.tariff_peakconsumption_tusdML}
                    onChange={(val) =>
                      setFieldValue('tariff_peakconsumption_tusdML', val)
                    }
                    error={errors.tariff_peakconsumption_tusdML}
                    placeholder="R$/kW 0,00"
                    textAlign="center"
                  />
                </FormControl>
              )}
              {!isRegulated && !isGreen && (
                <FormControl w="100%" mt={4}>
                  <FormLabel
                    htmlFor="tariff_peakconsumption_tusd"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Tarifa TUSD (R$/kWh)
                  </FormLabel>
                  <InputModal.Currency
                    id="tariff_peakconsumption_tusd"
                    name="tariff_peakconsumption_tusd"
                    value={values.tariff_peakconsumption_tusd}
                    onChange={(val) =>
                      setFieldValue('tariff_peakconsumption_tusd', val)
                    }
                    error={errors.tariff_peakconsumption_tusd}
                    placeholder="R$/kW 0,00"
                    textAlign="center"
                  />
                </FormControl>
              )}
              {isRegulated && (
                <FormControl w="100%" mt={4}>
                  <FormLabel
                    htmlFor="tariff_peakconsumption_tusd"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Tarifa TUSD (R$/kWh)
                  </FormLabel>
                  <InputModal.Currency
                    id="tariff_peakconsumption_tusd"
                    name="tariff_peakconsumption_tusd"
                    value={values.tariff_peakconsumption_tusd}
                    onChange={(val) =>
                      setFieldValue('tariff_peakconsumption_tusd', val)
                    }
                    error={errors.tariff_peakconsumption_tusd}
                    placeholder="R$/kW 0,00"
                    textAlign="center"
                  />
                </FormControl>
              )}
              {isRegulated && (
                <FormControl w="100%" mt={4}>
                  <FormLabel
                    htmlFor="tariff_peakconsumption_te"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Tarifa TE (R$/kWh)
                  </FormLabel>
                  <InputModal.Currency
                    id="tariff_peakconsumption_te"
                    name="tariff_peakconsumption_te"
                    value={values.tariff_peakconsumption_te}
                    onChange={(val) =>
                      setFieldValue('tariff_peakconsumption_te', val)
                    }
                    error={errors.tariff_peakconsumption_te}
                    placeholder="R$/kW 0,00"
                    textAlign="center"
                  />
                </FormControl>
              )}
            </Flex>
            <Flex flexDir="column" w={['100%', '100%', '100%', '20%']}>
              <FormControl w="100%" mt={4}>
                <FormLabel
                  htmlFor="valuePeakconsumption_tusd"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Valor TUSD (R$)
                </FormLabel>
                <InputModal.Result
                  id="valuePeakconsumption_tusd"
                  name="valuePeakconsumption_tusd"
                  value={analysisData?.valuePeakconsumption_tusd || undefined}
                />
              </FormControl>
              {isRegulated && (
                <FormControl w="100%" mt={4}>
                  <FormLabel
                    htmlFor="valuePeakconsumption_te"
                    display="flex"
                    textAlign={[
                      'flex-start',
                      'flex-start',
                      'flex-start',
                      'center'
                    ]}
                    mb="0px"
                    fontSize="12px"
                    fontWeight="semibold"
                    color="#303030"
                  >
                    Valor TE (R$)
                  </FormLabel>
                  <InputModal.Result
                    id="valuePeakconsumption_te"
                    name="valuePeakconsumption_te"
                    value={analysisData?.valuePeakconsumption_te || undefined}
                  />
                </FormControl>
              )}
            </Flex>
          </Flex>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={4}>
              <FormLabel
                htmlFor="peak_surplus_reactive_power"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo Excedente Reativo P. (kvar)
              </FormLabel>
              <InputModal.Currency
                id="peak_surplus_reactive_power"
                name="peak_surplus_reactive_power"
                value={values.peak_surplus_reactive_power}
                onChange={(val) =>
                  setFieldValue('peak_surplus_reactive_power', val)
                }
                error={errors.peak_surplus_reactive_power}
                placeholder="0 kvar"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={4}>
              <FormLabel
                htmlFor="tariff_surplusreactivepower"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Tarifa (R$/kvar)
              </FormLabel>
              <InputModal.Currency
                id="tariff_surplusreactivepower"
                name="tariff_surplusreactivepower"
                value={values.tariff_surplusreactivepower}
                onChange={(val) =>
                  setFieldValue('tariff_surplusreactivepower', val)
                }
                error={errors.tariff_surplusreactivepower}
                placeholder="R$/kW 0,00"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={4}>
              <FormLabel
                htmlFor="valuePeak_surplus_reactive_power"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor (R$)
              </FormLabel>
              <InputModal.Result
                id="valuePeak_surplus_reactive_power"
                name="valuePeak_surplus_reactive_power"
                value={
                  analysisData?.valuePeak_surplus_reactive_power || undefined
                }
              />
            </FormControl>
          </Flex>
        </Flex>
      )}
      {isConventional && (
        <Flex flexDir="column">
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter
              fontSize="14px"
              fontWeight="semibold"
              bg="white"
              px="4"
            >
              Consumo
            </AbsoluteCenter>
          </Box>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="offpeakconsumption"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo (kW/h)
              </FormLabel>
              <InputModal.Currency
                id="offpeakconsumption"
                name="offpeakconsumption"
                value={values.offpeakconsumption}
                onChange={(val) => setFieldValue('offpeakconsumption', val)}
                error={errors.offpeakconsumption}
                placeholder="0 kWh"
                textAlign="center"
              />
            </FormControl>
            <Flex flexDir="column" w={['100%', '100%', '100%', '20%']}>
              <FormControl w="100%" mt={4}>
                <FormLabel
                  htmlFor="tariff_offpeakconsumption_tusd"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Tarifa TUSD (R$/kWh)
                </FormLabel>
                <InputModal.Currency
                  id="tariff_offpeakconsumption_tusd"
                  name="tariff_offpeakconsumption_tusd"
                  value={values.tariff_offpeakconsumption_tusd}
                  onChange={(val) =>
                    setFieldValue('tariff_offpeakconsumption_tusd', val)
                  }
                  error={errors.tariff_offpeakconsumption_tusd}
                  placeholder="R$/kW 0,00"
                  textAlign="center"
                />
              </FormControl>

              <FormControl w="100%" mt={4}>
                <FormLabel
                  htmlFor="tariff_offpeakconsumption_te"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Tarifa TE (R$/kWh)
                </FormLabel>
                <InputModal.Currency
                  id="tariff_offpeakconsumption_te"
                  name="tariff_offpeakconsumption_te"
                  value={values.tariff_offpeakconsumption_te}
                  onChange={(val) =>
                    setFieldValue('tariff_offpeakconsumption_te', val)
                  }
                  error={errors.tariff_offpeakconsumption_te}
                  placeholder="R$/kW 0,00"
                  textAlign="center"
                />
              </FormControl>
            </Flex>
            <Flex flexDir="column" w={['100%', '100%', '100%', '20%']}>
              <FormControl w="100%" mt={4}>
                <FormLabel
                  htmlFor="valueTotalConsumption"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Valor (R$)
                </FormLabel>
                <InputModal.Result
                  id="valueTotalConsumption"
                  name="valueTotalConsumption"
                  value={analysisData?.valueTotalConsumption || undefined}
                />
              </FormControl>
            </Flex>
          </Flex>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl w={['100%', '100%', '100%', '20%']} mt={4}>
              <FormLabel
                htmlFor="offpeak_surplus_reactive_power"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Consumo Excedente Reativo (kvar)
              </FormLabel>
              <InputModal.Currency
                id="offpeak_surplus_reactive_power"
                name="offpeak_surplus_reactive_power"
                value={values.offpeak_surplus_reactive_power}
                onChange={(val) =>
                  setFieldValue('offpeak_surplus_reactive_power', val)
                }
                error={errors.offpeak_surplus_reactive_power}
                placeholder="0 kvar"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={4}>
              <FormLabel
                htmlFor="tariff_surplusreactivepower"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Tarifa (R$/kvar)
              </FormLabel>
              <InputModal.Currency
                id="tariff_surplusreactivepower"
                name="tariff_surplusreactivepower"
                value={values.tariff_surplusreactivepower}
                onChange={(val) =>
                  setFieldValue('offpeak_surplus_reactive_power', val)
                }
                error={errors.tariff_surplusreactivepower}
                placeholder="R$/kW 0,00"
                textAlign="center"
              />
            </FormControl>
            <FormControl w={['100%', '100%', '100%', '20%']} mt={4}>
              <FormLabel
                htmlFor="valueOffpeak_surplus_reactive_power"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor (R$)
              </FormLabel>
              <InputModal.Result
                id="valueOffpeak_surplus_reactive_power"
                name="valueOffpeak_surplus_reactive_power"
                value={
                  analysisData?.valueOffpeak_surplus_reactive_power || undefined
                }
              />
            </FormControl>
          </Flex>
        </Flex>
      )}
      {!isRegulated && (
        <Flex flexDir="column">
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter
              fontSize="14px"
              fontWeight="semibold"
              bg="white"
              px="4"
            >
              Taxas Adicionais
            </AbsoluteCenter>
          </Box>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex
              w={['100%', '100%', '100%', '50%']}
              flexDir="column"
              alignItems="center"
            >
              <FormControl w={['100%', '100%', '100%', '40%']} mt={4}>
                <FormLabel
                  htmlFor="tariff_covid"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Tarifa Conta COVID (R$/kWh)
                </FormLabel>
                <InputModal.Currency
                  id="tariff_covid"
                  name="tariff_covid"
                  value={values.tariff_covid}
                  onChange={(val) => setFieldValue('tariff_covid', val)}
                  error={errors.tariff_covid}
                  placeholder="R$/kW 0,00"
                  textAlign="center"
                />
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '40%']} mt={4}>
                <FormLabel
                  htmlFor="valueCovidaccount"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Valor Conta COVID (R$)
                </FormLabel>
                <InputModal.Result
                  id="valueCovidaccount"
                  name="valueCovidaccount"
                  value={analysisData?.valueCovidaccount || undefined}
                />
              </FormControl>
            </Flex>
            <Divider orientation="vertical" />
            <Flex
              w={['100%', '100%', '100%', '50%']}
              flexDir="column"
              alignItems="center"
            >
              <FormControl w={['100%', '100%', '100%', '40%']} mt={4}>
                <FormLabel
                  htmlFor="tariff_waterscarcity"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Tarifa Escassez Híd. (R$/kWh)
                </FormLabel>
                <InputModal.Currency
                  id="tariff_waterscarcity"
                  name="tariff_waterscarcity"
                  value={values.tariff_waterscarcity}
                  onChange={(val) => setFieldValue('tariff_waterscarcity', val)}
                  error={errors.tariff_waterscarcity}
                  placeholder="R$/kW 0,00"
                  textAlign="center"
                />
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '40%']} mt={4}>
                <FormLabel
                  htmlFor="valueWaterscarcityfee"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Valor Escassez Híd. (R$)
                </FormLabel>
                <InputModal.Result
                  id="valueWaterscarcityfee"
                  name="valueWaterscarcityfee"
                  value={analysisData?.valueWaterscarcityfee || undefined}
                />
              </FormControl>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
