import { Flex } from '@chakra-ui/react'
import { Text, Alert } from 'Components'
import { useSelector } from 'react-redux'

export const HeaderHomeTec = () => {
  const userStore = useSelector((state) => state.user)
  const fullName = userStore?.user?.name
  let firstName = ''
  if (fullName) {
    const names = fullName.split(' ')
    firstName = names[0]
  }
  return (
    <Flex
      display="flex"
      as="header"
      w={['0px', '0px', '58%', '72%']}
      h={['0px', '0px', '48px', '58px']}
      bg="brand.primary"
      borderRadius="10px 0px 0px 10px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      position="absolute"
      top="24px"
      right="0"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text.Header>{firstName}, bem vindo à sua área de trabalho !</Text.Header>
      <Alert />
    </Flex>
  )
}
