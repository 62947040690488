import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex
} from '@chakra-ui/react'
import { Button, Text } from 'Components'

export const ModalDeleteTariff = ({ onClose, onDelete }) => {
  return (
    <Flex>
      <Modal
        isCentered
        size="xs"
        closeOnOverlayClick={false}
        isOpen={true}
        onClose={onClose}
      >
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent
          flexDir="column"
          w={['300px', '500px', '700px', '800px']}
          borderRadius="8px"
          shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
        >
          <ModalHeader display="flex" justifyContent="center"></ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Text.Perfil fontSize="16px" fontWeight="semibold" color="#303030">
              Tem certeza que deseja excluir esta tarifa ?
            </Text.Perfil>
          </ModalBody>

          <ModalFooter mt="12px">
            <Button
              size="sm"
              bg="#ff2c4c"
              color="#ffffff"
              h="30px"
              mr={3}
              _hover={{
                color: 'brand.background_home',
                bg: 'brand.background'
              }}
              onClick={onDelete}
            >
              Excluir
            </Button>
            <Button size="sm" h="30px" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
