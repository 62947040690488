import {
  Flex,
  FormControl,
  FormLabel,
  Box,
  Divider,
  AbsoluteCenter,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel
} from '@chakra-ui/react'
import { InputModal, Text } from 'Components'
import { useQuery } from 'react-query'
import { getAnalysisById } from 'services/api/requests'

export const TaxationSection = ({
  id,
  values,
  handleChange,
  setFieldValue,
  errors
}) => {
  const { data: analysis } = useQuery(
    ['analysis', id],
    () => getAnalysisById(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const analysisData = analysis?.data

  return (
    <Flex flexDir="column">
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter fontSize="14px" fontWeight="semibold" bg="white" px="4">
          Tributação
        </AbsoluteCenter>
      </Box>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Flex flexDir="column" w={['100%', '100%', '100%', '15%']}>
          <FormControl w="100%" mt={[4, 4, 4, 0]}>
            <FormLabel
              htmlFor="offpeaktaxsubstitution"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Substituição Tributária F.P. (R$)
            </FormLabel>
            <InputModal.Currency
              id="offpeaktaxsubstitution"
              name="offpeaktaxsubstitution"
              value={values.offpeaktaxsubstitution}
              onChange={(val) => setFieldValue('offpeaktaxsubstitution', val)}
              error={errors.offpeaktaxsubstitution}
              placeholder="R$ 0,00"
              textAlign="center"
            />
          </FormControl>
          <FormControl w="100%" mt={4}>
            <FormLabel
              htmlFor="peaktaxsubstitution"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Substituição Tributária P. (R$)
            </FormLabel>
            <InputModal.Currency
              id="peaktaxsubstitution"
              name="peaktaxsubstitution"
              value={values.peaktaxsubstitution}
              onChange={(val) => setFieldValue('peaktaxsubstitution', val)}
              error={errors.peaktaxsubstitution}
              placeholder="R$ 0,00"
              textAlign="center"
            />
          </FormControl>
        </Flex>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="energydeduction"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Dedução de Energia (R$)
          </FormLabel>
          <InputModal.Currency
            id="energydeduction"
            name="energydeduction"
            value={values.energydeduction}
            onChange={(val) => setFieldValue('energydeduction', val)}
            error={errors.energydeduction}
            placeholder="R$ 0,00"
            textAlign="center"
          />
        </FormControl>
        <FormControl w={['100%', '100%', '100%', '15%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="valueICMSEnergy"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="12px"
            fontWeight="semibold"
            color="#303030"
          >
            Valor ICMS Energia (R$)
          </FormLabel>
          <InputModal.Result
            id="valueICMSEnergy"
            name="valueICMSEnergy"
            value={analysisData?.valueICMSEnergy || undefined}
          />
        </FormControl>
        <Divider orientation="vertical" />
        <Flex flexDir="column" w={['100%', '100%', '100%', '35%']}>
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Flex flexDir="column" w={['100%', '100%', '100%', '40%']}>
              <FormControl mt={[4, 4, 4, 0]}>
                <FormLabel
                  htmlFor="grosstariffsubsidy"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Subsídio Tarifário Bruto (R$)
                </FormLabel>
                <InputModal.Result
                  id="grosstariffsubsidy"
                  name="grosstariffsubsidy"
                  value={analysisData?.grosstariffsubsidy || undefined}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel
                  htmlFor="nettariffsubsidy"
                  display="flex"
                  textAlign={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center'
                  ]}
                  mb="0px"
                  fontSize="12px"
                  fontWeight="semibold"
                  color="#303030"
                >
                  Subsídio Tarifário Líquido (R$)
                </FormLabel>
                <InputModal.Result
                  id="nettariffsubsidy"
                  name="nettariffsubsidy"
                  value={analysisData?.nettariffsubsidy || undefined}
                />
              </FormControl>
            </Flex>
            <FormControl w={['100%', '100%', '100%', '40%']} mt={[4, 4, 4, 0]}>
              <FormLabel
                htmlFor="valueTariffsubsidy"
                display="flex"
                textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
                mb="0px"
                fontSize="12px"
                fontWeight="semibold"
                color="#303030"
              >
                Valor Subsídio Tarifário (R$)
              </FormLabel>
              <InputModal.Result
                id="valueTariffsubsidy"
                name="valueTariffsubsidy"
                value={analysisData?.valueTariffsubsidy || undefined}
              />
            </FormControl>
          </Flex>
          <Accordion
            w="100%"
            allowMultiple
            border="2px"
            borderColor="brand.gray"
            borderRadius="8px"
            mt={4}
          >
            <AccordionItem border="none">
              <Flex>
                <AccordionButton>
                  <Flex as="span" flex="1" textAlign="left">
                    <Text.Header
                      fontSize="13px"
                      fontWeight="semibold"
                      color="brand.background_home"
                      ml="0px"
                    >
                      Editar Subsídio
                    </Text.Header>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </Flex>
              <AccordionPanel>
                <Flex
                  display="flex"
                  flexDir="column"
                  w={['100%', '100%', '100%', '100%']}
                >
                  <Flex
                    flexDir={['column', 'column', 'column', 'row']}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <FormControl
                      w={['100%', '100%', '100%', '45%']}
                      mt={[4, 4, 4, 0]}
                    >
                      <FormLabel
                        htmlFor="grosstariffsubsidy1"
                        display="flex"
                        textAlign={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center'
                        ]}
                        mb="0px"
                        fontSize="12px"
                        fontWeight="semibold"
                        color="#303030"
                      >
                        Subsídio Tarifário Bruto (R$)
                      </FormLabel>
                      <InputModal.Currency
                        id="grosstariffsubsidy1"
                        name="grosstariffsubsidy1"
                        value={values.grosstariffsubsidy1}
                        onChange={(val) =>
                          setFieldValue('grosstariffsubsidy1', val)
                        }
                        error={errors.grosstariffsubsidy1}
                        placeholder="R$ 0,00"
                        textAlign="center"
                      />
                    </FormControl>
                    <FormControl
                      w={['100%', '100%', '100%', '45%']}
                      mt={[4, 4, 4, 0]}
                    >
                      <FormLabel
                        htmlFor="nettariffsubsidy1"
                        display="flex"
                        textAlign={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center'
                        ]}
                        mb="0px"
                        fontSize="12px"
                        fontWeight="semibold"
                        color="#303030"
                      >
                        Subsídio Tarifário Líquido (R$)
                      </FormLabel>
                      <InputModal.Currency
                        id="nettariffsubsidy1"
                        name="nettariffsubsidy1"
                        value={values.nettariffsubsidy1}
                        onChange={(val) =>
                          setFieldValue('nettariffsubsidy1', val)
                        }
                        error={errors.nettariffsubsidy1}
                        placeholder="R$ 0,00"
                        textAlign="center"
                      />
                    </FormControl>
                  </Flex>
                  <Flex
                    flexDir={['column', 'column', 'column', 'row']}
                    justifyContent="space-between"
                    w="100%"
                    mt={[0, 0, 0, 4]}
                  >
                    <FormControl
                      w={['100%', '100%', '100%', '45%']}
                      mt={[4, 4, 4, 0]}
                    >
                      <FormLabel
                        htmlFor="grosstariffsubsidy2"
                        display="flex"
                        textAlign={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center'
                        ]}
                        mb="0px"
                        fontSize="12px"
                        fontWeight="semibold"
                        color="#303030"
                      >
                        Subsídio Tarifário Bruto (R$)
                      </FormLabel>
                      <InputModal.Currency
                        id="grosstariffsubsidy2"
                        name="grosstariffsubsidy2"
                        value={values.grosstariffsubsidy2}
                        onChange={(val) =>
                          setFieldValue('grosstariffsubsidy2', val)
                        }
                        error={errors.grosstariffsubsidy2}
                        placeholder="R$ 0,00"
                        textAlign="center"
                      />
                    </FormControl>
                    <FormControl
                      w={['100%', '100%', '100%', '45%']}
                      mt={[4, 4, 4, 0]}
                    >
                      <FormLabel
                        htmlFor="nettariffsubsidy2"
                        display="flex"
                        textAlign={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center'
                        ]}
                        mb="0px"
                        fontSize="12px"
                        fontWeight="semibold"
                        color="#303030"
                      >
                        Subsídio Tarifário Líquido (R$)
                      </FormLabel>
                      <InputModal.Currency
                        id="nettariffsubsidy2"
                        name="nettariffsubsidy2"
                        value={values.nettariffsubsidy2}
                        onChange={(val) =>
                          setFieldValue('nettariffsubsidy2', val)
                        }
                        error={errors.nettariffsubsidy2}
                        placeholder="R$ 0,00"
                        textAlign="center"
                      />
                    </FormControl>
                  </Flex>
                  <Flex
                    flexDir={['column', 'column', 'column', 'row']}
                    justifyContent="space-between"
                    w="100%"
                    mt={[0, 0, 0, 4]}
                  >
                    <FormControl
                      w={['100%', '100%', '100%', '45%']}
                      mt={[4, 4, 4, 0]}
                    >
                      <FormLabel
                        htmlFor="grosstariffsubsidy3"
                        display="flex"
                        textAlign={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center'
                        ]}
                        mb="0px"
                        fontSize="12px"
                        fontWeight="semibold"
                        color="#303030"
                      >
                        Subsídio Tarifário Bruto (R$)
                      </FormLabel>
                      <InputModal.Currency
                        id="grosstariffsubsidy3"
                        name="grosstariffsubsidy3"
                        value={values.grosstariffsubsidy3}
                        onChange={(val) =>
                          setFieldValue('grosstariffsubsidy3', val)
                        }
                        error={errors.grosstariffsubsidy3}
                        placeholder="R$ 0,00"
                        textAlign="center"
                      />
                    </FormControl>
                    <FormControl
                      w={['100%', '100%', '100%', '45%']}
                      mt={[4, 4, 4, 0]}
                    >
                      <FormLabel
                        htmlFor="nettariffsubsidy3"
                        display="flex"
                        textAlign={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center'
                        ]}
                        mb="0px"
                        fontSize="12px"
                        fontWeight="semibold"
                        color="#303030"
                      >
                        Subsídio Tarifário Líquido (R$)
                      </FormLabel>
                      <InputModal.Currency
                        id="nettariffsubsidy3"
                        name="nettariffsubsidy3"
                        value={values.nettariffsubsidy3}
                        onChange={(val) =>
                          setFieldValue('nettariffsubsidy3', val)
                        }
                        error={errors.nettariffsubsidy3}
                        placeholder="R$ 0,00"
                        textAlign="center"
                      />
                    </FormControl>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      </Flex>
      <Flex
        flexDir={['column', 'column', 'column', 'row']}
        justifyContent="flex-start"
        alignItems="flex-end"
        mt={[4, 4, 4, 8]}
      >
        <FormControl w={['100%', '100%', '100%', '20%']} mt={[4, 4, 4, 0]}>
          <FormLabel
            htmlFor="hasDevec"
            display="flex"
            textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
            mb="0px"
            fontSize="14px"
            fontWeight="semibold"
            color="#303030"
          >
            Declaração DEVEC
          </FormLabel>
          <Checkbox
            id="hasDevec"
            name="hasDevec"
            isChecked={values.hasDevec}
            onChange={handleChange}
            error={errors.hasDevec}
            mb="0px"
            fontWeight="semibold"
            color="#303030"
            alignItems="center"
            colorScheme="green"
          >
            <Flex flexDir="row">
              <Text fontSize="13px" fontWeight="normal">
                {values.hasDevec ? 'Sim' : 'Não'}
              </Text>
            </Flex>
          </Checkbox>
        </FormControl>
      </Flex>
    </Flex>
  )
}
