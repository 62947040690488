import React from 'react'
import moment from 'moment'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import {
  Loader,
  EmptyMessage,
  MenuEditAnalysis,
  StatusBadge,
  DemandSection,
  ConsumptionSection,
  ReleasesSection,
  TaxationSection,
  EnergySection,
  ChargesSection,
  InitialInformation,
  SummarySection,
  SavingsSection,
  FlagImageMap,
  InputModal
} from 'Components'
import {
  Flex,
  useToast,
  Button,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  updateAnalysis,
  getAnalysisById,
  getTariffByAnalysisId,
  getTaxByAnalysisId,
  getFlagByAnalysisId
} from 'services/api/requests'

export const ScreenEditAnalysis = ({ id }) => {
  const userStore = useSelector((state) => state.user)
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data: tariff } = useQuery(
    ['tariff', id],
    () => getTariffByAnalysisId(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const tariffusedData = tariff?.data

  const { data: tax } = useQuery(['tax', id], () => getTaxByAnalysisId(id), {
    enabled: !!id,
    retry: 1,
    retryDelay: 1000
  })
  const taxusedData = tax?.data

  const { data: flag } = useQuery(['flag', id], () => getFlagByAnalysisId(id), {
    enabled: !!id,
    retry: 1,
    retryDelay: 1000
  })
  const flagusedData = flag?.data

  const getFlagIdByName = (flagName) => {
    return FlagImageMap.find((bandeira) => bandeira.name === flagName)?.id || ''
  }

  const getFlagNameById = (idFlag) => {
    return (
      FlagImageMap.find((bandeira) => bandeira.id === parseInt(idFlag, 10))
        ?.name || ''
    )
  }

  const { data: analysis, isLoading } = useQuery(
    ['analysis', id],
    () => getAnalysisById(id),
    {
      enabled: !!id,
      retry: 1,
      retryDelay: 1000
    }
  )
  const analysisData = analysis?.data

  const formattedStartDate = moment
    .utc(analysisData?.start_date)
    .format('YYYY-MM-DD')
  const formattedEndDate = moment
    .utc(analysisData?.end_date)
    .format('YYYY-MM-DD')

  const mutation = useMutation((data) => updateAnalysis(id, data), {
    onError: (error) => {
      toast({
        title: 'Falha ao atualizar dados da análise.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Análise atualizada com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
      queryClient.invalidateQueries(['analysis'])
    }
  })

  const { handleSubmit, values, handleChange, errors, setFieldValue } =
    useFormik({
      initialValues: {
        unit_id: analysisData?.unit_id || '',
        user_id: userStore?.user?.id || '',
        distributor_id: analysisData?.distributor_id || '',
        supplier_id: analysisData?.supplier_id || 0,
        hiringenvironment_id: analysisData?.hiringenvironment_id || '',
        voltageclass_id: analysisData?.voltageclass_id || '',
        tariffmodality_id: analysisData?.tariffmodality_id || '',
        start_date: formattedStartDate || '',
        end_date: formattedEndDate || '',
        total_cost: parseFloat(analysisData?.total_cost) || '',

        offpeakdemandcontract:
          parseFloat(analysisData?.offpeakdemandcontract) || '',
        peakdemandcontract: parseFloat(analysisData?.peakdemandcontract) || '',
        offpeakdemand: parseFloat(analysisData?.offpeakdemand) || '',
        peakdemand: parseFloat(analysisData?.peakdemand) || '',
        offpeaksurplus_reactive_demand:
          parseFloat(analysisData?.offpeaksurplus_reactive_demand) || '',
        peaksurplus_reactive_demand:
          parseFloat(analysisData?.peaksurplus_reactive_demand) || '',

        offpeakconsumption: parseFloat(analysisData?.offpeakconsumption) || '',
        peakconsumption: parseFloat(analysisData?.peakconsumption) || '',

        offpeak_surplus_reactive_power:
          parseFloat(analysisData?.offpeak_surplus_reactive_power) || '',
        peak_surplus_reactive_power:
          parseFloat(analysisData?.peak_surplus_reactive_power) || '',

        energyconsumption: parseFloat(analysisData?.energyconsumption) || '',
        priceenergyconsumption:
          parseFloat(analysisData?.priceenergyconsumption) || '',
        total_cost_energyconsumption:
          parseFloat(analysisData?.total_cost_energyconsumption) || '',

        energyassignment: parseFloat(analysisData?.energyassignment) || '',
        priceenergyassignment:
          parseFloat(analysisData?.priceenergyassignment) || '',
        total_cost_energyassignment:
          parseFloat(analysisData?.total_cost_energyassignment) || '',

        surplusenergy: parseFloat(analysisData?.surplusenergy) || '',
        pricesurplusenergy: parseFloat(analysisData?.pricesurplusenergy) || '',
        total_cost_surplusenergy:
          parseFloat(analysisData?.total_cost_surplusenergy) || '',

        offpeaktaxsubstitution:
          parseFloat(analysisData?.offpeaktaxsubstitution) || '',
        peaktaxsubstitution:
          parseFloat(analysisData?.peaktaxsubstitution) || '',
        energydeduction: parseFloat(analysisData?.energydeduction) || '',

        grosstariffsubsidy1: parseFloat(analysisData?.grosstariffsubsidy) || '',
        nettariffsubsidy1: parseFloat(analysisData?.nettariffsubsidy) || '',
        grosstariffsubsidy2: '',
        nettariffsubsidy2: '',
        grosstariffsubsidy3: '',
        nettariffsubsidy3: '',

        associationfee: parseFloat(analysisData?.associationfee) || '',
        financialsettlement:
          parseFloat(analysisData?.financialsettlement) || '',
        reserveenergycharges:
          parseFloat(analysisData?.reserveenergycharges) || '',
        ercap: parseFloat(analysisData?.ercap) || '',

        energydiscount: parseFloat(analysisData?.energydiscount) || '',

        public_lighting: parseFloat(analysisData?.public_lighting) || '',
        fines: parseFloat(analysisData?.fines) || '',
        fees: parseFloat(analysisData?.fees) || '',
        billingadjustment: parseFloat(analysisData?.billingadjustment) || '',
        chargesAndRefunds: parseFloat(analysisData?.chargesAndRefunds) || '',
        supplementary_demand:
          parseFloat(analysisData?.supplementary_demand) || '',
        others: parseFloat(analysisData?.others) || '',

        noICMSinTUSD: analysisData?.noICMSinTUSD || false,
        noICMSinDemand: analysisData?.noICMSinDemand || false,
        noICMSinConsumptionTUSD: analysisData?.noICMSinConsumptionTUSD || false,
        hasICMSinDemandUnused: analysisData?.hasICMSinDemandUnused || false,
        hasCovidAccount: analysisData?.hasCovidAccount || false,
        hasWaterScarcityFee: analysisData?.hasWaterScarcityFee || false,
        isSeasonality: analysisData?.isSeasonality || false,
        hasDevec: analysisData?.hasDevec || false,

        tariff_peakdemand: parseFloat(tariffusedData?.tariff_peakdemand) || '',
        tariff_offpeakdemand:
          parseFloat(tariffusedData?.tariff_offpeakdemand) || '',
        tariff_peakconsumption_tusd:
          parseFloat(tariffusedData?.tariff_peakconsumption_tusd) || '',
        tariff_peakconsumption_tusdML:
          parseFloat(tariffusedData?.tariff_peakconsumption_tusdML) || '',
        tariff_offpeakconsumption_tusd:
          parseFloat(tariffusedData?.tariff_offpeakconsumption_tusd) || '',
        tariff_peakconsumption_te:
          parseFloat(tariffusedData?.tariff_peakconsumption_te) || '',
        tariff_offpeakconsumption_te:
          parseFloat(tariffusedData?.tariff_offpeakconsumption_te) || '',
        tariff_surplusreactivepower:
          parseFloat(tariffusedData?.tariff_surplusreactivepower) || '',
        tariff_waterscarcity:
          parseFloat(tariffusedData?.tariff_waterscarcity) || '',
        tariff_covid: parseFloat(tariffusedData?.tariff_covid) || '',
        tax_icms: parseFloat(taxusedData?.tax_icms) || '',
        tax_pis: parseFloat(taxusedData?.tax_pis) || '',
        tax_cofins: parseFloat(taxusedData?.tax_cofins) || '',
        flag_name: getFlagIdByName(flagusedData?.flag_name) || '',
        flag_price: parseFloat(flagusedData?.flag_price) || ''
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        unit_id: Yup.number().required('Id da unidade é obrigatório'),
        user_id: Yup.number().required('Id do usuário é obrigatório'),
        distributor_id: Yup.number().required('Distribuidora é obrigatório'),
        supplier_id: Yup.number(),
        hiringenvironment_id: Yup.number()
          .required('Ambiente de Contratação é obrigatório')
          .test(
            'is-regulated-environment',
            'O ambiente de contratação deve ser Regulado',
            function (value) {
              const { tariffmodality_id } = this.parent
              return tariffmodality_id === 3 ? value === 2 : true
            }
          ),
        voltageclass_id: Yup.number().required(
          'Classe de Tensão é obrigatório'
        ),
        tariffmodality_id: Yup.number().required(
          'Modalidade Tarifária é obrigatório'
        ),
        start_date: Yup.date().required('Data de início é obrigatória'),
        end_date: Yup.date().required('Data de término é obrigatória'),
        total_cost: Yup.number().required('Custo total é obrigatório'),

        offpeakdemandcontract: Yup.number(),
        peakdemandcontract: Yup.number(),
        offpeakdemand: Yup.number(),
        peakdemand: Yup.number(),
        offpeaksurplus_reactive_demand: Yup.number(),
        peaksurplus_reactive_demand: Yup.number(),

        offpeakconsumption: Yup.number(),
        peakconsumption: Yup.number(),

        offpeak_surplus_reactive_power: Yup.number(),
        peak_surplus_reactive_power: Yup.number(),

        energyconsumption: Yup.number(),
        priceenergyconsumption: Yup.number(),
        total_cost_energyconsumption: Yup.number(),

        energyassignment: Yup.number(),
        priceenergyassignment: Yup.number(),
        total_cost_energyassignment: Yup.number(),

        surplusenergy: Yup.number(),
        pricesurplusenergy: Yup.number(),
        total_cost_surplusenergy: Yup.number(),

        offpeaktaxsubstitution: Yup.number(),
        peaktaxsubstitution: Yup.number(),
        energydeduction: Yup.number(),

        grosstariffsubsidy: Yup.number(),
        nettariffsubsidy: Yup.number(),

        associationfee: Yup.number(),
        financialsettlement: Yup.number(),
        reserveenergycharges: Yup.number(),

        energydiscount: Yup.number(),

        public_lighting: Yup.number(),
        fines: Yup.number(),
        fees: Yup.number(),
        billingadjustment: Yup.number(),
        chargesAndRefunds: Yup.number(),
        supplementary_demand: Yup.number(),
        others: Yup.number(),

        noICMSinTUSD: Yup.boolean(),
        noICMSinDemand: Yup.boolean(),
        noICMSinConsumptionTUSD: Yup.boolean(),
        hasICMSinDemandUnused: Yup.boolean(),
        hasCovidAccount: Yup.boolean(),
        hasWaterScarcityFee: Yup.boolean(),
        isSeasonality: Yup.boolean(),
        hasDevec: Yup.boolean(),

        tariffPeakDemand: Yup.number(),
        tariffOffPeakDemand: Yup.number(),
        tariffPeakConsumptionTusd: Yup.number(),
        tariffOffPeakConsumptionTusd: Yup.number(),
        tariffPeakConsumptionTe: Yup.number(),
        tariffPeakConsumptionTusdML: Yup.number(),
        tariffOffPeakConsumptionTe: Yup.number(),
        tariffSurplusReactivePower: Yup.number(),
        tariffWaterScarcity: Yup.number(),
        tariffCovidTusd: Yup.number(),
        taxICMS: Yup.number(),
        taxPIS: Yup.number(),
        taxCofins: Yup.number(),
        flagName: Yup.string(),
        flagPrice: Yup.number()
      }),

      onSubmit: (data) => {
        const excludeFields = [
          'tariff_peakdemand',
          'tariff_offpeakdemand',
          'tariff_peakconsumption_tusd',
          'tariff_peakconsumption_tusdML',
          'tariff_offpeakconsumption_tusd',
          'tariff_peakconsumption_te',
          'tariff_offpeakconsumption_te',
          'tariff_surplusreactivepower',
          'tariff_waterscarcity',
          'tariff_covid',
          'tax_icms',
          'tax_pis',
          'tax_cofins',
          'flag_name',
          'flag_price'
        ]

        const convertData = Object.fromEntries(
          Object.entries(data).map(([key, value]) => {
            if (key === 'flag_name') {
              return [key, getFlagNameById(value)]
            }
            if (excludeFields.includes(key)) {
              return [key, value === '' ? undefined : value]
            }
            if (value === '' || value === null || value === undefined) {
              return [key, 0]
            }
            if (typeof value === 'string' && !isNaN(value)) {
              return [key, parseFloat(value)]
            }

            return [key, value]
          })
        )
        mutation.mutate(convertData)
      }
    })

  const isConventional = values.tariffmodality_id === 3
  const isGreen = values.tariffmodality_id === 2
  const isRegulated = values.hiringenvironment_id === 2

  const [selectedButton, setSelectedButton] = React.useState('summary')

  if (isLoading) {
    return <Loader />
  }
  if (!analysisData || analysisData.length === 0) {
    return <EmptyMessage>Erro ao buscar dados da unidade.</EmptyMessage>
  }
  return (
    <Flex
      backgroundColor="white"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="80%"
      mb="16px"
      padding={6}
      flexDir={['column', 'column', 'column', 'column']}
      overflowX="hidden"
      overflowY="auto"
      css={{
        '::-webkit-scrollbar': {
          width: '4px',
          height: '4px'
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          background: 'gray'
        },
        '::-webkit-scrollbar-track': {
          borderRadius: '4px',
          background: 'white',
          border: '1px solid gray'
        }
      }}
    >
      <InitialInformation
        id={id}
        values={values}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        errors={errors}
        isGreen={isGreen}
        isRegulated={isRegulated}
        isConventional={isConventional}
      />

      <MenuEditAnalysis
        selectedButton={selectedButton}
        onSelectButton={setSelectedButton}
        isConventional={isConventional}
        isRegulated={isRegulated}
      />

      {selectedButton === 'summary' && (
        <SummarySection
          id={id}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          isRegulated={isRegulated}
          isConventional={isConventional}
        />
      )}

      {selectedButton === 'demand' && (
        <DemandSection
          id={id}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          isGreen={isGreen}
          isRegulated={isRegulated}
        />
      )}

      {selectedButton === 'consumption' && (
        <ConsumptionSection
          id={id}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          isRegulated={isRegulated}
          isConventional={isConventional}
          isGreen={isGreen}
        />
      )}
      {selectedButton === 'releases' && (
        <ReleasesSection
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          isRegulated={isRegulated}
          isConventional={isConventional}
        />
      )}
      {selectedButton === 'taxation' && (
        <TaxationSection
          id={id}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      )}

      {selectedButton === 'energy' && (
        <EnergySection
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      )}
      {selectedButton === 'charges' && (
        <ChargesSection
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      )}
      {selectedButton === 'savings' && (
        <SavingsSection
          id={id}
          values={values}
          handleChange={handleChange}
          errors={errors}
        />
      )}

      <Flex
        flexDir={['column', 'row', 'row', 'row']}
        justifyContent={
          selectedButton === 'summary'
            ? ['center', 'space-between', 'space-between', 'space-between']
            : ['center', 'flex-end', 'flex-end', 'flex-end']
        }
        alignItems="center"
        mt={[4, 4, 4, 12]}
      >
        {selectedButton === 'summary' && (
          <FormControl
            w={['100%', '100%', '100%', '15%']}
            mt={[4, 4, 4, 0]}
            isReadOnly
          >
            <FormLabel
              htmlFor="user_id"
              display="flex"
              textAlign={['flex-start', 'flex-start', 'flex-start', 'center']}
              mb="0px"
              fontSize="12px"
              fontWeight="semibold"
              color="#303030"
            >
              Analisado por:
            </FormLabel>
            <InputModal
              id="user_id"
              name="user_id"
              value={analysisData?.user?.name}
            />
          </FormControl>
        )}
        <Flex
          flexDir={['column', 'row', 'row', 'row']}
          justifyContent={['center', 'flex-end', 'flex-end', 'flex-end']}
          alignItems="center"
        >
          {selectedButton !== 'summary' && (
            <Flex mr={[0, 6, 6, 6]}>
              <StatusBadge status={analysisData?.status} />
            </Flex>
          )}
          <Button
            mt={[6, 0, 0, 0]}
            isLoading={mutation.isLoading}
            onClick={handleSubmit}
            bg="#F2920A"
            color="#ffffff"
            h="34px"
            _hover={{
              color: 'brand.background_home',
              bg: 'brand.background'
            }}
          >
            Recalcular
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
