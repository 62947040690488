import {
  Table,
  Thead,
  Tr,
  Th,
  TableContainer,
  Flex,
  useToast
} from '@chakra-ui/react'
import { getCompany, deleteCompany } from 'services/api/requests'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { ListCompany, Loader, EmptyMessage } from 'Components'

export const TableClient = ({ query }) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(['company'], getCompany, {
    retry: 1,
    retryDelay: 1000
  })

  const deleteCompanyMutation = useMutation((data) => deleteCompany(data), {
    onError: (error) => {
      toast({
        title: 'Falha ao excluir empresa.',
        description:
          error?.response?.data?.error || 'Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    },
    onSuccess: () => {
      toast({
        title: 'Empresa excluída com sucesso!',
        status: 'success',
        duration: 6000,
        isClosable: true
      })
      queryClient.removeQueries('company')
    }
  })
  const handleDelete = (data) => {
    deleteCompanyMutation.mutate(data)
  }

  const filteredData = data?.data?.filter((company) => {
    return (
      company.name.toLowerCase().includes(query.toLowerCase()) ||
      company.cnpj_company.includes(query)
    )
  })

  if (isLoading) {
    return <Loader />
  }

  if (!data || data?.length === 0) {
    return <EmptyMessage>Nenhuma empresa encontrada.</EmptyMessage>
  }
  return (
    <Flex
      bg="#ffffff"
      borderRadius="8px"
      shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
      mt="14px"
      h="75%"
      mb="16px"
    >
      <TableContainer
        w="100%"
        justifyContent="space-between"
        overflowX="auto"
        overflowY="auto"
        borderRadius="8px"
        css={{
          '::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            background: 'gray'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: 'null',
            border: 'null'
          }
        }}
      >
        <Table mb="8px" mt="8px" size="sm">
          <Thead position="sticky" top="0" zIndex="docked" bg="#ffffff">
            <Tr height="34px">
              <Th fontSize="12px">Razão Social</Th>
              <Th fontSize="12px">CNPJ</Th>
              <Th fontSize="12px">Filiais</Th>
              <Th fontSize="12px">Fim do Contrato</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <ListCompany onDelete={handleDelete} data={filteredData || []} />
        </Table>
      </TableContainer>
    </Flex>
  )
}
