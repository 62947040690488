import { LoginScreen, RecoverScreen, ResetPasswordScreen } from '../screens'

export const unauthRoutes = [
  {
    path: '/',
    element: <LoginScreen />
  },

  {
    path: '/recover',
    element: <RecoverScreen />
  },
  {
    path: '/resetPassword',
    element: <ResetPasswordScreen />
  }
]
