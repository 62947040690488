import axios from 'axios'
import { QueryClient } from 'react-query'
import { restoreItem } from 'services/storage'

export const api = axios.create({
  baseURL: 'https://api.solfus.com.br',
  headers: { Authorization: `bearer ${restoreItem('@solfus_token')}` }
})

export const apiAneel = axios.create({
  baseURL: 'https://api.solfus.com.br/aneel-api/'
})

export const queryClient = new QueryClient()
