import { Avatar, Flex, Collapse } from '@chakra-ui/react'
import { Text } from 'Components'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const PerfilMenu = ({ collapse }) => {
  const userStore = useSelector((state) => state.user)
  const fullName = userStore?.user?.name
  let firstName = ''
  let lastName = ''

  if (fullName) {
    const names = fullName.split(' ')
    firstName = names[0]
    lastName = names[names.length - 1]
  }

  const navigate = useNavigate()
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      mt="26px"
      onClick={() => navigate('/hometec')}
      _hover={{ cursor: 'pointer' }}
    >
      <Avatar
        name={userStore?.user?.name}
        src={userStore?.user?.avatar_url}
        borderWidth="2px"
        borderColor="brand.primary"
      />
      <Collapse in={!collapse} animateOpacity>
        <Text.Perfil2 mt="8px">{userStore?.user?.usertype?.name}</Text.Perfil2>
        <Text.Perfil mt="0">
          {firstName} {lastName}
        </Text.Perfil>
      </Collapse>
    </Flex>
  )
}
