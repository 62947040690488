import { Flex } from '@chakra-ui/react'
import ReactDOMServer from 'react-dom/server'
import { Button, TemplateFreeBlue } from 'Components'

export const Teste = () => {
  // Função para gerar o HTML e enviar ao backend
  const handleDownloadReport = async () => {
    const htmlContent = ReactDOMServer.renderToStaticMarkup(
      <TemplateFreeBlue />
    )

    try {
      const response = await fetch('/reports/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ reportHtml: htmlContent })
      })

      if (!response.ok) {
        throw new Error('Erro ao gerar o relatório')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `relatorio_${336}.pdf`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      alert('Erro ao gerar o relatório: ' + error.message)
    }
  }
  return (
    <Flex>
      <TemplateFreeBlue />
      <Button onClick={handleDownloadReport}> Baixar</Button>
    </Flex>
  )
}
