import { DeleteIcon } from '@chakra-ui/icons'

export const MenuMoreVoltageClass = ({ setShowModalDelete }) => {
  return (
    <DeleteIcon
      cursor="pointer"
      w="18px"
      h="18px"
      onClick={() => setShowModalDelete('voltageclass')}
      _hover={{ transform: 'scale(1.08)' }}
    />
  )
}
