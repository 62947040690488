import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  useDisclosure,
  Image
} from '@chakra-ui/react'
import { Button, Text } from 'Components'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setAll } from 'services/store/slices/user'
import { useAuth } from 'router/authProvider'

export const ModalQuit = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { logout, isAuthenticated } = useAuth()

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated, navigate])
  const onLogout = () => {
    localStorage.clear()
    dispatch(setAll({ user: null, token: null }))
    logout()
  }
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex>
      <Button.logout onClick={onOpen} />
      <Modal
        isCentered
        size="sm"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent
          flexDir="column"
          w={['300px', '500px', '700px', '1000px']}
          borderRadius="8px"
          shadow="2px 2px 10px rgba(0, 0, 0, 0.4)"
        >
          <ModalHeader display="flex" justifyContent="center">
            <Image src="/img/Logo.svg" alt="Logo" h="100px" />
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Text.Perfil fontSize="16px" fontWeight="semibold" color="#303030">
              Deseja realmente sair do aplicativo ?
            </Text.Perfil>
          </ModalBody>

          <ModalFooter mt="12px">
            <Button
              bg="#F2920A"
              color="#ffffff"
              h="34px"
              mr={3}
              _hover={{
                color: 'brand.background_home',
                bg: 'brand.background'
              }}
              onClick={() => onLogout()}
            >
              Sair
            </Button>
            <Button h="34px" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
