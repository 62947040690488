import { Flex, Image } from '@chakra-ui/react'
import { ButtonsMenu, PerfilMenu, ModalQuit } from 'Components'

export const Menu = ({ collapse }) => {
  return (
    <Flex display="flex" flexDir="column">
      <Flex flexDir="column" alignItems="center">
        <Image
          src="/img/Logo-small.svg"
          alt="Solfus LogoSmall"
          w="31px"
          h="32px"
          mt="26px"
        />
        <PerfilMenu collapse={collapse} />
        <ButtonsMenu collapse={collapse} />
      </Flex>
      <Flex
        flexDir="row"
        justifyContent="center"
        alignItems="baseline"
        mr="8px"
        ml="8px"
        mt="26px"
      >
        <ModalQuit />
      </Flex>
    </Flex>
  )
}
