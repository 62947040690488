import React from 'react'
import moment from 'moment'
import 'moment/locale/pt'
import { Td, Tr } from '@chakra-ui/react'
import { MenuMoreTax, ModalDeleteTax, ModalEditTax } from 'Components'

moment.locale('pt')

export const Tax = ({
  id,
  distributor,
  icms,
  pis,
  cofins,
  start_date,
  end_date,
  onDelete,
  distributor_id
}) => {
  const [showModalDelete, setShowModalDelete] = React.useState()
  const [showModalEdit, setShowModalEdit] = React.useState()

  const formattedStartDate = moment.utc(start_date).format('DD/MM/YYYY')
  const formattedEndDate = moment.utc(end_date).format('DD/MM/YYYY')

  const findMajorityMonth = (startDate, endDate) => {
    const start = moment.utc(startDate)
    const end = moment.utc(endDate)

    const monthDays = {}

    const current = start.clone()

    while (current.isSameOrBefore(end, 'day')) {
      const monthYear = current.format('MMM/YY')
      if (!monthDays[monthYear]) {
        monthDays[monthYear] = 0
      }
      monthDays[monthYear]++
      current.add(1, 'day')
    }

    let maxDays = 0
    let majorityMonth = ''

    for (const monthYear in monthDays) {
      if (monthDays[monthYear] > maxDays) {
        maxDays = monthDays[monthYear]
        majorityMonth = monthYear
      }
    }

    return majorityMonth
  }

  const majorityMonth = findMajorityMonth(start_date, end_date)

  const formatPercentage = (value) => {
    return value
      .toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      .replace('.', ',')
  }

  const onCloseModalDelete = () => {
    setShowModalDelete(null)
  }

  const onCloseModalEdit = () => {
    setShowModalEdit(null)
  }

  const handleDelete = () => {
    onDelete(id)
    onCloseModalDelete()
  }

  return (
    <Tr>
      <Td>{majorityMonth}</Td>
      <Td>{formatPercentage(icms)} %</Td>
      <Td>{formatPercentage(pis)} %</Td>
      <Td>{formatPercentage(cofins)} %</Td>
      <Td>{formattedStartDate}</Td>
      <Td>{formattedEndDate}</Td>
      <Td display="flex" flexDir="row" justifyContent="flex-end">
        <MenuMoreTax
          setShowModalDelete={setShowModalDelete}
          setShowModalEdit={setShowModalEdit}
        />

        {showModalDelete === 'tax' && (
          <ModalDeleteTax
            onDelete={handleDelete}
            onClose={onCloseModalDelete}
          />
        )}
        {showModalEdit === 'tax' && (
          <ModalEditTax
            onClose={onCloseModalEdit}
            id={id}
            tax={{
              distributor,
              icms,
              pis,
              cofins,
              start_date,
              end_date,
              distributor_id
            }}
          />
        )}
      </Td>
    </Tr>
  )
}
