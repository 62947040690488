import { Flex } from '@chakra-ui/react'

import { Text, CardHome } from 'Components'

export const InfoHomeTec = () => {
  return (
    <Flex
      display="flex"
      w="100%"
      mt="60px"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Flex w="62%" h="280px">
        <CardHome />
      </Flex>
      <Flex
        display="flex"
        w="38%"
        h="280px"
        bg="brand.background_home"
        borderRadius="10px 0px 0px 10px"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDir="column"
      >
        <Text.Header fontSize={['16px', '16px', '16px', '19px']} mt="8px">
          Tarefas Pendentes
        </Text.Header>
        <Text.Header fontWeight="regular" fontSize="14px" mt="8px">
          Nenhuma tarefa pendente.
        </Text.Header>
      </Flex>
    </Flex>
  )
}
