import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Td, Tr, Badge } from '@chakra-ui/react'
import {
  MenuMoreTariff,
  ModalDeleteTariff,
  ModalEditTariff,
  Button
} from 'Components'

export const Tariff = ({
  id,
  distributor_id,
  voltageclass,
  tariffmodality_id,
  tariffmodality,
  voltageclass_id,
  peakdemand,
  offpeakdemand,
  peakconsumption_tusd,
  offpeakconsumption_tusd,
  peakconsumption_te,
  offpeakconsumption_te,
  surplusreactivepower,
  waterscarcity,
  covid_tusd,
  start_date,
  end_date,
  onDelete
}) => {
  const navigate = useNavigate()
  const [showModalDelete, setShowModalDelete] = React.useState()
  const [showModalEdit, setShowModalEdit] = React.useState()

  const formattedStartDate = moment.utc(start_date).format('DD/MM/YYYY')
  const formattedEndDate = moment.utc(end_date).format('DD/MM/YYYY')

  const isCurrent = moment().isBetween(
    moment.utc(start_date),
    moment.utc(end_date),
    'day',
    '[]'
  )

  const onCloseModalDelete = () => {
    setShowModalDelete(null)
  }

  const onCloseModalEdit = () => {
    setShowModalEdit(null)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  const current = isCurrent ? (
    <Badge colorScheme="green">Vigente</Badge>
  ) : (
    <Badge colorScheme="red">Não Vigente</Badge>
  )

  return (
    <Tr>
      <Td>{tariffmodality?.name}</Td>
      <Td>{voltageclass?.name}</Td>
      <Td>{formattedStartDate}</Td>
      <Td>{formattedEndDate}</Td>
      <Td>{current}</Td>
      <Td display="flex" flexDir="row" justifyContent="space-between">
        <Button.listDetail
          onClick={() =>
            navigate(`/tariff/distributor/${distributor_id}/detail/${id}`)
          }
        />
        <MenuMoreTariff
          setShowModalDelete={setShowModalDelete}
          setShowModalEdit={setShowModalEdit}
        />

        {showModalDelete === 'tariff' && (
          <ModalDeleteTariff
            onDelete={handleDelete}
            onClose={onCloseModalDelete}
          />
        )}
        {showModalEdit === 'tariff' && (
          <ModalEditTariff
            onClose={onCloseModalEdit}
            id={id}
            tariff={{
              distributor_id,
              tariffmodality_id,
              voltageclass_id,
              peakdemand,
              offpeakdemand,
              peakconsumption_tusd,
              offpeakconsumption_tusd,
              peakconsumption_te,
              offpeakconsumption_te,
              surplusreactivepower,
              waterscarcity,
              covid_tusd,
              start_date,
              end_date
            }}
          />
        )}
      </Td>
    </Tr>
  )
}
