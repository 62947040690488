import { api } from 'services/api'

export const registerTax = (data) =>
  api.post('/tax', data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getTax = () =>
  api.get('/tax', {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const getTaxByDistributor = (id) =>
  api.get(`/tax/distributor/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const deleteTax = (id) =>
  api.delete(`/tax/${id}`, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })

export const updateTax = (id, data) =>
  api.put(`/tax/${id}`, data, {
    headers: {
      Authorization: `bearer ${localStorage.getItem('@solfus_token')}`
    }
  })
