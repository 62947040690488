import { Tbody } from '@chakra-ui/react'
import { Distributor } from 'Components'

export const ListDistributor = ({ data, onDelete }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <Distributor
            key={`distributor_${item.id}`}
            {...item}
            onDelete={onDelete}
          />
        ))}
    </Tbody>
  )
}
