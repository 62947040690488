import { Text } from 'Components/atoms/Text'
import { Input as ChakraInput, InputGroup } from '@chakra-ui/react'
import { PatternFormat, NumericFormat } from 'react-number-format'

export const InputModal = (props) => (
  <>
    <ChakraInput
      display="flex"
      alignItems="center"
      h="36px"
      fontSize="14px"
      focusBorderColor="brand.primary"
      backgroundColor="brand.background"
      {...props}
    />
    {props.error && (
      <Text fontSize="12px" color="red">
        {props.error}
      </Text>
    )}
  </>
)

InputModal.Number = ({ value, onChange, id, name, ...props }) => {
  return (
    <>
      <InputGroup display="flex" alignItems="center" size="md" {...props}>
        <ChakraInput
          as={PatternFormat}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          type="tel"
          format="(##) ##### ####"
          h="36px"
          fontSize="14px"
          focusBorderColor="brand.primary"
          backgroundColor="brand.background"
        />
      </InputGroup>
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

InputModal.Number.displayName = 'InputModalNumber'

InputModal.CNPJ = ({ value, onChange, id, name, ...props }) => {
  return (
    <>
      <InputGroup display="flex" alignItems="center" size="md" {...props}>
        <ChakraInput
          as={PatternFormat}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          format="##.###.###/####-##"
          mask="_"
          h="36px"
          fontSize="14px"
          focusBorderColor="brand.primary"
          backgroundColor="brand.background"
        />
      </InputGroup>
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

InputModal.CNPJ.displayName = 'InputModalCNPJ'

InputModal.Result = ({ value, onChange, id, name, ...props }) => {
  const numberFormat = (valor) => {
    return Intl.NumberFormat('pt-BR').format(valor)
  }
  return (
    <>
      <InputGroup display="flex" alignItems="center" size="md" {...props}>
        <ChakraInput
          display="flex"
          textAlign="center"
          id={id}
          name={name}
          onChange={onChange}
          value={numberFormat(value)}
          h="36px"
          fontSize="14px"
          focusBorderColor="brand.primary"
          backgroundColor="#FFFFFF"
          isReadOnly
          placeholder="R$ 0,00"
        />
      </InputGroup>
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

InputModal.Result.displayName = 'InputModalResult'

InputModal.Currency = ({
  value,
  onChange,
  placeholder,
  id,
  name,
  decimalScale,
  ...props
}) => {
  const handleValueChange = (values) => {
    const rawValue = values.value.replace(',', '.')
    onChange(rawValue === '' ? '' : parseFloat(rawValue))
  }
  return (
    <>
      <InputGroup display="flex" alignItems="center" size="md" {...props}>
        <NumericFormat
          id={id}
          name={name}
          value={value === 0 || value ? value.toString().replace('.', ',') : ''}
          onValueChange={handleValueChange}
          thousandSeparator="."
          decimalSeparator=","
          customInput={ChakraInput}
          placeholder={placeholder}
          h="36px"
          fontSize="14px"
          textAlign="center"
          focusBorderColor="brand.primary"
          backgroundColor="brand.background"
          decimalScale={decimalScale}
          fixedDecimalScale={false}
        />
      </InputGroup>
      {props.error && (
        <Text fontSize="12px" color="red">
          {props.error}
        </Text>
      )}
    </>
  )
}

InputModal.Currency.displayName = 'InputModalCurrency'
