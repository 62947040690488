import { Tbody } from '@chakra-ui/react'
import { Company } from 'Components'

export const ListCompany = ({ data, onDelete }) => {
  return (
    <Tbody>
      {data &&
        data?.map((item) => (
          <Company key={`company_${item.id}`} {...item} onDelete={onDelete} />
        ))}
    </Tbody>
  )
}
